import { createStore } from 'vuex'
import common from './modules/common'

export default createStore({
	state: {
		mobileOrPC: true
	},
	mutations: {
		whichDeviceFun(state) {//区分移动端与PC端滚动禁用方法
			if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
				console.log('iPhone/平板')
				state.mobileOrPC = false
			} else if (/(Android)/i.test(navigator.userAgent)) {
				console.log('安卓端')
				state.mobileOrPC = false
			} else {
				console.log('PC端')
				state.mobileOrPC = true
			}
		}
	},
	actions: {

	},
	modules: {
		common
	}
})