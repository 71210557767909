<template>
    <div ref="toTop">
        <div class="mobile_style" style="height:1.35rem"></div>
        <!-- ⬆ 移动端填补顶栏空间的标签不能删除 -->
        <div class="pc_style">
            <div class="bannarBlock">
                <div class="bannerTitleBox">
                    <span class="banner_font">以数字化融合低碳化，打造智慧空间畅享未来，<br>探索未来数字城市发展新路</span>
                </div>
                <el-row class="bannerBottomBox">
                    <el-col :span="12" class="banner_left">
                        <el-row>上海云止科技有限公司，简称上海云止，是一家专注于“智慧空间”数字化的公司，于2020年在上海成立，至今产品已落地全国16个省市自治区、100余城市。</el-row>
                        <div class="banner_table">
                            <el-row>
                                <el-col :span="6" class="banner_table_item">
                                    <img src="../assets/image/aboutUs_icon1.png" alt="">
                                    <div>智慧政务</div>
                                </el-col>
                                <el-col :span="6" class="banner_table_item">
                                    <img src="../assets/image/aboutUs_icon2.png" alt="">
                                    <div>智慧商场</div>
                                </el-col>
                                <el-col :span="6" class="banner_table_item">
                                    <img src="../assets/image/aboutUs_icon3.png" alt="">
                                    <div>智慧交通</div>
                                </el-col>
                                <el-col :span="6" class="banner_table_item">
                                    <img src="../assets/image/aboutUs_icon4.png" alt="">
                                    <div>智慧能源</div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6" class="banner_table_item">
                                    <img src="../assets/image/aboutUs_icon5.png" alt="">
                                    <div>智慧文旅</div>
                                </el-col>
                                <el-col :span="6" class="banner_table_item">
                                    <img src="../assets/image/aboutUs_icon6.png" alt="">
                                    <div>智慧建筑</div>
                                </el-col>
                                <el-col :span="6" class="banner_table_item">
                                    <img src="../assets/image/aboutUs_icon7.png" alt="">
                                    <div>智慧医疗</div>
                                </el-col>
                                <el-col :span="6" class="banner_table_item">
                                    <img src="../assets/image/aboutUs_icon8.png" alt="">
                                    <div>智慧教育</div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="12" class="banner_right">
                        <el-row class="banner_right_row fontWeightStyle" :class="item.active ? 'font_active' : ''"
                            v-for="(item, index) in highLightList" :key="index" @mouseenter="activeStyle(item.id)">
                            <el-col :span="12" class="BRR_left">{{ item.name }}</el-col>
                            <el-col :span="12" class="BRR_right">
                                <ICountUp :delay="delay" :endVal="item.number" :options="options" />+
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <div class="corporateCulture">
                <div class="fontStyle1">企业文化</div>
                <div class="fontStyle2">云端筑建</div>
                <div class="fontStyle2">智慧升级</div>
                <div class="fontStyle2">知止后定</div>
                <div class="fontStyle2">守正出新</div>
            </div>
            <div class="calligraphy">
                <div class="fontBig">知止而后有定，定而后能静，<br>静而后能安，安而后能虑，虑而后能得</div>
                <div class="fontSmall">——《大学》</div>
            </div>
            <SloganLine></SloganLine>
            <div class="mondrian">
                <el-row class="mondrian_row1">
                    <el-col :span="3"></el-col>
                    <el-col :span="7" class="colorBlock" id="picture1"></el-col>
                    <el-col :span="14" class="marginStyle1">
                        <el-row class="mondrian_title">从技术总监变身“极客”CEO，要做智慧空间数字化“狂人”</el-row>
                        <el-row
                            class="mondrian_detail">上海云止科技有限公司创始人高蓉，精耕智慧空间城市数字化，扎根产品十余年，核心团队成员均具有行业八年以上的从业经验，云止坚持以技术为企业基石，坚持探索创新，强强联合，才能真诚打磨好产品。</el-row>
                    </el-col>
                </el-row>
                <el-row class="mondrian_row2">
                    <el-col :span="13" class="marginStyle2">
                        <el-row class="mondrian_title">持续拓展数字应用场景，<br>深化产业数字化大融合</el-row>
                        <el-row
                            class="mondrian_detail">致力于智慧空间数字化领域深耕，旨在打通更多应用场景，将产业数字化大融合便捷带入更多行业，通过自身在行业中的多年探索与积累转化为落地成果，用定制化、一体化的解决方案加速全行业用户数字化转型与升级，以数字化融合低碳化，打造智慧空间畅享未来，探索未来数字城市发展新路。</el-row>
                    </el-col>
                    <el-col :span="8" class="colorBlock" id="picture2"></el-col>
                    <el-col :span="3"></el-col>
                </el-row>
            </div>
            <div class="authentication">
                <el-row class="authentication_title">业内奖项和企业能力认证</el-row>
                <el-row class="authentication_BlockBox">
                    <div class="authentication_block" v-for="(item, index) in authenticationList" :key="index">
                        <div class="ab_img">
                            <img :src="item.imgSrc" alt="">
                        </div>
                        <div class="ab_font">{{ item.name }}</div>
                    </div>
                </el-row>
            </div>
        </div>
        <div class="mobile_style">
            <div class="ms_bannarBlock">
                <div class="ms_bannerTitleBox">
                    <span class="ms_banner_font">以数字化融合低碳化，打造智慧空间畅享未来，探索未来数字城市发展新路</span>
                </div>
                <div class="ms_bannerBottomBox">
                    <div class="ms_banner_left">
                        <div class="ms_banner_detail">
                            上海云止科技有限公司，简称上海云止，是一家专注于“智慧空间”数字化的公司，于2020年在上海成立，至今产品已落地全国16个省市自治区、100余城市。
                        </div>
                        <el-row class="ms_banner_tag">
                            <el-col :span="8" v-for="(item, index) in highLightList" :key="index">
                                <el-row class="ms_banner_tag_num">{{ item.number }}+</el-row>
                                <el-row class="ms_banner_tag_name">{{ item.name }}</el-row>
                            </el-col>
                        </el-row>
                        <div class="ms_banner_table">
                            <el-row>
                                <el-col :span="6" class="ms_banner_table_item">
                                    <img src="../assets/image/aboutUs_icon1.png" alt="">
                                    <div>智慧政务</div>
                                </el-col>
                                <el-col :span="6" class="ms_banner_table_item">
                                    <img src="../assets/image/aboutUs_icon2.png" alt="">
                                    <div>智慧商场</div>
                                </el-col>
                                <el-col :span="6" class="ms_banner_table_item">
                                    <img src="../assets/image/aboutUs_icon3.png" alt="">
                                    <div>智慧交通</div>
                                </el-col>
                                <el-col :span="6" class="ms_banner_table_item">
                                    <img src="../assets/image/aboutUs_icon4.png" alt="">
                                    <div>智慧能源</div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6" class="ms_banner_table_item">
                                    <img src="../assets/image/aboutUs_icon5.png" alt="">
                                    <div>智慧文旅</div>
                                </el-col>
                                <el-col :span="6" class="ms_banner_table_item">
                                    <img src="../assets/image/aboutUs_icon6.png" alt="">
                                    <div>智慧建筑</div>
                                </el-col>
                                <el-col :span="6" class="ms_banner_table_item">
                                    <img src="../assets/image/aboutUs_icon7.png" alt="">
                                    <div>智慧医疗</div>
                                </el-col>
                                <el-col :span="6" class="ms_banner_table_item">
                                    <img src="../assets/image/aboutUs_icon8.png" alt="">
                                    <div>智慧教育</div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ms_row">企业文化</div>
            <div class="corporateCulture">
                <div class="fontStyle2">云端筑建</div>
                <div class="fontStyle2">智慧升级</div>
                <div class="fontStyle2">知止后定</div>
                <div class="fontStyle2">守正出新</div>
            </div>
            <div class="calligraphy">
                <div class="fontBig">知止而后有定，定而后能静，<br>静而后能安，安而后能虑，虑而后能得</div>
                <div class="fontSmall">——《大学》</div>
            </div>
            <SloganLine></SloganLine>
            <div class="mondrian">
                <el-row class="mondrian_row1">
                    <el-col :span="24" class="colorBlock" id="picture1"></el-col>
                </el-row>
                <el-row class="mondrian_title">从技术总监变身“极客”CEO，要做智慧空间数字化“狂人”</el-row>
                <el-row
                    class="mondrian_detail">上海云止科技有限公司创始人高蓉，精耕智慧空间城市数字化，扎根产品十余年，核心团队成员均具有行业八年以上的从业经验，云止坚持以技术为企业基石，坚持探索创新，强强联合，才能真诚打磨好产品。</el-row>
                <el-row class="mondrian_row2">
                    <el-col :span="24" class="colorBlock" id="picture2"></el-col>
                </el-row>
                <el-row class="mondrian_title">持续拓展数字应用场景，深化产业数字化大融合</el-row>
                <el-row
                    class="mondrian_detail">致力于智慧空间数字化领域深耕，旨在打通更多应用场景，将产业数字化大融合便捷带入更多行业，通过自身在行业中的多年探索与积累转化为落地成果，用定制化、一体化的解决方案加速全行业用户数字化转型与升级，以数字化融合低碳化，打造智慧空间畅享未来，探索未来数字城市发展新路。</el-row>
            </div>
            <div class="authentication">
                <el-row class="authentication_title">业内奖项和企业能力认证</el-row>
                <el-row class="authentication_BlockBox">
                    <div class="authentication_block" v-for="(item, index) in authenticationList" :key="index">
                        <div class="ab_img">
                            <img :src="item.imgSrc" alt="">
                        </div>
                        <div class="ab_font">{{ item.name }}</div>
                    </div>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import ICountUp from 'vue-countup-v2/src/countup.vue';
import SloganLine from '../components/SloganLine.vue';

export default {
    name: "aboutUs",
    components: {
        ICountUp,
        SloganLine
    },
    data() {
        return {
            //---countUp插件的参数---
            delay: 0,
            instance: '',
            options: {
                useEasing: true,
                useGrouping: true,
                // duration: 0.5,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            //---end---
            countUpList: [
                16, 120, 600, 11000
            ],
            authenticationList: [
                {
                    id: 1,
                    name: "高新技术企业",
                    imgSrc: require('@/assets/image/aboutUs_img7.svg')
                },
                {
                    id: 2,
                    name: "科技型中小企业",
                    imgSrc: require('@/assets/image/aboutUs_img4.svg')
                },
                {
                    id: 3,
                    name: "创新型中小企业",
                    imgSrc: require('@/assets/image/aboutUs_img14.svg')
                },
                {
                    id: 4,
                    name: "7+专利国家版权局认证",
                    imgSrc: require('@/assets/image/aboutUs_img1.svg')
                },
                {
                    id: 5,
                    name: "15+自主研发认证",
                    imgSrc: require('@/assets/image/aboutUs_img3.svg')
                },
                {
                    id: 6,
                    name: "30+软著证书认证",
                    imgSrc: require('@/assets/image/aboutUs_img3.svg')
                },
                {
                    id: 7,
                    name: "企业信用评价AAA级信用企业",
                    imgSrc: require('@/assets/image/aboutUs_img10.svg')
                },
                {
                    id: 8,
                    name: "软件产品认证",
                    imgSrc: require('@/assets/image/aboutUs_img11.svg')
                },
                {
                    id: 9,
                    name: "软件企业认证",
                    imgSrc: require('@/assets/image/aboutUs_img12.svg')
                },
                {
                    id: 10,
                    name: "质量管理体系认证证书（ISO9001）",
                    imgSrc: require('@/assets/image/aboutUs_img15.svg')
                },
                {
                    id: 11,
                    name: "MAXHUB上海地区授权经销商",
                    imgSrc: require('@/assets/image/aboutUs_img5.svg')
                },
                {
                    id: 12,
                    name: "ULI华东会员",
                    imgSrc: require('@/assets/image/aboutUs_img6.svg')
                },
                {
                    id: 13,
                    name: "江苏省标识行业协会会员单位",
                    imgSrc: require('@/assets/image/aboutUs_img15.svg')
                },
                {
                    id: 14,
                    name: "中国百货商业协会会员单位",
                    imgSrc: require('@/assets/image/aboutUs_img16.svg')
                },
                {
                    id: 15,
                    name: "技术品牌奖",
                    imgSrc: require('@/assets/image/aboutUs_img8.svg')
                },
                {
                    id: 16,
                    name: "年度创新产品及解决方案",
                    imgSrc: require('@/assets/image/aboutUs_img8.svg')
                },
                {
                    id: 17,
                    name: "行业信息化创新产品",
                    imgSrc: require('@/assets/image/aboutUs_img13.svg')
                },
            ],
            highLightList: [
                {
                    id: 0,
                    name: "项目覆盖省",
                    number: 16,
                    active: true
                },
                // {
                //     id: 1,
                //     name: "企业荣誉认证",
                //     number: 120,
                //     active: false
                // },
                {
                    id: 2,
                    name: "用户信赖选择",
                    number: 600,
                    active: false
                },
                {
                    id: 3,
                    name: "单平台用户累计",
                    number: 11000,
                    active: false
                }
            ]
            // highLightActive: true
        }
    },
    mounted() {
        this.androidScrollTop();
    },
    methods: {
        androidScrollTop() {//手机安卓端微信内置浏览器切换页面复位到页面顶部
            this.$store.commit('whichDeviceFun')
            if (!this.$store.state.mobileOrPC) {
                this.$refs.toTop.scrollIntoView()
            }
        },
        activeStyle(itemId) {
            for (const key in this.highLightList) {
                if (Object.hasOwnProperty.call(this.highLightList, key)) {
                    const element = this.highLightList[key];
                    if (element.id === itemId) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                }
            }
        }
    }
}

</script>
<style lang="scss" scoped>
font {
    color: #313131;
}

.bannarBlock {
    height: 958px;
    border-bottom: 1px solid #313131;

    .bannerTitleBox {
        margin-top: 100px;
        margin-left: 200px;

        .banner_font {
            font-family: Alimama_ShuHeiTi_Bold;
            font-size: 50px;
            font-weight: bold;
            color: #000000;
        }
    }

    .bannerBottomBox {
        margin: 60px 200px;

        .banner_left {
            font-size: 20px;
            line-height: 40px;
            // text-indent: 2em;
            padding-right: 60px;

            .banner_table {
                width: 576px;
                height: 288px;
                border: 1px solid #ededed;
                margin-top: 60px;
                color: #313131;

                .banner_table_item {
                    width: 144px;
                    height: 144px;
                    border-left: 1px solid #ededed;
                    border-bottom: 1px solid #ededed;
                    padding: 35px 0;
                    text-align: center;

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }

                .banner_table_item:first-child {
                    border-left: 0;
                }
            }
        }

        .banner_right {
            margin: 60px 0;
            padding-left: 60px;

            .banner_right_row {
                height: 134px;
                border-bottom: 2px solid #ccc;

                .BRR_left {
                    font-size: 20px;
                    font-family: MicrosoftYaHei;
                    font-weight: bolder;
                    padding-top: 100px;
                }

                .BRR_right {
                    color: #ccc;
                    font-size: 48px;
                    padding-top: 70px;
                    text-align: right;
                }
            }

            .font_active {
                border-bottom: 2px solid #313131;

                .BRR_right {
                    color: #313131;
                }
            }

            // .banner_right_row:hover {
            //     border-bottom: 2px solid #313131;

            //     .BRR_right {
            //         color: #313131;
            //     }
            // }
        }
    }
}

.corporateCulture {
    height: 258px;
    padding: 0 240px;
    display: flex;
    color: #313131;
    align-items: center;

    .fontStyle1 {
        font-size: 50px;
        font-family: Alimama_ShuHeiTi_Bold;
        margin-right: 90px;
    }

    .fontStyle2 {
        font-size: 30px;
        font-family: MicrosoftYaHei;
        margin: 0 10px;
        font-weight: bolder;
    }
}

.calligraphy {
    height: 538px;
    background-color: #f9f9ff;
    padding: 0 135px;
    padding-top: 130px;
    padding-bottom: 63px;

    .fontBig {
        font-size: 80px;
        font-family: Slidexiaxing_Regular;
    }

    .fontSmall {
        font-size: 30px;
        font-family: Slidexiaxing_Regular;
        text-align: right;
        padding: 60px 0;
    }
}

.mondrian {
    margin-top: 120px;
    border-top: 1px solid #313131;
    border-bottom: 1px solid #313131;

    .mondrian_row1 {
        height: 768px;

        .marginStyle1 {
            padding: 136px 136px 40px 40px;
        }
    }

    .mondrian_row2 {
        height: 640px;
        border-top: 1px solid #313131;

        .marginStyle2 {
            padding: 154px 118px;
        }
    }

    .colorBlock {
        background-color: #eee;
        border-left: 1px solid #313131;
        border-right: 1px solid #313131;
    }

    #picture1 {
        background-image: url("../assets/image/aboutUs_pic1.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    #picture2 {
        width: 687px;
        height: 640px;
        background-image: url("../assets/image/aboutUs_pic2.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .mondrian_title {
        font-size: 36px;
        font-family: MicrosoftYaHei;
        font-weight: bolder;
    }

    .mondrian_detail {
        font-size: 24px;
        font-family: MicrosoftYaHei;
        margin-top: 30px;
        line-height: 44px;
    }
}

.authentication {
    height: 1080px;

    .authentication_title {
        height: 250px;
        font-size: 36px;
        font-family: MicrosoftYaHei;
        font-weight: bolder;
        justify-content: center;
        align-items: center;
    }

    .authentication_BlockBox {
        padding: 0 200px;

        .authentication_block {
            border: 1px solid #eaeaea;
            width: 280px;
            height: 170px;
            margin: 10px 9px;
            text-align: center;
            padding: 20px 10px;

            .ab_img {

                img {
                    height: 80px;
                }
            }

            .ab_font {
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: bolder;
            }
        }
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
    }

    .ms_bannarBlock {
        // margin-top: 1.35rem;
        height: auto;

        .ms_bannerTitleBox {
            margin: 0;
            padding: 1rem 0.3rem;

            .ms_banner_font {
                font-size: 0.5rem;
                font-family: Alimama_ShuHeiTi_Bold;
                font-weight: bold;
            }
        }
    }

    .ms_bannerBottomBox {
        padding: 0.3rem;

        .ms_banner_left {
            .ms_banner_detail {
                font-size: 0.3rem;
                line-height: 0.5rem;
                text-indent: 2em;
            }

            .ms_banner_tag {
                padding: 0.5rem 0;

                .ms_banner_tag_num {
                    font-size: 0.5rem;
                    font-weight: bolder;
                    justify-content: center;
                }

                .ms_banner_tag_name {
                    font-size: 0.3rem;
                    justify-content: center;
                }
            }

            .ms_banner_table {
                width: 100%;
                margin: 0.5rem 0;
                border-top: 1px solid #ccc;
                border-left: 1px solid #ccc;

                .ms_banner_table_item {
                    text-align: center;
                    padding: 0.5rem 0;
                    border-right: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;

                    img {
                        width: 0.75rem;
                        height: 0.75rem;
                    }
                }
            }
        }
    }

    .corporateCulture {
        .fontStyle2 {
            width: 25%;
            font-size: 0.3rem;
            text-align: center;
        }
    }

    .ms_row {
        width: 100%;
        text-align: center;
        font-weight: bolder;
        font-size: 0.5rem;
    }

    .calligraphy {
        .fontBig {
            font-size: 0.4rem;
        }

        .fontSmall {
            font-size: 0.3rem;
        }
    }

    .mondrian {
        margin-top: 120px;
        border-top: 0.07rem solid #313131;
        border-bottom: 0.07rem solid #313131;
        height: auto;
        // padding: 0.3rem;

        .mondrian_row1 {
            height: auto;
            padding: 0 0.3rem;
            border-bottom: 0.07rem solid #313131;
        }

        .mondrian_row2 {
            height: auto;
            border-top: 0.07rem solid #313131;
            border-bottom: 0.07rem solid #313131;
            padding: 0 0.3rem;
        }

        .colorBlock {
            background-color: #eee;
            border-left: 1px solid #313131;
            border-right: 1px solid #313131;
        }

        #picture1 {
            width: 100%;
            height: 15rem;
            background-image: url("../assets/image/aboutUs_pic1.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        #picture2 {
            width: 100%;
            height: 7rem;
            background-image: url("../assets/image/aboutUs_pic2.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .mondrian_title {
            width: 100%;
            font-size: 0.5rem;
            font-family: MicrosoftYaHei;
            font-weight: bolder;
            padding: 0.3rem 0.5rem;
            margin-top: 1rem;
        }

        .mondrian_detail {
            font-size: 0.3rem;
            font-family: MicrosoftYaHei;
            margin-top: 0;
            line-height: 0.5rem;
            padding: 0.5rem;
            margin-bottom: 1rem;
        }
    }

    .authentication {
        height: auto;

        .authentication_title {
            height: auto;
            font-size: 0.5rem;
            padding: 1rem 0;
        }

        .authentication_BlockBox {
            height: auto;
            margin-bottom: 1.5rem;

            .authentication_block {
                width: 48%;
                height: 2.5rem;
                margin: 10px 9px;
                text-align: center;
                padding: 20px 10px;

                .ab_img {

                    img {
                        height: 1rem;
                    }
                }

                .ab_font {
                    font-size: 0.25rem;
                    font-family: MicrosoftYaHei;
                    font-weight: bolder;
                }
            }
        }
    }
}</style>