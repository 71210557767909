import axios from 'axios'

export const Server = new class {
    async submitUserMobile(data) {
        const res = await axios.post('/api/customer/submit', data);
        return res.data;
    }
    async getNews(params) {
        const res = await axios.get('/api/consult/list', params);
        return res.data;
    }
    async getLogoWall() {
        const res = await axios.get('/api/background-logo/list');
        return res.data;
    }
    async getInfoCategory() {
        const res = await axios.get('/api/consult/category');
        return res.data;
    }
    async getTag(params) {
        const res = await axios.get('/api/consult/tag', params);
        return res.data;
    }
    async getNewsInfo(id) {
        const res = await axios.get('/api/consult/' + id);
        return res.data;
    }
    async getHotList() {
        const res = await axios.get('/api/consult/host-list');
        return res.data;
    }
    async getCaseList(params) {
        const res = await axios.get('/api/exp-center/list', params);
        return res.data;
    }
    async getCaseInfo(id) {
        const res = await axios.get('/api/exp-center/info/' + id);
        return res.data;
    }
    async getCaseMenu() {
        const res = await axios.get('/api/exp-center/type-lise');
        return res.data;
    }
    async tracking(data) {
        const res = await axios.post('/api/track', data);
        return res.data;
    }
    async search(data) {
        const res = await axios.get('/api/search', data);
        return res.data;
    }
}