<template>
    <div ref="obtain">
        <div class="mobile_style" style="height:1.35rem"></div>
        <div class="bannerBox" id="bannerBox" :style="{ 'background-image': `url(${getImgPath(contentJson.bannerImage)}) ` }">
        <!-- <div class="bannerBox" id="bannerBox"> -->
            <div class="bannerLeftBox">
                <div class="bannerLeft_title">{{ contentJson.bannerTitle }}</div>
                <div class="bannerLeft_En">{{ contentJson.bannerEn }}</div>
                <div class="bannerLeft_describe">
                    {{ contentJson.bannerDesc }}
                </div>
            </div>
        </div>
        <el-row class="pc_style">
            <el-col :span="3">
                <div class="category_box" v-show="showMenuState">
                    <div class="category_body">
                        <div v-for="(categoryItem, categoryIndex) in contentJson.categoryList" :key="categoryIndex"
                            @click="scrolltoBlock(categoryItem.id)" class="category_inside">
                            <div class="category_title" :class="categoryItem.active ? 'case_name' : ''">
                                {{ categoryItem.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="21">
                <div class="introductionBox" id="scrollBox1">
                    <el-row class="introduction_title">{{ contentJson.caseProfile }}</el-row>
                    <el-row>
                        <el-col :span="10">
                            <img class="underBanner" :src="this.currentImg" alt="">
                        </el-col>
                        <el-col :span="14" class="ib1rightBox">
                            <el-row class="introduction_cardBlock" v-for="(item, index) in contentJson.blockOneList"
                                :key="index" :class="[item.active ? 'defaultActive' : '']"
                                @mouseenter="blockOneChange(item.id)">
                                <el-row class="cardBlock_one">
                                    <el-col :span="6" class="fontWeightStyle fontBottom">{{ item.name }}</el-col>
                                    <el-col :span="16"></el-col>
                                    <el-col :span="2"><span class="fontWeightStyle"
                                            :class="[item.active ? 'font_black' : 'font_white']">0{{ index + 1
                                            }}</span></el-col>
                                </el-row>
                                <el-row class="cardBlock_two">
                                    <el-col :span="12">
                                        <el-row v-show="item.text1 != '' ? true : false">> {{ item.text1 }}</el-row>
                                        <el-row v-show="item.text2 != '' ? true : false">> {{ item.text2 }}</el-row>
                                        <el-row v-show="item.text3 != '' ? true : false">> {{ item.text3 }}</el-row>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-row v-show="item.text4 != '' ? true : false">> {{ item.text4 }}</el-row>
                                        <el-row v-show="item.text5 != '' ? true : false">> {{ item.text5 }}</el-row>
                                        <el-row v-show="item.text6 != '' ? true : false">> {{ item.text6 }}</el-row>
                                    </el-col>
                                </el-row>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
                <div class="introductionBox" id="scrollBox2">
                    <el-row class="introduction_title">{{ contentJson.creativeIdea }}</el-row>
                    <div class="introduction_block2">
                        <div class="iblock2" v-for="(item, index) in contentJson.blockTwoList" :key="index"
                            :class="[item.active ? 'ib2_openStyle' : 'ib2_closeStyle']"
                            @mouseenter="blockTwoChange(item.id)">
                            <div class="introduction_block2_open" v-show="item.active">
                                <el-row class="introduction_block2_title">{{ item.name }}</el-row>
                                <el-row class="introduction_block2_text">{{ item.text1 }}<br>{{ item.text2 }}</el-row>
                                <el-row class="introduction_block2_blockBox">
                                    <el-row class="introduction_block2_block"
                                        v-for="(currentItem, currentIndex) in item.itemArr" :key="currentIndex">
                                        <el-row class="introduction_block2_block_text">{{ currentItem.name }}</el-row>
                                        <el-row class="introduction_block2_block_num">{{ currentItem.number }}</el-row>
                                        <div class="inb2_bottomBox">数据统计来源于 {{ currentItem.area }}</div>
                                    </el-row>
                                </el-row>
                            </div>
                            <div class="introduction_block2_close" v-show="!item.active">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
                <div class="introductionBox" id="scrollBox3">
                    <el-row class="introduction_title">{{ contentJson.advantage }}</el-row>
                    <el-row class="introduction_block3">
                        <el-col :span="8" class="iblock3_inside" v-for="(item, index) in contentJson.blockThreeList"
                            :key="index">
                            <div class="ib3_number">0{{ index + 1 }}</div>
                            <el-row class="iblock3_fontBold">{{ item.title }}</el-row>
                            <el-row class="iblock3_fontNormal">
                                <span>{{ item.desc1 }}</span>
                                {{ item.desc2 }}
                            </el-row>
                            <el-row class="iblock3_fontSmall">
                                <el-row class="cardBlock_two">
                                    <el-row v-show="item.point1 == '' ? false : true">> {{ item.point1 }}</el-row>
                                    <el-row v-show="item.point2 == '' ? false : true">> {{ item.point2 }}</el-row>
                                    <el-row v-show="item.point3 == '' ? false : true">> {{ item.point3 }}</el-row>
                                </el-row>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
                <div class="introductionBox" id="scrollBox4" v-show="partnersBlockShow">
                    <el-row class="introduction_title">{{ contentJson.partner }}</el-row>
                    <el-row class="introduction_block4">
                        <el-col :span="6" class="iblock4_inside" v-for="(item, index) in contentJson.blockFourList"
                            :key="index">
                            <div class="iblock4_insideBlock">
                                <img class="iblock4_img" :src="getImgPath(item.imageUrl)" alt="">
                                <el-row class="iblock4_fontBold">
                                    {{ item.name }}
                                </el-row>
                                <el-row class="iblock4_fontNormal">
                                    {{ item.text1 }}
                                </el-row>
                                <div class="ib4_icon"><img :src="getImgPath(item.icon)" alt="">{{ item.text2 }}</div>
                                <div class="ib4_lastBlock">{{ item.text3 }}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
        <div class="bottomMenuBox1 pc_style" v-show="showMenuState" v-if="bottomShow">
            <div class="BMB_block" v-for="(item, index) in bottomMenu" :key="index" @click="routerLink(item.data)">{{
                item.name }}
                <span class="BMB_active" v-if="item.active"></span>
            </div>
        </div>
        <div class="bottomMenuBox2 pc_style" v-show="showMenuState" v-else>
            <div class="BMB_block" v-for="(item, index) in bottomMenu" :key="index" @click="routerLink(item.data)">{{
                item.name }}
                <span class="BMB_active" v-if="item.active"></span>
            </div>
        </div>
        <div class="mobile_style">
            <div class="introductionBox">
                <el-row class="introduction_title">{{ contentJson.caseProfile }}</el-row>
                <el-row class="introduction_detail">
                    <el-col :span="24" class="ib1rightBox">
                        <el-row class="introduction_cardBlock" v-for="(item, index) in contentJson.blockOneList"
                            :key="index" @click="blockOneChange(item.id)">
                            <el-row class="cardBlock_one">
                                <el-col :span="6" class="fontWeightStyle fontBottom">{{ item.name }}</el-col>
                                <el-col :span="15"></el-col>
                                <el-col :span="3"><span class="fontWeightStyle"
                                        :class="[item.active ? 'font_black' : 'font_white']">0{{ index + 1
                                        }}</span></el-col>
                            </el-row>
                            <el-row class="cardBlock_two" v-show="item.active">
                                <el-col :span="12">
                                    <el-row v-show="item.text1 != '' ? true : false">> {{ item.text1 }}</el-row>
                                    <el-row v-show="item.text2 != '' ? true : false">> {{ item.text2 }}</el-row>
                                    <el-row v-show="item.text3 != '' ? true : false">> {{ item.text3 }}</el-row>
                                </el-col>
                                <el-col :span="12">
                                    <el-row v-show="item.text4 != '' ? true : false">> {{ item.text4 }}</el-row>
                                    <el-row v-show="item.text5 != '' ? true : false">> {{ item.text5 }}</el-row>
                                    <el-row v-show="item.text6 != '' ? true : false">> {{ item.text6 }}</el-row>
                                </el-col>
                            </el-row>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <div class="introductionBox" style="margin-top: 1rem;">
                <el-row class="introduction_title">{{ contentJson.creativeIdea }}</el-row>
                <div class="introduction_block2">
                    <div class="iblock2 ib2_openStyle" v-for="(item, index) in contentJson.blockTwoList" :key="index">
                        <div class="introduction_block2_open">
                            <el-row class="introduction_block2_title">{{ item.name }}</el-row>
                            <el-row class="introduction_block2_text">{{ item.text1 }}<br>{{ item.text2 }}</el-row>
                            <el-row class="introduction_block2_blockBox">
                                <el-row class="introduction_block2_block"
                                    v-for="(currentItem, currentIndex) in item.itemArr" :key="currentIndex">
                                    <el-row class="introduction_block2_block_text">{{ currentItem.name }}</el-row>
                                    <el-row class="introduction_block2_block_num">{{ currentItem.number }}</el-row>
                                    <div class="inb2_bottomBox">数据统计来源于 {{ currentItem.area }}</div>
                                </el-row>
                            </el-row>
                        </div>
                    </div>
                </div>
            </div>
            <div class="introductionBox">
                <el-row class="introduction_title">{{ contentJson.advantage }}</el-row>
                <el-row class="introduction_block3">
                    <div class="iblock3_outside">
                        <div class="iblock3_inside" v-for="(item, index) in contentJson.blockThreeList" :key="index">
                            <div class="ib3_number">0{{ index + 1 }}</div>
                            <el-row class="iblock3_fontBold">{{ item.title }}</el-row>
                            <el-row class="iblock3_fontNormal">
                                <span>{{ item.desc1 }}</span>
                                {{ item.desc2 }}
                            </el-row>
                            <el-row class="iblock3_fontSmall">
                                <el-row class="cardBlock_two">
                                    <el-row v-show="item.point1 == '' ? false : true">> {{ item.point1 }}</el-row>
                                    <el-row v-show="item.point2 == '' ? false : true">> {{ item.point2 }}</el-row>
                                    <el-row v-show="item.point3 == '' ? false : true">> {{ item.point3 }}</el-row>
                                </el-row>
                            </el-row>
                        </div>
                    </div>
                </el-row>
            </div>
            <div class="introductionBox" v-show="partnersBlockShow">
                <el-row class="introduction_title">{{ contentJson.partner }}</el-row>
                <div class="introduction_block4">
                    <div class="iblock4_scroll">
                        <div class="iblock4_inside" v-for="(item, index) in contentJson.blockFourList" :key="index">
                            <div class="iblock4_insideBlock">
                                <img class="iblock4_img" :src="getImgPath(item.imageUrl)" alt="">
                                <el-row class="iblock4_fontBold">
                                    {{ item.name }}
                                </el-row>
                                <el-row class="iblock4_fontNormal">
                                    {{ item.text1 }}
                                </el-row>
                                <div class="ib4_icon"><img :src="getImgPath(item.icon)" alt="">{{ item.text2 }}</div>
                                <div class="ib4_lastBlock">{{ item.text3 }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import solutionJson from '@/static/json/solution.json';

export default {
    name: 'solution',
    data() {
        return {
            showMenuState: false,
            bottomMenu: [
                {
                    id: 1,
                    name: '商业地产',
                    data: 'commercialRealEstate',
                    active: true
                },
                {
                    id: 2,
                    name: '政府数字化',
                    data: 'governmentDigitization',
                    active: false
                },
                {
                    id: 3,
                    name: '智慧园区',
                    data: 'smartPark',
                    active: false
                },
                {
                    id: 4,
                    name: '智慧展馆',
                    data: 'smartExhibitionHall',
                    active: false
                },
                {
                    id: 5,
                    name: '智慧校园',
                    data: 'smartCampus',
                    active: false
                },
            ],
            currentImg: "",
            bottomShow: true,
            partnersBlockShow: true,
            contentJson: {}//存储json中的数据
        }
    },
    watch: {
        $route(to, from) {
            if (this.$route.query.data && from.path == "/solution") {
                this.changeItemData(this.$route.query.data)
            }
        }
    },
    mounted() {
        if (this.$route.query != undefined) {
            this.changeItemData(this.$route.query.data);
        }
        window.addEventListener('scroll', this.watchScroll);
        this.androidScrollTop();
    },
    unmounted() {
        window.removeEventListener('scroll', this.watchScroll);
    },
    methods: {
        androidScrollTop() {//手机安卓端微信内置浏览器切换页面复位到页面顶部
            this.$store.commit('whichDeviceFun')
            if (!this.$store.state.mobileOrPC) {
                this.$refs.obtain.scrollIntoView()
            }
        },
        isInViewPort(element) {//判断目标元素是否在视图范围内
            const viewWidth = window.innerWidth || document.documentElement.clientWidth;
            const viewHeight = window.innerHeight || document.documentElement.clientHeight;
            const {
                top,
                right,
                bottom,
                left,
            } = element.getBoundingClientRect();
            return (
                top >= 0 &&
                left >= 0 &&
                right <= viewWidth &&
                bottom <= viewHeight
            );
        },
        watchScroll() {
            this.showMenu();
            this.leftMenuChange();
        },
        showMenu() {
            let nowScroll = document.documentElement.scrollTop,
                bannerScroll = document.getElementById('bannerBox').offsetHeight;
            if (nowScroll < bannerScroll / 2) {
                this.showMenuState = false;
            } else {
                this.showMenuState = true;
            }
        },
        getEleTop(el) {
            let realTop = el.offsetTop;
            realTop += el.offsetParent.offsetTop;
            return realTop;
        },
        leftMenuChange() {
            let currentArr = [],
                nowHeight = Math.abs(this.$refs.obtain.getBoundingClientRect().top) + 120;
            if (this.partnersBlockShow) {
                currentArr = [
                    this.getEleTop(document.getElementById('scrollBox1')),
                    this.getEleTop(document.getElementById('scrollBox2')),
                    this.getEleTop(document.getElementById('scrollBox3')),
                    this.getEleTop(document.getElementById('scrollBox4'))
                ]
            } else {
                currentArr = [
                    this.getEleTop(document.getElementById('scrollBox1')),
                    this.getEleTop(document.getElementById('scrollBox2')),
                    this.getEleTop(document.getElementById('scrollBox3'))
                ]
            }
            // console.log(currentArr);
            for (let i = 0; i < currentArr.length; i++) {
                if (i == currentArr.length - 1) {
                    if (nowHeight >= currentArr[i]) {
                        this.publicChangeActive(i + 1, this.contentJson.categoryList);
                        this.bottomShow = false;
                    } else {
                        this.bottomShow = true;
                    }
                } else {
                    if (nowHeight >= currentArr[i] && nowHeight < currentArr[i + 1]) {
                        this.publicChangeActive(i + 1, this.contentJson.categoryList);
                    }
                }
            }
        },
        publicChangeActive(itemId, list) {
            for (const key in list) {
                if (Object.hasOwnProperty.call(list, key)) {
                    const element = list[key];
                    if (element.id === itemId) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                }
            }
        },
        scrolltoBlock(id) {
            console.log('scrolltoBlock-----', id)
            document.querySelector("#scrollBox" + id).scrollIntoView({
                behavior: "smooth"
            });
        },
        blockOneChange(itemId) {
            // console.log('one',itemId)
            this.publicChangeActive(itemId, this.contentJson.blockOneList);
        },
        blockTwoChange(itemId) {
            this.publicChangeActive(itemId, this.contentJson.blockTwoList);
        },
        routerLink(data) {
            this.$router.push({
                path: '/solution',
                query: {
                    data: data
                }
            });
            this.changeItemData(data);
        },
        changeItemData(data) {
            switch (data) {
                case "commercialRealEstate":
                    this.contentJson = solutionJson.commercialRealEstate;
                    this.publicChangeActive(1, this.bottomMenu);
                    this.partnersBlockShow = true;
                    break;
                case "governmentDigitization":
                    this.contentJson = solutionJson.governmentDigitization;
                    this.publicChangeActive(2, this.bottomMenu);
                    this.partnersBlockShow = false;
                    break;
                case "smartPark":
                    this.contentJson = solutionJson.smartPark;
                    this.publicChangeActive(3, this.bottomMenu);
                    this.partnersBlockShow = true;
                    break;
                case "smartExhibitionHall":
                    this.contentJson = solutionJson.smartExhibitionHall;
                    this.publicChangeActive(4, this.bottomMenu);
                    this.partnersBlockShow = true;
                    break;
                case "smartCampus":
                    this.contentJson = solutionJson.smartCampus;
                    this.publicChangeActive(5, this.bottomMenu);
                    this.partnersBlockShow = true;
                    break;
            }
            this.currentImg = this.getImgPath(this.contentJson.block1Img);
        },
        getImgPath(data) {
            return require('@/assets/' + data);
        }
    }
}
</script>
<style lang="scss" scoped>
@keyframes menuOpen {
    from {
        height: 140px;
    }

    to {
        height: 290px;
    }
}

.bannerBox {
    height: 680px;
    display: flex;
    border-bottom: 1px solid #313131;
    color: #fff;
    padding: 180px 260px;
    // background-image: url('../assets/image/solutionBanner1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .bannerLeftBox {
        width: 73%;

        .bannerLeft_title {
            font-size: 60px;
        }

        .bannerLeft_En {
            font-size: 36px;
            color: #eee;
        }

        .bannerLeft_describe {
            font-size: 24px;
            margin-top: 50px;
        }

    }
}

.category_box {
    width: 240px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    .category_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 200px;

        .category_inside {
            width: 100%;

            .category_title {
                display: flex;
                align-items: center;
                margin: 15px 0;
                font-size: 20px;
                color: #999999;
                cursor: pointer;
            }

            .case_name {
                padding: 50px 0;
                font-size: 30px;
                font-family: Alimama_ShuHeiTi_Bold;
                font-weight: bold;
                color: #313131;
            }
        }
    }
}

.introductionBox {
    height: 1080px;
    color: #313131;
    border-top: 1px solid #313131;
    overflow: hidden;

    .introduction_title {
        font-size: 30px;
        font-family: Alimama_ShuHeiTi_Bold;
        padding-top: 100px;
        padding-left: 30px;
        padding-bottom: 80px;
    }

    .underBanner {
        width: 600px;
        height: 800px;
        margin: 0 30px;
    }

    .ib1rightBox {
        height: 920px;
        overflow: hidden;

        .introduction_cardBlock {
            height: 140px;
            line-height: 130px;
            overflow: hidden;

            .cardBlock_one {
                width: 90%;
                height: 140px;
                font-size: 30px;
                border-bottom: 1px solid #313131;

                span {
                    font-size: 60px;
                    text-align: right;
                }

                .font_white {
                    color: #f6f6f6;
                }

                .font_black {
                    color: #313131;
                }
            }

            .cardBlock_two {
                width: 100%;
                font-size: 20px;
                line-height: 40px;
            }
        }
    }

    .defaultActive {
        animation: menuOpen 0.5s;
        animation-fill-mode: forwards;
    }

    .introduction_block2 {
        height: 750px;
        border: 1px solid #eee;
        display: flex;

        .ib2_openStyle {
            width: 64%;
        }

        .ib2_closeStyle {
            width: 12%;
        }

        .iblock2 {
            border-left: 1px solid #f6f6f6;
            border-right: 1px solid #f6f6f6;

            .introduction_block2_open {
                height: 750px;
                padding: 100px 160px;

                .introduction_block2_title {
                    font-size: 40px;
                    font-weight: bold;
                }

                .introduction_block2_text {
                    font-size: 24px;
                    line-height: 38px;
                    margin-top: 20px;
                }

                .introduction_block2_blockBox {
                    margin-top: 80px;

                    .introduction_block2_block {
                        width: 300px;
                        height: 336px;
                        border: 1px solid #313131;
                        padding: 40px 30px;
                        margin-right: 30px;
                        position: relative;

                        .introduction_block2_block_text {
                            font-size: 20px;
                            width: 100%;
                            height: 30%;
                        }

                        .introduction_block2_block_num {
                            font-size: 40px;
                            font-weight: bold;
                            position: absolute;
                            top: 40%;
                        }

                        .inb2_bottomBox {
                            line-height: 40px;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            font-size: 16px;
                            color: #ccc;
                            border-top: 1px solid #ccc;
                            padding: 0 10px;
                        }
                    }
                }
            }

            .introduction_block2_close {
                height: 750px;
                font-size: 40px;
                font-weight: bold;
                padding: 100px 80px;
            }
        }

        .iblock2:nth-child(even) {

            .introduction_block2_open,
            .introduction_block2_close {
                background-color: #f6f6f6;
            }
        }

    }

    .introduction_block3 {
        background-color: #fff;
        height: 844px;

        .iblock3_inside {
            padding: 180px 80px;
            position: relative;

            .ib3_number {
                font-size: 120px;
                font-family: Alimama_ShuHeiTi_Bold;
                color: #eee;
                position: absolute;
                top: 100px;
            }

            .iblock3_fontBold {
                font-size: 40px;
                font-family: MicrosoftYaHei;
                font-weight: bolder;
                border-bottom: 3px solid #313131;
                line-height: 100px;
            }

            .iblock3_fontNormal {
                font-size: 20px;
                font-family: MicrosoftYaHei;
                margin: 50px 0;

                span {
                    font-weight: bolder;
                }
            }

            .iblock3_fontSmall {
                font-size: 16px;
                line-height: 40px;
                position: absolute;
                top: 500px;

                .cardBlock_two {
                    .el-row {
                        width: 100%;
                    }
                }
            }
        }

        .iblock3_inside:hover {
            background-color: #f6f6f6;
        }
    }

    .introduction_block4 {
        height: 800px;

        .iblock4_inside {
            padding: 120px 25px;

            .iblock4_insideBlock {
                height: 600px;
                padding: 20px;
                border: 3px solid #313131;
                position: relative;
                line-height: 40px;

                .iblock4_img {
                    width: 100%;
                    height: 180px;
                    background-color: #f6f6f6;
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: center;
                }

                .iblock4_fontBold {
                    font-size: 24px;
                    font-weight: bold;
                }

                .iblock4_fontNormal {
                    font-size: 16px;
                }

                .ib4_icon {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    position: absolute;
                    left: 20px;
                    right: 0;
                    bottom: 70px;

                    img {
                        width: 75px;
                        justify-content: center;
                        align-items: center;
                        margin-right: 30px;
                    }
                }

                .ib4_lastBlock {
                    padding: 0 20px;
                    line-height: 60px;
                    border-top: 1px solid #eee;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
}

.introductionBox:first-child {
    border-top: 0;
}

.bottomMenuBox1 {
    width: 988px;
    height: 76px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    backdrop-filter: blur(10px);
    padding: 19px 0;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    .BMB_block {
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        width: 20%;
        border-right: 1px solid #999;
        float: left;
        cursor: pointer;
        position: relative;

        .BMB_active {
            width: 10px;
            height: 10px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid #313131;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -10px;
        }
    }

    .BMB_block:last-child {
        border: 0;
    }
}

.bottomMenuBox2 {
    width: 988px;
    height: 76px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    backdrop-filter: blur(10px);
    padding: 19px 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .BMB_block {
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        width: 20%;
        border-right: 1px solid #999;
        float: left;
        cursor: pointer;
        position: relative;

        .BMB_active {
            width: 10px;
            height: 10px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid #313131;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -10px;
        }
    }

    .BMB_block:last-child {
        border: 0;
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
    }

    .bannerBox {
        // margin-top: 1.35rem;
        height: 4rem;
        padding: 1rem 0.5rem;

        .bannerLeftBox {
            width: 100%;

            .bannerLeft_title {
                font-size: 0.5rem;
            }

            .bannerLeft_En {
                font-size: 0.3rem;
            }

            .bannerLeft_describe {
                font-size: 0.3rem;
            }
        }
    }

    .introductionBox {
        height: auto;
        padding: 0 0.25rem;

        .introduction_title {
            font-size: 0.5rem;
            padding: 1rem 0;
        }

        .introduction_detail {
            .ib1rightBox {
                height: auto;

                .introduction_cardBlock {
                    height: auto;
                    line-height: 1rem;

                    .cardBlock_one {
                        font-size: 0.5rem;
                        width: 100%;
                        min-height: 2rem;
                        align-items: end;

                        .font_black {
                            font-size: 0.6rem;
                        }

                        .font_white {
                            font-size: 0.6rem;
                        }
                    }
                    .cardBlock_two div {
                        height: auto;
                        font-size: 0.3rem;
                        line-height: 0.75rem;
                    }
                }
            }
        }

        .introduction_block2 {
            height: auto;
            display: block;

            .ib2_openStyle {
                width: 100%;

                .introduction_block2_open {
                    height: auto;
                    padding: 0.7rem 0;

                    .introduction_block2_title {
                        font-size: 0.5rem;
                        padding-left: 0.25rem;
                    }

                    .introduction_block2_text {
                        font-size: 0.3rem;
                        line-height: 0.5rem;
                        padding-left: 0.25rem;
                    }

                    .introduction_block2_blockBox {

                        .introduction_block2_block {
                            width: 45%;
                            height: 3.5rem;
                            margin: 0 2.5%;

                            .introduction_block2_block_text {
                                font-size: 0.3rem;
                            }

                            .introduction_block2_block_num {
                                font-size: 0.5rem;
                            }

                            .inb2_bottomBox {
                                display: flex;
                                font-size: 0.25rem;
                                line-height: 0.35rem;
                                padding: 0.1rem;
                                height: 1rem;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }

        .introduction_block3 {
            background-color: #fff;
            height: auto;
            overflow: auto;
            .iblock3_outside{
                width: 18rem;
                display: flex;
            }
            .iblock3_inside {
                width: 6rem;
                padding: 1rem 0.5rem;
                position: relative;

                .ib3_number {
                    font-size: 1.5rem;
                    font-family: Alimama_ShuHeiTi_Bold;
                    color: #eee;
                    position: absolute;
                    top: 0;
                }

                .iblock3_fontBold {
                    font-size: 0.5rem;
                    // font-family: MicrosoftYaHei;
                    font-weight: bold;
                    border-bottom: 3px solid #313131;
                    line-height: 1rem;
                }

                .iblock3_fontNormal {
                    font-size: 0.3rem;
                    font-family: MicrosoftYaHei;
                    margin: 0.3rem 0;

                    span {
                        font-weight: bolder;
                        min-height: 1rem;
                    }
                }

                .iblock3_fontSmall {
                    font-size: 0.3rem;
                    line-height: 0.5rem;
                    position: relative;
                    top: 0;

                    .cardBlock_two {
                        .el-row {
                            width: 100%;
                        }
                    }
                }
            }

            .iblock3_inside:hover {
                background-color: #f6f6f6;
            }
        }

        .introduction_block4 {
            height: auto;
            overflow: auto;

            .iblock4_scroll {
                width: 24rem;
                display: flex;
            }

            .iblock4_inside {
                width: 6rem;
                padding: 1rem 0.5rem;

                .iblock4_insideBlock {
                    height: 8rem;
                    padding: 0.2rem;
                    border: 3px solid #313131;
                    position: relative;
                    line-height: 0.5rem;

                    .iblock4_img {
                        width: 100%;
                        height: 3rem;
                        background-color: #f6f6f6;
                        margin-bottom: 20px;
                        display: flex;
                        justify-content: center;
                    }

                    .iblock4_fontBold {
                        font-size: 0.4rem;
                        font-weight: bold;
                        line-height: 1rem;
                    }

                    .iblock4_fontNormal {
                        font-size: 0.3rem;
                    }

                    .ib4_icon {
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        position: absolute;
                        left: 0.2rem;
                        right: 0;
                        bottom: 0.7rem;

                        img {
                            width: 0.7rem;
                            justify-content: center;
                            align-items: center;
                            margin-right: 30px;
                        }
                    }

                    .ib4_lastBlock {
                        padding: 0 0.2rem;
                        line-height: 0.5rem;
                        border-top: 1px solid #eee;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }
}</style>