<template>
  <div class="solutionBlock pc_style">
    <div id="block1" @mouseenter="changeWeight(1)" @click="routerLink('commercialRealEstate')">
      <div class="SB_blockOpen" v-show="solution1_onoffState">
        <el-row class="SB_blockOpen_title">商业地产</el-row>
        <el-row class="SB_blockOpen_style1">商场营销、会员、导视、商户服务定制化整合<br>提供闭合式解决方案，以数据赋能各项业务的运营管理</el-row>
        <el-row class="SB_blockOpen_style2">
          <el-col :span="12">
            <el-row class="SBBS_fontsize"><span>6</span>倍</el-row>
            <el-row>商场运营效率提升</el-row>
          </el-col>
          <el-col :span="12">
            <el-row><span>70%</span></el-row>
            <el-row>商场寻车寻店客诉降低</el-row>
          </el-col>
        </el-row>
        <el-row class="SB_blockOpen_style1">查看更多解决方案&nbsp;&nbsp;&nbsp;></el-row>
      </div>
      <el-row class="SB_blockOpen_style3" v-show="solution1_onoffState">
        <el-col :span="12">数据来源：基于项目情况得出的统计数据</el-col>
        <el-col :span="12">数据最新更新时间2023-05-17</el-col>
      </el-row>
      <div class="SB_blockClose" v-show="!solution1_onoffState">
        <el-row class="SB_blockClose_icon"><img src="../assets/image/solution0.png" alt=""></el-row>
        <el-row class="SB_blockClose_style1">商业地产</el-row>
        <el-row class="SB_blockClose_style2">商场营销、会员、导视、商户服务定制化整合，提供闭合式解决方案，以数据赋能各项业务的运营管理；</el-row>
      </div>
    </div>
    <div id="block2" @mouseenter="changeWeight(2)" @click="routerLink('governmentDigitization')">
      <div class="SB_blockOpen" v-show="solution2_onoffState">
        <el-row class="SB_blockOpen_title">政府数字化</el-row>
        <el-row class="SB_blockOpen_style1">平台集成政府各职能部门数据统一管控，实现城市多维数据的共享，提升监管力度和行政效率；</el-row>
        <el-row class="SB_blockOpen_style2">
          <el-col :span="12">
            <el-row><span>200+</span></el-row>
            <el-row>系统接入</el-row>
          </el-col>
          <el-col :span="12">
            <el-row class="SBBS_fontsize"><span>4</span>倍</el-row>
            <el-row>信息化数字管理能力提升</el-row>
          </el-col>
        </el-row>
        <el-row class="SB_blockOpen_style1">查看更多解决方案&nbsp;&nbsp;&nbsp;></el-row>
      </div>
      <el-row class="SB_blockOpen_style3" v-show="solution2_onoffState">
        <el-col :span="12">数据来源：基于项目情况得出的统计数据</el-col>
        <el-col :span="12">数据最新更新时间2023-05-17</el-col>
      </el-row>
      <div class="SB_blockClose" v-show="!solution2_onoffState">
        <el-row class="SB_blockClose_icon"><img src="../assets/image/solution1.png" alt=""></el-row>
        <el-row class="SB_blockClose_style1">政府数字化</el-row>
        <el-row class="SB_blockClose_style2">平台集成政府各职能部门数据统一管控，实现城市多维数据的共享，提升监管力度和行政效率；</el-row>
      </div>
    </div>
    <div id="block3" @mouseenter="changeWeight(3)" @click="routerLink('smartPark')">
      <div class="SB_blockOpen" v-show="solution3_onoffState">
        <el-row class="SB_blockOpen_title">智慧园区</el-row>
        <el-row class="SB_blockOpen_style1">整合园区安全、管理、经营和服务全数据，实时精准地掌握园区基础设施、人员、车辆、企业的脉动;</el-row>
        <el-row class="SB_blockOpen_style2">
          <el-col :span="12">
            <el-row><span>8.3%</span></el-row>
            <el-row>空置率下降</el-row>
          </el-col>
          <el-col :span="12">
            <el-row><span>63%</span></el-row>
            <el-row>园区运营效率提升</el-row>
          </el-col>
        </el-row>
        <el-row class="SB_blockOpen_style1">查看更多解决方案&nbsp;&nbsp;&nbsp;></el-row>
      </div>
      <el-row class="SB_blockOpen_style3" v-show="solution3_onoffState">
        <el-col :span="12">数据来源：基于项目情况得出的统计数据</el-col>
        <el-col :span="12">数据最新更新时间2023-05-17</el-col>
      </el-row>
      <div class="SB_blockClose" v-show="!solution3_onoffState">
        <el-row class="SB_blockClose_icon"><img src="../assets/image/solution2.png" alt=""></el-row>
        <el-row class="SB_blockClose_style1">智慧园区</el-row>
        <el-row class="SB_blockClose_style2">整合园区安全、管理、经营和服务全数据，实时精准地掌握园区基础设施、人员、车辆、企业的脉动;</el-row>
      </div>
    </div>
    <div id="block4" @mouseenter="changeWeight(4)" @click="routerLink('smartExhibitionHall')">
      <div class="SB_blockOpen" v-show="solution4_onoffState">
        <el-row class="SB_blockOpen_title">智慧展馆</el-row>
        <el-row class="SB_blockOpen_style1">赋能博物馆各体系流程。一体化解决安全、服务、营销、管理，提升体验与运营；</el-row>
        <el-row class="SB_blockOpen_style2">
          <el-col :span="9">
            <el-row><span>47%</span></el-row>
            <el-row>游客滞留时长提升</el-row>
          </el-col>
          <el-col :span="15">
            <el-row class="SBBS_fontsize"><span>40000+</span>次</el-row>
            <el-row>线上互动引流</el-row>
          </el-col>
        </el-row>
        <el-row class="SB_blockOpen_style1">查看更多解决方案&nbsp;&nbsp;&nbsp;></el-row>
      </div>
      <el-row class="SB_blockOpen_style3" v-show="solution4_onoffState">
        <el-col :span="12">数据来源：基于项目情况得出的统计数据</el-col>
        <el-col :span="12">数据最新更新时间2023-05-17</el-col>
      </el-row>
      <div class="SB_blockClose" v-show="!solution4_onoffState">
        <el-row class="SB_blockClose_icon"><img src="../assets/image/solution3.png" alt=""></el-row>
        <el-row class="SB_blockClose_style1">智慧展馆</el-row>
        <el-row class="SB_blockClose_style2">赋能博物馆各体系流程。一体化解决安全、服务、营销、管理，提升体验与运营；</el-row>
      </div>
    </div>
    <div id="block5" @mouseenter="changeWeight(5)" @click="routerLink('smartCampus')">
      <div class="SB_blockOpen" v-show="solution5_onoffState">
        <el-row class="SB_blockOpen_title">智慧校园</el-row>
        <el-row class="SB_blockOpen_style1">提供建筑、环境、设备、教室等解决方案。驱动学校教育智能化，可视化运行态势，打造更佳教育环境；</el-row>
        <el-row class="SB_blockOpen_style2">
          <el-col :span="12">
            <el-row><span>86%</span></el-row>
            <el-row>异常、违规事件降低</el-row>
          </el-col>
          <el-col :span="12">
            <el-row><span>84%</span></el-row>
            <el-row>安全管控提升</el-row>
          </el-col>
        </el-row>
        <el-row class="SB_blockOpen_style1">查看更多解决方案&nbsp;&nbsp;&nbsp;></el-row>
      </div>
      <el-row class="SB_blockOpen_style3" v-show="solution5_onoffState">
        <el-col :span="12">数据来源：基于项目情况得出的统计数据</el-col>
        <el-col :span="12">数据最新更新时间2023-05-17</el-col>
      </el-row>
      <div class="SB_blockClose" v-show="!solution5_onoffState">
        <el-row class="SB_blockClose_icon"><img src="../assets/image/solution4.png" alt=""></el-row>
        <el-row class="SB_blockClose_style1">智慧校园</el-row>
        <el-row class="SB_blockClose_style2">提供建筑、环境、设备、教室等解决方案。驱动学校教育智能化，可视化运行态势，打造更佳教育环境；</el-row>
      </div>
    </div>
  </div>
  <div class="solutionBlock mobile_style">
    <div class="SB_blockClose" @click="routerLink('commercialRealEstate')">
      <el-row class="SB_blockClose_icon"><img src="../assets/image/solution0.png" alt=""></el-row>
      <el-row class="SB_blockClose_style1">商业地产</el-row>
      <el-row class="SB_blockClose_style2">商场营销、会员、导视、商户服务定制化整合，提供闭合式解决方案，以数据赋能各项业务的运营管理；</el-row>
    </div>
    <div class="SB_blockClose" @click="routerLink('governmentDigitization')">
      <el-row class="SB_blockClose_icon"><img src="../assets/image/solution1.png" alt=""></el-row>
      <el-row class="SB_blockClose_style1">政府数字化</el-row>
      <el-row class="SB_blockClose_style2">平台集成政府各职能部门数据统一管控，实现城市多维数据的共享，提升监管力度和行政效率；</el-row>
    </div>
    <div class="SB_blockClose" @click="routerLink('smartPark')">
      <el-row class="SB_blockClose_icon"><img src="../assets/image/solution2.png" alt=""></el-row>
      <el-row class="SB_blockClose_style1">智慧园区</el-row>
      <el-row class="SB_blockClose_style2">整合园区安全、管理、经营和服务全数据，实时精准地掌握园区基础设施、人员、车辆、企业的脉动;</el-row>
    </div>
    <div class="SB_blockClose" @click="routerLink('smartExhibitionHall')">
      <el-row class="SB_blockClose_icon"><img src="../assets/image/solution3.png" alt=""></el-row>
      <el-row class="SB_blockClose_style1">智慧展馆</el-row>
      <el-row class="SB_blockClose_style2">赋能博物馆各体系流程。一体化解决安全、服务、营销、管理，提升体验与运营；</el-row>
    </div>
    <div class="SB_blockClose" @click="routerLink('smartCampus')">
      <el-row class="SB_blockClose_icon"><img src="../assets/image/solution4.png" alt=""></el-row>
      <el-row class="SB_blockClose_style1">智慧校园</el-row>
      <el-row class="SB_blockClose_style2">提供建筑、环境、设备、教室等解决方案。驱动学校教育智能化，可视化运行态势，打造更佳教育环境；</el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SolutionHomePage',
  data() {
    return {
      solution1_onoffState: true,
      solution2_onoffState: false,
      solution3_onoffState: false,
      solution4_onoffState: false,
      solution5_onoffState: false,
    }
  },
  methods: {
    changeWeight(num) {
      let block1 = document.getElementById('block1'),
        block2 = document.getElementById('block2'),
        block3 = document.getElementById('block3'),
        block4 = document.getElementById('block4'),
        block5 = document.getElementById('block5');
      switch (num) {
        case 1:
          block1.animate(
            [{
              width: '40%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block5.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          this.solution1_onoffState = true;
          this.solution2_onoffState = false;
          this.solution3_onoffState = false;
          this.solution4_onoffState = false;
          this.solution5_onoffState = false;
          break;
        case 2:
          block1.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '40%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block5.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          this.solution1_onoffState = false;
          this.solution2_onoffState = true;
          this.solution3_onoffState = false;
          this.solution4_onoffState = false;
          this.solution5_onoffState = false;
          break;
        case 3:
          block1.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '40%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block5.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          this.solution1_onoffState = false;
          this.solution2_onoffState = false;
          this.solution3_onoffState = true;
          this.solution4_onoffState = false;
          this.solution5_onoffState = false;
          break;
        case 4:
          block1.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '40%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block5.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          this.solution1_onoffState = false;
          this.solution2_onoffState = false;
          this.solution3_onoffState = false;
          this.solution4_onoffState = true;
          this.solution5_onoffState = false;
          break;
        case 5:
          block1.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '15%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block5.animate(
            [{
              width: '40%'
            }],
            {
              duration: 300,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          this.solution1_onoffState = false;
          this.solution2_onoffState = false;
          this.solution3_onoffState = false;
          this.solution4_onoffState = false;
          this.solution5_onoffState = true;
          break;
      }
    },
    routerLink(data) {
      console.log(data);
      this.$router.push({
        path: '/solution',
        query: {
          data: data
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.solutionBlock {
  height: 642px;
  margin-bottom: 100px;
  border-bottom: 1px solid #313131;
  cursor: url(../assets/image/mouseArrow.svg), pointer;

  #block1,
  #block2,
  #block3,
  #block4,
  #block5 {
    display: block;
    float: left;
    height: 642px;
    border-right: 1px solid #313131;
    overflow: hidden;
    position: relative;

    .SB_blockOpen {
      position: absolute;
      top: 100px;
      left: 150px;
      right: 150px;
      bottom: 112px;
      font-size: 16px;
      overflow: hidden;

      .SB_blockOpen_title {
        font-size: 40px;
        font-weight: bold;
      }

      .SB_blockOpen_style1 {
        margin: 30px 0;
      }

      .SB_blockOpen_style2 {
        line-height: 60px;

        .SBBS_fontsize {
          font-size: 30px;
        }
      }

      .SB_blockOpen_style2 span {
        font-size: 60px;
        font-weight: bold;
      }
    }

    .SB_blockOpen_style3 {
      height: 47px;
      line-height: 47px;
      text-align: center;
      color: #ccc;
      font-size: 16px;
      border-top: 1px solid #ccc;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .SB_blockClose {
      position: absolute;
      top: 120px;
      left: 30px;
      right: 30px;
      bottom: 120px;

      .SB_blockClose_icon {
        justify-content: center;
      }

      .SB_blockClose_style1 {
        font-size: 30px;
        justify-content: center;
        margin: 30px 0;
      }

      .SB_blockClose_style2 {
        font-size: 16px;
        color: #666;
      }
    }
  }

  #block1 {
    width: 40%;
    position: relative;
  }

  #block2,
  #block3,
  #block4,
  #block5 {
    width: 15%;
  }
}

.mobile_style {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .pc_style {
    display: none;
  }

  .mobile_style {
    display: flex;
  }

  .solutionBlock {
    width: auto;
    height: auto;
    margin-bottom: 1rem;
    border-bottom: 1px solid #313131;
    overflow: auto;
    position: relative;

    .SB_blockClose {
      height: 7rem;
      padding: 0.85rem;
      border-right: 1px solid #313131;

      .SB_blockClose_icon {
        justify-content: center;
      }

      .SB_blockClose_style1 {
        font-size: 0.35rem;
        justify-content: center;
        margin: 0.25rem 0;
      }

      .SB_blockClose_style2 {
        font-size: 0.2rem;
        color: #666;
      }
    }
  }
}
</style>