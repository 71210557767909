<template>
    <!-- 根据路由渲染组件 -->
    <Topbar></Topbar>
    <BackTop></BackTop>
    <transition name="fade-transform" mode="out-in">
        <router-view v-if="isRouterAlive"></router-view>
    </transition>
    <BottomBar></BottomBar>
    <transition name="fade-transform" mode="out-in">
        <MessageBox></MessageBox>
    </transition>
    <transition name="fade-transform" mode="out-in">
        <SearchComponent></SearchComponent>
    </transition>
</template>

<script>
import Topbar from './components/Topbar.vue';
import BottomBar from './components/BottomBar.vue';
import MessageBox from './components/MessageBox.vue';
import SearchComponent from './components/SearchComponent.vue';
import BackTop from './components/BackTop.vue';

export default {
    name: "App",
    components: { Topbar, BottomBar, MessageBox, SearchComponent,BackTop },
    provide() {
        return {
            reload: this.reload,
        }
    },
    data() {
        return {
            isRouterAlive: true,
        }
    },
    mounted() {
        document.dispatchEvent(new Event("render-event"));
        this.$nextTick(() => {
            const meta = document.createElement('meta');
            meta.content = 'same-origin';
            meta.name = 'referrer';
            document.getElementsByTagName('head')[0].appendChild(meta);
        })
    },

    methods: {
        reload() {                    // 刷新页面
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/reset.css";
@import "~@/assets/font/font.css";
@import "~@/styles/public.css";
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
    font-size: 16px;
}

/* 
enter-active 定义进入过渡的结束状态
leave-active 定义离开过渡的结束状态
 */
.fade-transform-leave-active,
.fade-transform-enter-active {
    transition: all 0.3s;
}

/* 
enter定义进入过渡的开始状态
 */
.fade-transform-enter {
    opacity: 0;
    transform: translateY(30px);
}

/* 
leave-to离场动画结束后的状态
 */
.fade-transform-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}
</style>
