<template>
  <div ref="toTop">
    <div class="mobile_style" style="height:1.35rem"></div>
    <!-- ⬆ 移动端填补顶栏空间的标签不能删除 -->
    <el-row class="pc_style">
      <el-col :span="3">
        <div class="category_box">
          <div class="case_name">{{ selectedList[0].name }}</div>
          <div class="category_body">
            <div v-for="(categoryItem, categoryIndex) in categoryList" :key="categoryIndex"
              @click="routerLink(categoryItem.id)">
              <div class="category_title" v-if="categoryItem.isSeleted">
                {{ categoryItem.name }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="21" class="waterfallBox">
        <el-row>
          <el-col :span="17" class="leftBox">
            <el-row class="bigImg">
              <img :src="selectedImg[0].imageUrl" alt="" v-show="selectedImg[0].imageUrl == '' ? false : true">
            </el-row>
            <el-row class="title">{{ title }}</el-row>
            <el-row>
              <el-col :span="12" class="text" v-html="description"></el-col>
              <el-col :span="12" class="text">
                <el-row>
                  <el-col :span="6" class="smallTit">服务客户：</el-col>
                  <el-col :span="18">{{ customerName }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="smallTit">落地城市：</el-col>
                  <el-col :span="18">{{ cityAddress }}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7" class="rightBox">
            <el-row class="smallImg" v-for="(item, index) in imgList" :key="index" @click="changeActive(item.imageUrl)">
              <img :src="item.imageUrl" alt="">
            </el-row>
          </el-col>
        </el-row>
        <el-row class="pageNum">
          <el-col :span="8"><span class="pageBtn" @click="prev()">上一个</span></el-col>
          <el-col :span="8" class="backList"><router-link to="/typicalCase">返回列表</router-link></el-col>
          <el-col :span="8"><span class="pageBtn" @click="next()">下一个</span></el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="waterfallBox mobile_style">
      <div class="img_scroll">
        <el-row class="mobileImg" v-for="(item, index) in imgList" :key="index" @click="changeActive(item.imageUrl)">
          <img :src="item.imageUrl" alt="">
        </el-row>
      </div>
      <!-- <el-row class="bigImg">
          <img :src="selectedImg[0].imageUrl" alt="" v-show="selectedImg[0].imageUrl == '' ? false : true">
        </el-row> -->
      <el-row class="title">{{ title }}</el-row>
      <div class="text" v-html="description"></div>
      <el-row class="smallTit">服务客户：{{ customerName }}</el-row>
      <el-row class="smallTit">落地城市：{{ cityAddress }}</el-row>
    </div>
  </div>
</template>

<script>
import { Server } from '@/utils/server';

export default {
  name: "caseDetail",
  data() {
    return {
      title: '',
      cityAddress: '',
      customerName: '',
      description: '',
      categoryList: [
        {
          id: '0',
          name: "全部案例",
          isSeleted: false,
        },
        // {
        //   id: 1,
        //   name: "全部案例",
        //   isSeleted: false,
        // },
        // {
        //   id: 2,
        //   name: "商业地产",
        //   isSeleted: true,
        // },
        // {
        //   id: 3,
        //   name: "政府数字化",
        //   isSeleted: true,
        // },
        // {
        //   id: 4,
        //   name: "智慧园区",
        //   isSeleted: true,
        // },
        // {
        //   id: 5,
        //   name: "智慧展馆",
        //   isSeleted: true,
        // },
        // {
        //   id: 6,
        //   name: "智慧校园",
        //   isSeleted: true,
        // },
      ],
      selectedList: [
        {
          id: 1,
          name: "全部案例",
          isSeleted: false,
        },
      ],
      imgList: [
        // {
        //   imageUrl: require('@/assets/image/YinXiang.png')
        // },
        // {
        //   imageUrl: require('@/assets/image/WuHan.png')
        // },
        // {
        //   imageUrl: require('@/assets/image/test3.png')
        // },
        // {
        //   imageUrl: require('@/assets/image/test4.png')
        // },
      ],
      selectedImg: [
        {
          imageUrl: ""
        },
      ],
      dataGroup: [],
      currentData: "",
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.id && from.path == "/caseDetail") {
        this.getCaseDesc(this.$route.query.id)
      }
    }
  },
  mounted() {
    this.getCaseMenu();
    if (this.$route.query != undefined) {
      // console.log('mounted---', this.$route.query.id);
      this.getCaseDesc(this.$route.query.id);
    }
    this.getCaseList();
    this.androidScrollTop();
  },
  methods: {
    androidScrollTop() {//手机安卓端微信内置浏览器切换页面复位到页面顶部
      this.$store.commit('whichDeviceFun')
      if (!this.$store.state.mobileOrPC) {
        this.$refs.toTop.scrollIntoView()
      }
    },
    getCaseDesc(id) {
      this.imgList = [];
      Server.getCaseInfo(id).then(res => {
        this.title = res.title;
        this.cityAddress = res.cityAddress;
        this.customerName = res.customerName;
        this.description = res.description;
        this.selectedImg[0].imageUrl = res.cover;
        if (res.picUrl != "" && res.picUrl != undefined) {
          let currentImgArr = res.picUrl.split(',');
          for (let i = 0; i < currentImgArr.length; i++) {
            this.imgList.push({
              imageUrl: currentImgArr[i]
            })
          }
          // console.log(this.imgList);
        }
      })
    },
    changeActive(imageUrl) {
      this.selectedImg = [];
      for (const key in this.imgList) {
        if (Object.hasOwnProperty.call(this.imgList, key)) {
          const element = this.imgList[key];
          if (element.imageUrl === imageUrl) {
            this.selectedImg.push(element);
          }
        }
      }
    },
    routerLink(val) {
      this.$router.push({
        path: "/typicalCase",
        query: {
          type: val
        }
      })
    },
    getCaseList(params) {
      Server.getCaseList(params).then(res => {
        // console.log(res.records);
        const self = this;
        this.dataGroup = res.records;
        const filterData = res.records.filter(function (v, i) {
          if (v.id == self.$route.query.id) {
            self.index = i;
            return v
          }
        });
        this.currentData = filterData[0];
        console.log('prevNext!!!', this.dataGroup);
      })
    },
    prev() {
      if (this.index > 0) {
        this.index -= 1;
        this.currentData = this.dataGroup[this.index];
        this.$router.push({
          path: "/caseDetail",
          query: {
            id: this.currentData.id
          }
        })
      } else {
        this.$bus.$emit('state', true);
        this.$bus.$emit('msg', '已经是第一条');
        // alert('已经是第一条')
      }
    },
    next() {
      if (this.index < this.dataGroup.length - 1) {
        this.index += 1;
        this.currentData = this.dataGroup[this.index];
        this.$router.push({
          path: "/caseDetail",
          query: {
            id: this.currentData.id
          }
        })
      } else {
        this.$bus.$emit('state', true);
        this.$bus.$emit('msg', '已经是最后一条');
        // alert('已经是最后一条')
      }
    },
    getCaseMenu() {
      Server.getCaseMenu().then(res => {
        for (let i = 0; i < res.length; i++) {
          this.categoryList.push({
            id: res[i].id,
            name: res[i].label,
            isSeleted: true
          })
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped >
.category_box {
  width: 240px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 120px;

  .case_name {
    padding: 100px 0;
    text-align: center;
    font-size: 30px;
    font-family: Alimama_ShuHeiTi_Bold;
    font-weight: bold;
    color: #313131;
  }

  .category_body {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 200px;

    .category_title {
      display: flex;
      align-items: center;
      margin: 15px 0;
      font-size: 20px;
      color: #999999;
      cursor: pointer;
    }
  }
}

.waterfallBox {

  .title {
    font-size: 40px;
    font-weight: bold;
    padding-left: 40px;
  }

  .text {
    font-size: 20px;
    padding: 40px;
  }

  .leftBox {
    padding: 40px 0;

    .bigImg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;

      img {
        max-width: 100%;
        // width: 1120px;
        height: 630px;
      }
    }

    .smallTit {
      font-weight: bolder;
    }
  }

  .rightBox {
    padding: 40px 0;
    height: 1080px;
    overflow: auto;

    .smallImg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      img {
        // width: 400px;
        max-width: 100%;
        height: 225px;
      }
    }
  }

  .pageNum {
    height: 102px;
    line-height: 102px;
    border-top: 1px solid #313131;
    text-align: center;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .backList {
      border-left: 1px solid #313131;
      border-right: 1px solid #313131;
    }

    .pageBtn {
      cursor: pointer;
    }
  }
}

.mobile_style {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .pc_style {
    display: none;
  }

  .mobile_style {
    display: block;
    // margin-top: 1.35rem;
    margin-bottom: 0.7rem;
  }

  .waterfallBox {
    .img_scroll {
      overflow: auto;
      height: 6rem;
      padding: 0.3rem;
      display: flex;
      flex-direction: row;

      .mobileImg {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0.2rem;

        img {
          max-height: 5rem;
        }
      }
    }

    .title {
      font-size: 0.5rem;
      padding: 0.3rem;
    }

    .text {
      font-size: 0.3rem;
      min-height: 2rem;
    }

    .smallTit {
      padding: 0.1rem 0.3rem;
      font-size: 0.3rem;
    }
  }

}
</style>
