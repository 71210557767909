<template>
    <div class="mainBox pc_style">
        <div class="centerBlock">
            <div class="logoBox">
                <img src="../assets/image/logo.svg" alt="" @click="routerLink({ route: '/' })">
            </div>
            <div class="menuBlock">
                <div class="insideBlock">
                    <ul class="firstMenuBox">
                        <li v-for="(item, index) in menulist" @click="routerLink(item)" :key="index"
                            :class="[item.active ? 'menu_li_active' : '']" class="firstMenu"
                            @mouseover="showSecondMenu(item.id)" @mouseleave="closeSecondMenu(item.id)">
                            <span class="fontHoverBorderBlack">{{ item.name }}</span>
                            <ul class="secondMenuBox" v-if="item.children != undefined" :id="'box' + item.id">
                                <li class="secondMenu" v-for="(itemSe, indexSe) in item.children" :key="indexSe"
                                    :class="[itemSe.active ? 'menu_li_active' : '']" @click.stop="routerLink(itemSe)">
                                    <span class="topBarSecondMenu">{{ itemSe.name }}</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="insideBlock searchBtn" @click="this.$bus.$emit('searchState', true)">
                    <img src="../assets/image/search_icon.png" alt="">
                </div>
                <div class="insideBlock phoneBlock">
                    <img src="../assets/image/phone_icon.png" alt="">
                    400-996-9002
                </div>
            </div>
        </div>
        <div class="openMenuBox" id="openMenuBox" v-show="showMenu" :class="[nowMenu ? 'menu1' : 'menu2']"></div>
    </div>
    <div class="mobile_style">
        <div class="msBox">
            <div class="ms_logo">
                <img src="../assets/image/logo.svg" alt="">
            </div>
            <div class="ms_search" @click="this.$bus.$emit('searchState', mobileSearchBoxState())">
                <img src="../assets/image/search_icon.png" alt="">
            </div>
            <div class="ms_menu" @click="mobileMenuState">
                <img src="../assets/image/mobile_menu_icon.png" alt="">
            </div>
        </div>
        <div class="ms_menuBox" v-show="showMobileMenu">
            <ul class="ms_firstMenuBox">
                <li v-for="(item, index) in menulist" :key="index" class="ms_firstMenu_li">
                    <span class="ms_firstMenu_span" @click="showChildren(item.id,item)">{{ item.name }}</span>
                    <ul class="ms_secondMenuBox" v-if="item.children != undefined" :style="item.active ? '' : 'display:none'">
                        <li class="ms_secondMenu" v-for="(itemSe, indexSe) in item.children" :key="indexSe">
                            <span class="ms_secondMenu_span" @click.stop="clickSecondMenu(itemSe)">{{ itemSe.name }}</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>
  
<script>
import { Server } from "@/utils/server";

export default {
    name: 'TopBar',
    data() {
        return {
            showMenu: false,
            showMobileMenu: false,
            menulist: [
                {
                    id: 1,
                    name: '首页',
                    route: '/',
                    active: true,
                },
                {
                    id: 2,
                    name: '产品中心',
                    route: '/productCenter',
                    data: 'navigation',
                    active: false,
                    children: [
                        {
                            id: 2.1,
                            route: '/productCenter',
                            name: '导视导览系统',
                            data: 'navigation',
                            active: false
                        },
                        {
                            id: 2.2,
                            route: '/productCenter',
                            name: '会员营销系统',
                            data: 'memberMarking',
                            active: false
                        },
                        {
                            id: 2.3,
                            route: '/productCenter',
                            name: '商户服务系统',
                            data: 'merchantServices',
                            active: false
                        },
                        {
                            id: 2.4,
                            route: '/productCenter',
                            name: '交互商显',
                            data: 'interactiveView',
                            active: false
                        },
                    ]
                },
                {
                    id: 3,
                    name: '解决方案',
                    route: '/solution',
                    data: 'commercialRealEstate',
                    active: false,
                    children: [
                        {
                            id: 3.1,
                            route: '/solution',
                            name: '商业地产',
                            data: 'commercialRealEstate',
                            active: false
                        },
                        {
                            id: 3.2,
                            route: '/solution',
                            name: '政府数字化',
                            data: 'governmentDigitization',
                            active: false
                        },
                        {
                            id: 3.3,
                            route: '/solution',
                            name: '智慧园区',
                            data: 'smartPark',
                            active: false
                        },
                        {
                            id: 3.4,
                            route: '/solution',
                            name: '智慧展馆',
                            data: 'smartExhibitionHall',
                            active: false
                        },
                        {
                            id: 3.5,
                            route: '/solution',
                            name: '智慧校园',
                            data: 'smartCampus',
                            active: false
                        },
                    ]
                },
                {
                    id: 4,
                    name: '经典案例',
                    route: '/typicalCase',
                    active: false,
                },
                {
                    id: 5,
                    name: '资讯中心',
                    route: '/informationCenter',
                    active: false,
                },
                {
                    id: 6,
                    name: '关于云止',
                    route: '/aboutUs',
                    active: false,
                },
                {
                    id: 7,
                    name: '加入我们',
                    route: '/joinUs',
                    active: false,
                },
            ],
            menuActiveId: parseInt(sessionStorage.getItem('yunpointActive')),
            nowMenu: true,
            beforeRoute: "",
            showMobileSearch: false
        }
    },
    mounted() {
        if (this.menuActiveId == undefined) {
            sessionStorage.setItem('yunpointActive', 1);
            this.menuActiveId = 1;
            this.beforeRoute = this.$route.path
            this.tracking();
        }
        this.changeMenuActive(this.menuActiveId);
    },
    watch: {
        $route(to, from) {
            this.beforeRoute = from.fullPath;
            // console.log("从哪来---", from.fullPath);
            // console.log("到哪去---", to.fullPath);
            let currentPath = to.path;
            if (to.path == "/informationDetail") {
                currentPath = "/informationCenter"
            }
            if (to.path == "/caseDetail") {
                currentPath = "/typicalCase";
            }
            for (const key in this.menulist) {
                if (Object.hasOwnProperty.call(this.menulist, key)) {
                    const element = this.menulist[key];
                    if (element.route === currentPath) {
                        element.active = true;
                        sessionStorage.setItem('yunpointActive', element.id);
                        this.menuActiveId = element.id;
                    } else {
                        element.active = false;
                    }
                }
            }
            this.tracking();
        }
    },
    methods: {
        changeMenuActive(itemId) {
            for (const key in this.menulist) {
                if (Object.hasOwnProperty.call(this.menulist, key)) {
                    const element = this.menulist[key];
                    if (element.id === itemId) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                }
            }
            sessionStorage.setItem('yunpointActive', itemId);
            this.menuActiveId = itemId;
        },
        routerLink(item) {
            this.$router.push({
                path: item.route,
                query: {
                    data: item.data
                }
            })
        },
        showSecondMenu(num) {
            let ele = document.getElementById('box' + num);
            if (ele != undefined) {
                this.showMenu = true;
                ele.style.display = "block"
                if (num == 2) {
                    this.nowMenu = true;
                }
                if (num == 3) {
                    this.nowMenu = false;
                }
            }
        },
        closeSecondMenu(num) {
            let ele = document.getElementById('box' + num);
            if (ele != undefined) {
                this.showMenu = false;
                ele.style.display = "none"
            }
        },
        tracking() {
            let params = {
                moduleName: this.routeToString(this.beforeRoute),
            }
            Server.tracking(params).then(res => {
                console.log(res)
            })
        },
        routeToString(data) {
            let currentArr = data.split("?"), currentData = "";
            if (currentArr.length > 1) {
                currentData = currentArr[1].split("data=");
            }
            console.log(currentArr)
            switch (currentArr[0]) {
                case "/":
                    return "首页"
                case "/productCenter":
                    return this.productCenterType(currentData);
                case "/solution":
                    return this.solutionType(currentData);
                case "/typicalCase":
                    return "经典案例"
                case "/caseDetail":
                    return "案例详情"
                case "/informationCenter":
                    return "资讯中心"
                case "/informationDetail":
                    return "资讯详情"
                case "/aboutUs":
                    return "关于云止"
                case "/joinUs":
                    return "加入我们"
            }
        },
        productCenterType(arr) {
            // console.log(arr);
            if (arr[1] == "navigation") {
                return "产品中心——导视导览系统"
            }
            if (arr[1] == "memberMarking") {
                return "产品中心——会员营销系统"
            }
            if (arr[1] == "merchantServices") {
                return "产品中心——商户服务系统"
            }
            if (arr[1] == "interactiveView") {
                return "产品中心——交互商显"
            }
        },
        solutionType(arr) {
            // console.log(arr);
            if (arr[1] == "commercialRealEstate") {
                return "解决方案——商业地产"
            }
            if (arr[1] == "governmentDigitization") {
                return "解决方案——政府数字化"
            }
            if (arr[1] == "smartPark") {
                return "解决方案——智慧园区"
            }
            if (arr[1] == "smartExhibitionHall") {
                return "解决方案——智慧展馆"
            }
            if (arr[1] == "smartCampus") {
                return "解决方案——智慧校园"
            }
        },
        mobileMenuState(){
            this.showMobileMenu = !this.showMobileMenu
        },
        showChildren(id,item){
            this.changeMenuActive(id)
            // console.log('show~~~',id,item)
            if(id != 2 && id != 3){
                this.routerLink(item)
                this.showMobileMenu = false
            }
        },
        clickSecondMenu(item){
            this.routerLink(item)
            this.showMobileMenu = false
        },
        mobileSearchBoxState(){
            this.showMobileSearch = !this.showMobileSearch
            return this.showMobileSearch
        },
    }
}
</script>

<style lang="scss" scoped>
.mainBox {
    display: flex;
    border-top: 2px solid #313131;
    border-bottom: 2px solid #313131;
    height: 120px;
    position: relative;

    .centerBlock {
        width: 100%;
        display: flex;
        padding: 0 80px;
        justify-content: space-between;
        box-sizing: border-box;

        .logoBox {
            width: 191px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 191px;
                height: 116px;
                cursor: pointer;
            }
        }

        .menuBlock {
            display: flex;
            justify-content: center;

            .topBarMenu {
                font-size: 20px;
                color: #313131;
            }
        }

        .insideBlock {
            display: flex;
            padding: 0 20px;
            align-items: center;
            justify-content: center;

            .firstMenuBox {
                display: flex;

                .firstMenu {
                    display: flex;
                    height: 120px;
                    line-height: 90px;
                    justify-content: center;
                    padding: 10px;
                    margin: 0 20px;
                    font-size: 20px;
                    cursor: pointer;

                    .secondMenuBox {
                        width: 200px;
                        background-color: #fff;
                        text-align: center;
                        z-index: 999;
                        position: absolute;
                        top: 120px;
                        display: none;

                        .secondMenu {
                            width: 100%;
                            height: 75px;
                            font-size: 16px;
                            font-weight: normal;
                            background-color: #fff;
                            padding: 15px 43px;

                            span {
                                display: flex;
                                width: auto;
                                height: 30px;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .secondMenu:hover {
                            font-weight: bold;
                        }
                    }
                }

                .firstMenu:hover {
                    font-weight: bold;
                }

                .menu_li_active {
                    font-weight: bold;
                    position: relative;
                }

                .menu_li_active::after {
                    content: '';
                    display: block;
                    width: 40%;
                    height: 3px;
                    background-color: #313131;
                    position: absolute;
                    top: 98px;
                    left: 10px;
                    bottom: -10px;
                }
            }
        }

        .searchBtn {
            width: 120px;
            background-color: #313131;

            img {
                width: 40px;
                height: 40px;
            }

            cursor: pointer;
        }

        .phoneBlock {
            font-size: 24px;

            img {
                width: 28px;
                height: 28px;
                margin: 0 10px;
            }
        }
    }

}

.openMenuBox {
    width: 100%;
    position: absolute;
    top: 120px;
    background-color: #fff;
    border-bottom: 1px solid #313131;
    z-index: 99;
}

.menu1 {
    height: 305px;
}

.menu2 {
    height: 380px;
}

.msBox {
    border-top: 1px solid #313131;
    border-bottom: 1px solid #313131;
    height: 1.35rem;
    position: relative;

    .ms_logo {
        width: 2rem;
        position: absolute;
        left: 0.25rem;

        img {
            width: 2rem;
            height: 1.35rem;
        }
    }

    .ms_search {
        width: 1.45rem;
        height: 1.35rem;
        position: absolute;
        right: 1.7rem;
        background-color: #313131;

        img {
            width: 0.5rem;
            height: 0.5rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .ms_menu {
        width: 1.45rem;
        height: 1.35rem;
        position: absolute;
        right: 0.25rem;

        img {
            width: 0.65rem;
            height: 0.65rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.ms_menuBox{
    background-color: #313131;
    color: #fff;
    font-weight: bold;
    text-align: center;
    position: relative;
    z-index: 99999;
    .ms_firstMenuBox{
        .ms_firstMenu_li{
            height: auto;
            .ms_firstMenu_span{
                display: block;
                width: 100%;
                height: 1.5rem;
                line-height: 1.5rem;
                font-size: 80px;
            }
            .ms_secondMenuBox{
                background-color: #393939;
                border-top: 1px solid #000;
                .ms_secondMenu{
                    .ms_secondMenu_span{
                        font-weight: normal;
                        display: block;
                        width: 100%;
                        height: 1.5rem;
                        line-height: 1.5rem;
                        border-bottom: 1px solid #000;
                        font-size: 70px;
                    }
                }
            }
        }
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        background-color: #fff;
    }
}
</style>
  