<template>
  <div ref="waterRipple" class="water-ripple" :id="containerId"></div>
</template>

<script>
import { WaterRipple } from "@/utils/WaterRipple";
import { ref } from 'vue'
import { debounce } from 'lodash';
export default {
  name: "WaterRipple",
  setup() {
    const waterRipple = ref()
    return {
      waterRipple
    }
  },
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      canvasWidth: '',
      canvasHeight: '',
      waterRippleCom: null,
      containerId: 'Water' + new Date()
    };
  },
  mounted() {
    this.init();
    // 调整尺寸后，重新绘制
    // 实例化 debounce 函数
    const debounceFunc = debounce(function() {
      console.log(this.canvasHeight)
      this.setCanvas();
      this.waterRippleCom.resetCanvas({
        width: this.canvasWidth,
        height: this.canvasHeight
      });
    }.bind(this), 500);
    window.onresize = () => {
      debounceFunc()
    }
  },
  methods: {
    setCanvas() {
      this.canvasWidth = this.waterRipple.offsetWidth
      this.canvasHeight = this.waterRipple.offsetHeight
    },
    init() {
      let _self = this
      //Settings - params for WaterRippleEffect
      this.setCanvas()
      var settings = {
        image: _self.src, //image path
        dropRadius: 3, //radius of the ripple
        width: _self.canvasWidth, //width
        height: _self.canvasHeight, //height
        delay: 3, //if auto param === true. 1 === 1 second delay for animation
        auto: false, //if auto param === true, animation starts on it´s own
      };

      //init
      var waterRippleEffect = new WaterRipple(
        document.getElementById(this.containerId),
        settings
      );
      _self.waterRippleCom = waterRippleEffect
      document.getElementById(this.containerId).style.cursor = "pointer";

      //on click
      document.getElementById(this.containerId).addEventListener("click", function (e) {
        var mouseX = e.layerX;
        var mouseY = e.layerY;
        waterRippleEffect.disturb(mouseX, mouseY);
      });

      //on mousemove
      document
        .getElementById(this.containerId)
        .addEventListener("mousemove", function (e) {
          var mouseX = e.layerX;
          var mouseY = e.layerY;
          waterRippleEffect.disturb(mouseX, mouseY);
        });

      document.onkeydown = function (e) {
        var event = e || window.event || arguments.callee.caller.arguments[0];
        if (event && event.keyCode == 13) {
          // enter 键
          waterRippleEffect.disturb(200, 200);
        }
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.water-ripple {
  width: 100%;
  height: 100%;
  // position: absolute;
  // cursor: pointer;
  text-align: center;
}
</style>