<template>
    <div class="partnerBox">
        <div class="moveBox">
            <div class="block" v-for="(item, index) in imageList" :key="index">
                <img :src="item.src" alt="">
            </div>
        </div>
        <div class="moveBox2">
            <div class="block" v-for="(item, index) in imageList" :key="index">
                <img :src="item.src" alt="">
            </div>
        </div>
    </div>
</template>
  
<script>
import { Server } from '../utils/server';

export default {
    name: 'PartnerHomePage',
    data() {
        return {
            imageList: [
                // {
                //     id: 1,
                //     src: require('@/assets/image/JoinUs_icon1.png'),
                // },
                // {
                //     id: 2,
                //     src: require('@/assets/image/JoinUs_icon2.png'),
                // },
                // {
                //     id: 3,
                //     src: require('@/assets/image/JoinUs_icon3.png'),
                // },
                // {
                //     id: 4,
                //     src: require('@/assets/image/JoinUs_icon4.png'),
                // },
                // {
                //     id: 5,
                //     src: require('@/assets/image/JoinUs_icon5.png'),
                // },
                // {
                //     id: 6,
                //     src: require('@/assets/image/JoinUs_icon6.png'),
                // },
                // {
                //     id: 7,
                //     src: require('@/assets/image/JoinUs_icon7.png'),
                // },
                // {
                //     id: 8,
                //     src: require('@/assets/image/JoinUs_icon8.png'),
                // },
                // {
                //     id: 9,
                //     src: require('@/assets/image/JoinUs_icon9.png'),
                // },
                // {
                //     id: 10,
                //     src: require('@/assets/image/JoinUs_icon10.png'),
                // },
                // {
                //     id: 11,
                //     src: require('@/assets/image/JoinUs_icon6.png'),
                // },
                // {
                //     id: 12,
                //     src: require('@/assets/image/JoinUs_icon7.png'),
                // },
                // {
                //     id: 13,
                //     src: require('@/assets/image/JoinUs_icon8.png'),
                // },
                // {
                //     id: 14,
                //     src: require('@/assets/image/JoinUs_icon9.png'),
                // },
                // {
                //     id: 15,
                //     src: require('@/assets/image/JoinUs_icon10.png'),
                // },
            ]
        }
    },
    mounted() {
        this.getLogoWall();
    },
    methods: {
        getLogoWall() {
            Server.getLogoWall().then(res => {
                // console.log(res)
                for (let i = 0; i < res.length; i++) {
                    this.imageList.push({
                        src: res[i].logo
                    })
                }
            })
        }
    }
}
</script>
  
<style lang="scss" scoped>
@keyframes loop1 {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes loop2 {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

.partnerBox {
    width: 100%;
    height: 150px;
    margin-bottom: 30px;
    position: relative;

    .moveBox {
        display: flex;
        justify-content: flex-end;
        animation: loop1 30s infinite;
        animation-timing-function: linear;
    }

    .moveBox2 {
        display: flex;
        margin-top: 60px;
        animation: loop2 30s infinite;
        animation-timing-function: linear;
    }

    .moveBox,
    .moveBox2 {

        .block {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 200px;
            height: 150px;
            border: 1px solid rgba($color: #313131, $alpha: 0.1);
            margin: 0 30px;

            img {
                height: 82px;
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .partnerBox {
        width: 100%;
        height: 2.5rem;
        margin-bottom: 0.1rem;
        position: relative;

        .moveBox {
            display: flex;
            justify-content: flex-end;
            animation: loop1 30s infinite;
            animation-timing-function: linear;
        }

        .moveBox2 {
            display: flex;
            margin-top: 0.5rem;
            animation: loop2 30s infinite;
            animation-timing-function: linear;
        }

        .moveBox,
        .moveBox2 {

            .block {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 3rem;
                height: 2.5rem;
                border: 1px solid rgba($color: #313131, $alpha: 0.1);
                margin: 0 0.1rem;

                img {
                    height: 1.5rem;
                }
            }
        }
    }
}
</style>
  