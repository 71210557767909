<template>
  <div ref="toTop">
    <div class="mobile_style" style="height:1.35rem"></div>
    <!-- ⬆ 移动端填补顶栏空间的标签不能删除 -->
    <el-row class="pc_style">
      <el-col :span="3">
        <div class="category_box">
          <div class="case_name">{{ selectedList[0].name }}</div>
          <div class="category_body">
            <div v-for="(categoryItem, categoryIndex) in categoryList" :key="categoryIndex"
              @click="switchCategoryChange(categoryItem.id)">
              <div class="category_title" v-if="categoryItem.isSeleted">
                {{ categoryItem.name }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="21">
        <div :class="[dataList.length === 0 ? 'case_no_data' : '']" v-if="dataList.length > 0" class="waterfallBox">
          <div v-masonry transition-duration="0s" item-selector=".imgBox" column-width=".imgBox" destroy-delay="0"
            fit-width="false" id="waterfallContainer" class="waterfallInsideBox">
            <div v-masonry-tile class="item" v-for="(item, index) in dataList" :key="index">
              <div class="imgBox" @click="toDetail(item.id)">
                <div class="maskBox"></div>
                <img :src="item.url" alt="">
                <div class="text">
                  <el-row class="text_title">{{ item.name }}</el-row>
                  <el-row>{{ item.address }}</el-row>
                </div>
              </div>
            </div>
          </div>
          <el-row class="moreBox">
            <div class="moreBtn" @click="getMore()" v-show="!noMore">加载更多</div>
            <el-row v-show="noMore" class="noMoreTips">没有更多了</el-row>
          </el-row>
        </div>
        <div class="case_no_data" v-else>
          <div class="">暂无数据</div>
        </div>
      </el-col>
    </el-row>
    <div class="mobile_style">
      <el-row class="keyword_label">
        <div class="keywordBlock" v-for="(item, index) in categoryList" :class="[item.isSeleted ? '' : 'keywordActive']"
          :key="index" @click="switchCategoryChange(item.id)">{{
            item.name }}</div>
      </el-row>
      <div :class="[dataList.length === 0 ? 'case_no_data' : '']" v-if="dataList.length > 0" class="waterfallBox">
        <div v-masonry transition-duration="0s" item-selector=".imgBox" column-width=".imgBox" destroy-delay="0"
          fit-width="false" id="waterfallContainer" class="waterfallInsideBox">
          <div v-masonry-tile class="item" v-for="(item, index) in dataList" :key="index">
            <div class="imgBox" @click="routerLink(item.id)">
              <div class="maskBox"></div>
              <img :src="item.url" alt="">
              <div class="text">
                <el-row class="text_title">{{ item.name }}</el-row>
                <el-row>{{ item.address }}</el-row>
              </div>
            </div>
          </div>
        </div>
        <el-row class="moreBox">
          <div class="moreBtn" @click="getMore()" v-show="!noMore">加载更多</div>
          <el-row v-show="noMore" class="noMoreTips">没有更多了</el-row>
        </el-row>
      </div>
      <div class="case_no_data" v-else>
        <div class="">暂无数据</div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { Server } from '@/utils/server';

export default {
  name: "caseList",
  data() {
    return {
      dataList: [
        // {
        //   id: 1,
        //   name: "武汉天地",
        //   address: "瑞安新天地",
        //   url: require("@/assets/image/WuHan.png"),
        // },
        // {
        //   id: 2,
        //   name: "虹桥天地",
        //   address: "虹桥路333号",
        //   url: require("@/assets/image/test3.png"),
        // },
        // {
        //   id: 3,
        //   name: "印象城",
        //   address: "南翔路113号",
        //   url: require("@/assets/image/YinXiang.png"),
        // },
        // {
        //   id: 4,
        //   name: "城市广场",
        //   address: "淄博城市路633号",
        //   url: require("@/assets/image/test4.png"),
        // },
        // {
        //   id: 3,
        //   name: "印象城",
        //   address: "南翔路113号",
        //   url: require("@/assets/image/WuHan.png"),
        // },
      ],
      categoryList: [
        {
          id: '0',
          name: "全部案例",
          isSeleted: false,
        },
        // {
        //   id: 2,
        //   name: "商业地产",
        //   isSeleted: true,
        // },
        // {
        //   id: 3,
        //   name: "政府数字化",
        //   isSeleted: true,
        // },
        // {
        //   id: 4,
        //   name: "智慧园区",
        //   isSeleted: true,
        // },
        // {
        //   id: 5,
        //   name: "智慧展馆",
        //   isSeleted: true,
        // },
        // {
        //   id: 6,
        //   name: "智慧校园",
        //   isSeleted: true,
        // },
      ],
      selectedList: [
        {
          id: '0',
          name: "全部案例",
          isSeleted: false,
        },
      ],
      totalPage: "",
      currentPages: 1,
      noMore: false,
      isSearch: false,//判断是否是在列表追加数据 false:追加 true:不追加
      onoff: true
    };
  },
  mounted() {
    this.getCaseMenu();
    this.androidScrollTop();
  },
  methods: {
    androidScrollTop() {//手机安卓端微信内置浏览器切换页面复位到页面顶部
      this.$store.commit('whichDeviceFun')
      if (!this.$store.state.mobileOrPC) {
        this.$refs.toTop.scrollIntoView()
      }
    },
    switchCategoryChange(itemId) {
      // console.log('---', itemId)
      this.selectedList = [];
      this.isSearch = true;
      this.currentPages = 1;
      for (const key in this.categoryList) {
        if (Object.hasOwnProperty.call(this.categoryList, key)) {
          const element = this.categoryList[key];
          if (element.id === itemId) {
            element.isSeleted = false;
            this.selectedList.push(element);
            if (element.id != '0') {
              this.getCaseList({ params: { categoryId: this.selectedList[0].id } })
            } else {
              this.getCaseList()
            }
          } else {
            element.isSeleted = true;
          }
        }
      }
    },
    toDetail(id) {
      window.open('/caseDetail?id=' + id);
    },
    routerLink(id) {
      this.$router.push({
        path: '/caseDetail',
        query: {
          id: id
        }
      })
    },
    getCaseList(params) {
      Server.getCaseList(params).then(res => {
        // console.log(res.records, this.isSearch);
        this.totalPage = res.pages;
        this.noMore = false;
        let currentArr = res.records;
        if (this.isSearch) {
          this.dataList = [];
          setTimeout(() => {
            for (let i = 0; i < currentArr.length; i++) {
              this.dataList[i] = {
                id: currentArr[i].id,
                name: currentArr[i].title,
                address: currentArr[i].cityAddress,
                url: currentArr[i].cover
              }
            }
          }, 300)
        } else {
          for (let i = 0; i < currentArr.length; i++) {
            this.dataList.push({
              id: currentArr[i].id,
              name: currentArr[i].title,
              address: currentArr[i].cityAddress,
              url: currentArr[i].cover
            })
          }
        }
      })
    },
    getMore() {
      // console.log(this.totalPage, this.currentPages + 1);
      this.isSearch = false;
      if (this.currentPages + 1 <= this.totalPage) {
        let currentParams = {};
        console.log('getMore---', this.selectedList[0].id);
        if (this.selectedList[0].id == '0') {
          currentParams = { params: { current: this.currentPages + 1 } }
        } else {
          currentParams = { params: { current: this.currentPages + 1, categoryId: this.selectedList[0].id } }
        }
        this.getCaseList(currentParams);
        this.currentPages = this.currentPages + 1
      } else {
        this.noMore = true;
      }
    },
    getCaseMenu() {
      Server.getCaseMenu().then(res => {
        for (let i = 0; i < res.length; i++) {
          this.categoryList.push({
            id: res[i].id,
            name: res[i].label,
            isSeleted: true
          })
        }
        if (this.$route.query.type != undefined) {
          this.switchCategoryChange(this.$route.query.type);
        } else {
          this.getCaseList();
        }
      })
    }
  }
}
</script>
    
<style lang="scss" scoped >
.category_box {
  width: 240px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 120px;

  .case_name {
    padding: 100px 0;
    text-align: center;
    font-size: 30px;
    font-family: Alimama_ShuHeiTi_Bold;
    font-weight: bold;
    color: #313131;
  }

  .category_body {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 200px;

    .category_title {
      display: flex;
      align-items: center;
      margin: 15px 0;
      font-size: 20px;
      color: #999999;
      cursor: pointer;
    }
  }
}

.waterfallBox {
  overflow: auto;

  .moreBox {
    justify-content: center;
    align-items: center;
    font-size: 20px;

    .moreBtn {
      width: 248px;
      height: 60px;
      border: 1px solid #313131;
      text-align: center;
      line-height: 60px;
      margin: 100px 0;
      cursor: pointer;
    }

    .noMoreTips {
      margin: 100px 0;
    }
  }

  .waterfallInsideBox {
    padding: 5px;

    .imgBox {
      margin: 10px;
      display: inline-block;
      object-fit: contain;
      vertical-align: bottom;
      cursor: url(../../../assets/image/case_right.png), pointer;

      img {
        width: 41vw;
      }

      .maskBox {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(transparent, #313131);
        // opacity: 0.5;
      }

      .text {
        color: #fff;
        position: absolute;
        left: 30px;
        bottom: 20px;
        font-size: 20px;
        font-family: MicrosoftYaHei;

        .text_title {
          font-size: 30px;
          font-weight: bolder;
        }
      }
    }
  }
}

.case_no_data {
  height: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_style {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .pc_style {
    display: none;
  }

  .mobile_style {
    display: block;
    // margin-top: 1.35rem;
  }

  .keyword_label {
    padding: 0.2rem;

    .keywordBlock {
      display: flex;
      height: 1rem;
      font-size: 0.3rem;
      line-height: 1rem;
      margin: 0.2rem;
      justify-content: center;
      padding: 0 0.3rem;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #d8d8d8;
      color: #313131;
    }

    .keywordActive {
      background-color: #313131;
      border: 1px solid #313131;
      color: #fff;
    }
  }

  .waterfallBox {
    overflow: auto;

    .moreBox {
      justify-content: center;
      align-items: center;
      font-size: 0.3rem;

      .moreBtn {
        width: 4rem;
        height: 1rem;
        border: 1px solid #313131;
        text-align: center;
        line-height: 1rem;
        margin: 1rem 0;
        cursor: pointer;
      }

      .noMoreTips {
        margin: 1rem 0;
      }
    }

    .waterfallInsideBox {
      padding: 0.2rem;

      .imgBox {
        margin: 0.1rem;
        display: inline-block;
        object-fit: contain;
        vertical-align: bottom;
        cursor: url(../../../assets/image/case_right.png), pointer;

        img {
          width: 46vw;
        }

        .maskBox {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(transparent, #313131);
          // opacity: 0.5;
        }

        .text {
          color: #fff;
          position: absolute;
          left: 0.3rem;
          bottom: 0.3rem;
          font-size: 0.3rem;
          font-family: MicrosoftYaHei;

          .text_title {
            font-size: 0.3rem;
            font-weight: bolder;
          }
        }
      }
    }
  }

  .case_no_data {
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
    