<template>
    <div ref="toTop">
        <div class="mobile_style" style="height:1.35rem"></div>
        <!-- ⬆ 移动端填补顶栏空间的标签不能删除 -->
        <div class="bannarBlock">
            <div class="bannerFontBox">
                <span class="font1">资讯中心</span><br>
                <span class="font2">开创新一代"智慧空间"数字化</span>
            </div>
        </div>
        <el-row class="infoListBox pc_style">
            <el-col :span="16" class="mainList">
                <el-row class="infoListTop">
                    <span>资讯中心&nbsp;>&nbsp;{{ nowCategory }}</span>
                    <el-row class="tabBox" v-show="showCategory">
                        <div class="tabBtn" v-for="(item, index) in categoryList" :key="index"
                            :class="item.active ? 'tabBtnActive' : ''" @click="switchCategory(item)">{{ item.label }}
                        </div>
                    </el-row>
                </el-row>
                <el-row class="infoListMain">
                    <ul>
                        <li v-for="(item, index) in infoListData" :key="index" @click="toDetail(item.id)">
                            <div class="NewsBlock">
                                <el-row class="NewsTitle">{{ item.title }}</el-row>
                                <el-row class="NewsDate">{{ item.createDate }}</el-row>
                                <el-row class="NewsDetail">
                                    <p>{{ item.description }}</p>
                                </el-row>
                                <el-row class="NewsKeywordBox">
                                    <div class="NewsKeyword" v-for="(itemkey, inx) in item.tagsDesc" :key="inx">
                                        {{ itemkey }}</div>
                                </el-row>
                            </div>
                        </li>
                    </ul>
                </el-row>
            </el-col>
            <el-col :span="8" class="keywordBox">
                <el-row class="keyword_title">热门关键词</el-row>
                <el-row class="keyword_label">
                    <div class="keywordBlock" v-for="(item, index) in keywordData"
                        :class="[item.active ? 'keywordActive' : '']" :key="index" @click="switchTag(item.id, item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
            </el-col>
        </el-row>
        <el-row class="pageNum pc_style">
            <el-col :span="10" class="prevBtn" @click="prev()">上一页</el-col>
            <el-col :span="4" class="backList">
                <input v-model="currentPage" v-focus />&nbsp;/&nbsp;{{ totalPage }}
            </el-col>
            <el-col :span="10" class="nextBtn" @click="next()">下一页</el-col>
        </el-row>
        <div class="infoListBox mobile_style">
            <div class="mainList">
                <el-row class="infoListTop">
                    <el-row>资讯中心&nbsp;>&nbsp;{{ nowCategory }}</el-row>
                    <el-row class="tabBox" v-show="showCategory">
                        <div class="tabBtn" v-for="(item, index) in categoryList" :key="index"
                            :class="item.active ? 'tabBtnActive' : ''" @click="switchCategory(item)">{{ item.label }}
                        </div>
                    </el-row>
                </el-row>
                <el-row class="infoListMain">
                    <ul>
                        <li v-for="(item, index) in infoListData" :key="index" @click="routerLink(item.id)">
                            <div class="NewsBlock">
                                <el-row class="NewsTitle">{{ item.title }}</el-row>
                                <el-row class="NewsDate">{{ item.createDate }}</el-row>
                            </div>
                        </li>
                    </ul>
                </el-row>
            </div>
            <el-row class="moreBox">
                <div class="moreBtn" @click="getMore()" v-show="!noMore">加载更多</div>
                <el-row v-show="noMore" class="noMoreTips">没有更多了</el-row>
            </el-row>
        </div>
    </div>
</template>
  
<script>
import { Server } from '@/utils/server';

export default {
    name: "informationCenter",
    data() {
        return {
            showCategory: true,
            nowCategory: "全部资讯",
            nowTag: "",
            categoryList: [
                {
                    id: '00',
                    label: '全部资讯',
                    active: true,
                },
            ],
            infoListData: [
                {
                    infoTit: "租户服务平台上线瑞安新天地",
                    infoDate: "2023/03/02",
                    infoDetail: "文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述",
                    infoKeyword: [
                        "关键词1",
                        "关键词2",
                        "关键词3"
                    ]
                },
                {
                    infoTit: "标题1",
                    infoDate: "2023/03/02",
                    infoDetail: "文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述",
                    infoKeyword: [
                        "关键词1",
                        "关键词2",
                        "关键词3"
                    ]
                },
                {
                    infoTit: "标题2",
                    infoDate: "2023/03/02",
                    infoDetail: "文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述",
                    infoKeyword: [
                        "关键词1",
                        "关键词2"
                    ]
                },
                {
                    infoTit: "标题3",
                    infoDate: "2023/03/02",
                    infoDetail: "文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述",
                    infoKeyword: [
                        "关键词1",
                        "关键词2",
                        "关键词3",
                        "关键词4"
                    ]
                },
                {
                    infoTit: "标题4",
                    infoDate: "2023/03/02",
                    infoDetail: "文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述文本描述",
                    infoKeyword: [
                        "关键词1"
                    ]
                }
            ],
            keywordData: [
                {
                    id: '00',
                    label: "全部",
                    active: true
                },
            ],
            totalPage: "",
            currentPage: 1,
            noMore: false,
            isSearch: false,//判断是否是在列表追加数据 false:追加 true:不追加
        };
    },
    created() {
        let that = this;
        document.onkeypress = function (e) {
            var keycode = document.all ? event.keyCode : e.which;
            if (keycode == 13) {
                console.log('onkeypress', that.currentPage);
                that.getNews({ params: { current: that.currentPage } });   // 回车搜索事件
                return false;
            }
        };
    },
    mounted() {
        this.getInfoCategory();
        this.getTag();
        this.androidScrollTop();
    },
    methods: {
        androidScrollTop() {//手机安卓端微信内置浏览器切换页面复位到页面顶部
            this.$store.commit('whichDeviceFun')
            if (!this.$store.state.mobileOrPC) {
                this.$refs.toTop.scrollIntoView()
            }
        },
        toDetail(id) {
            window.open("/informationDetail?id=" + id);
        },
        routerLink(id) {
            this.$router.push({
                path: '/informationDetail',
                query: {
                    id: id
                }
            })
        },
        getNews(params) {
            Server.getNews(params).then(res => {
                this.infoListData = res.records;
                this.totalPage = res.pages;
                this.noMore = false;
                // console.log('---~~~', this.currentPage, this.totalPage);
            });
        },
        getInfoCategory() {
            Server.getInfoCategory().then(res => {
                let currentArr = res;
                for (let i = 0; i < currentArr.length; i++) {
                    this.categoryList.push({ id: currentArr[i].id, label: currentArr[i].label, active: false });
                }
                console.log(this.categoryList);
            })
        },
        getTag() {
            Server.getTag().then(res => {
                let currentArr = res;
                for (let i = 0; i < currentArr.length; i++) {
                    this.keywordData.push({ id: currentArr[i].id, label: currentArr[i].label, active: false });
                }
                if (this.$route.query.tagIds != undefined) {
                    // console.log('~~~', this.$route.query.tagIds, this.$route.query.tagLabel);
                    this.switchTag(this.$route.query.tagIds, this.$route.query.tagLabel)
                } else {
                    this.getNews();
                }
            })
        },
        switchCategory(item) {
            this.currentPage = 1;
            this.isSearch = true;
            for (const key in this.categoryList) {
                if (Object.hasOwnProperty.call(this.categoryList, key)) {
                    const element = this.categoryList[key];
                    if (element.id === item.id) {
                        element.active = true;
                        this.nowCategory = element.label;
                    } else {
                        element.active = false;
                    }
                }
            }
            if (item.id != '00') {
                this.getNews({ params: { categoryId: item.id } });
            } else {
                this.getNews();
            }
        },
        switchTag(itemId, itemLabel) {
            this.currentPage = 1;
            // console.log('tagloading~~~', this.currentPage, itemId);
            for (const key in this.keywordData) {
                if (Object.hasOwnProperty.call(this.keywordData, key)) {
                    const element = this.keywordData[key];
                    if (element.id === itemId) {
                        element.active = true;
                        this.nowTag = element.label;
                    } else {
                        element.active = false;
                    }
                }
            }
            if (itemId != '00') {
                this.showCategory = false;
                this.nowCategory = "关键词 > " + itemLabel;
                this.getNews({ params: { tagIds: itemId } });
            } else {
                this.showCategory = true;
                this.nowCategory = "全部资讯";
                this.switchCategory({
                    id: '00',
                    label: '全部资讯',
                    active: true,
                },);
                this.getNews();
            }
        },
        prev() {
            console.log(this.currentPage - 1);
            if (this.currentPage - 1 > 0) {
                this.getNews({ params: { current: this.currentPage - 1 } });
                this.currentPage = this.currentPage - 1;
            }
        },
        next() {
            console.log(this.currentPage + 1);
            if (this.currentPage + 1 <= this.totalPage) {
                this.getNews({ params: { current: this.currentPage + 1 } });
                this.currentPage = this.currentPage + 1
            }
        },
        getNewsMobile(params) {
            Server.getNews(params).then(res => {
                // console.log(res.records, this.isSearch);
                this.totalPage = res.pages;
                this.noMore = false;
                let currentArr = res.records;
                if (this.isSearch) {
                    this.infoListData = [];
                    setTimeout(() => {
                        for (let i = 0; i < currentArr.length; i++) {
                            this.infoListData[i] = {
                                title: currentArr[i].title,
                                createDate: currentArr[i].createDate
                            }
                        }
                    }, 300)
                } else {
                    for (let i = 0; i < currentArr.length; i++) {
                        this.infoListData.push({
                            title: currentArr[i].title,
                            createDate: currentArr[i].createDate,
                        })
                    }
                }
            })
        },
        getMore() {
            // console.log(this.totalPage, this.currentPage + 1);
            this.isSearch = false;
            if (this.currentPage + 1 <= this.totalPage) {
                let currentParams = { params: { current: this.currentPage + 1 } }
                this.getNewsMobile(currentParams);
                this.currentPage = this.currentPage + 1
            } else {
                this.noMore = true;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.bannarBlock {
    height: 560px;
    position: relative;
    border-bottom: 1px solid #313131;

    .bannerFontBox {
        position: absolute;
        top: 120px;
        left: 160px;

        .font1 {
            font-family: Alimama_ShuHeiTi_Bold;
            font-size: 68px;
            font-weight: bold;
            color: #313131;
        }

        .font2 {
            font-family: Alimama_ShuHeiTi_Bold;
            font-size: 100px;
            font-weight: bold;
            color: #313131;
        }
    }

}

.infoListBox {
    border-bottom: 1px solid #313131;

    .mainList {

        .infoListTop {
            font-size: 16px;
            color: #999;
            padding: 30px 0;
            padding-left: 140px;
            border-bottom: 1px solid #313131;

            .tabBox {

                .tabBtn {
                    width: 160px;
                    height: 60px;
                    border: 1px solid #d8d8d8;
                    font-size: 16px;
                    text-align: center;
                    line-height: 60px;
                    margin: 0 20px;
                    margin-top: 30px;
                    cursor: pointer;
                }

                .tabBtnActive {
                    background-color: #313131;
                    color: #fff;
                }
            }
        }

        .infoListMain {
            li {
                background-color: #fff;
                color: #313131;
                border-top: 1px solid #313131;

                .NewsBlock {
                    width: 65.8vw;
                    height: 208px;
                    padding: 25px 140px;

                    .NewsTitle {
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .NewsDate {
                        font-size: 16px;
                        color: #ccc !important;
                        margin: 10px 0;
                    }

                    .NewsDetail {
                        p {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            -o-text-overflow: ellipsis;
                        }
                    }

                    .NewsKeywordBox {
                        margin-top: 20px;

                        .NewsKeyword {
                            border: 1px solid #313131;
                            font-size: 16px;
                            text-align: center;
                            margin-right: 12px;
                            padding: 2px 5px;
                        }
                    }
                }

            }

            li:first-child {
                border-top: 0;
            }

            li:hover {
                background-color: #f6f6f6;
                // color: #fff;

                .NewsBlock {
                    .NewsKeywordBox {
                        .NewsKeyword {
                            // border: 1px solid #fff;
                        }
                    }
                }
            }
        }
    }

    .keywordBox {
        background-color: #f9f9f9;
        border-left: 1px solid #313131;
        padding: 80px 50px;

        .keyword_title {
            font-size: 24px;
            font-weight: bold;
        }

        .keyword_label {
            .keywordBlock {
                display: flex;
                height: 40px;
                font-size: 16px;
                line-height: 40px;
                margin: 12px;
                justify-content: center;
                padding: 0 20px;
                cursor: pointer;
                background-color: #fff;
                border: 1px solid #d8d8d8;
                color: #313131;
            }

            .keywordBlock:hover {
                border: 1px solid #313131;
            }

            .keywordActive {
                background-color: #313131;
                border: 1px solid #313131;
                color: #fff;
            }
        }
    }

}

.pageNum {
    height: 102px;
    line-height: 102px;
    border-top: 1px solid #313131;
    text-align: center;
    font-size: 20px;
    margin-top: 150px;

    .backList {
        border-left: 1px solid #313131;
        border-right: 1px solid #313131;

        input {
            border: 1px solid #f2f2f2;
            width: 50px;
            text-align: center;
            font-size: 20px;
        }
    }

    .prevBtn,
    .nextBtn {
        cursor: pointer;
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
    }

    .bannarBlock {
        height: 3rem;
        // margin-top: 1.35rem;

        .bannerFontBox {
            position: absolute;
            top: 0.75rem;
            left: 0.75rem;

            .font1 {
                font-size: 0.4rem;
            }

            .font2 {
                font-size: 0.6rem;
            }
        }
    }

    .infoListBox {
        margin-top: 0;

        .mainList {
            border-bottom: 1px solid #313131;

            .infoListTop {
                font-size: 0.3rem;
                padding: 0.3rem 0.5rem;
                height: auto;

                .tabBox {
                    width: 100%;
                    margin: 0.25rem 0;

                    .tabBtn {
                        width: 2rem;
                        height: 0.7rem;
                        font-size: 0.3rem;
                        line-height: 0.7rem;
                        margin: 0 0.1rem;
                        margin-top: 0.3rem;
                    }

                    .tabBtnActive {
                        background-color: #313131;
                        color: #fff;
                    }
                }
            }

            .infoListMain {
                ul {
                    width: 100%;

                    li {
                        border-top: 1px solid #313131;

                        .NewsBlock {
                            width: auto;
                            height: auto;
                            padding: 0.4rem 0.3rem;

                            .NewsTitle {
                                font-size: 0.4rem;
                                font-weight: bold;
                            }

                            .NewsDate {
                                font-size: 0.3rem;
                                margin: 0.1rem 0;
                            }
                        }
                    }

                    li:first-child {
                        border-top: 0;
                    }
                }
            }
        }

        .moreBox {
            justify-content: center;
            align-items: center;
            font-size: 0.3rem;

            .moreBtn {
                width: 4rem;
                height: 1rem;
                border: 1px solid #313131;
                text-align: center;
                line-height: 1rem;
                margin: 1rem 0;
                cursor: pointer;
            }

            .noMoreTips {
                margin: 1rem 0;
            }
        }
    }
}
</style>