<template>
    <div ref="toTop">
        <div class="mobile_style" style="height:1.35rem"></div>
        <!-- ⬆ 移动端填补顶栏空间的标签不能删除 -->
    <div class="infoListBox pc_style">
        <el-row>
            <el-col :span="16" class="mainList">
                <el-row class="infoListTop">
                    <span>资讯详情 > {{ infoTitle }}</span>
                </el-row>
                <el-row class="infoDetail">
                    <el-row class="infoDetail_title">{{ infoTitle }}</el-row>
                    <el-row class="infoDetail_date">{{ infoDate }}</el-row>
                    <el-row class="infoDetail_text" v-html="infoContent"></el-row>
                </el-row>
            </el-col>
            <el-col :span="8">
                <el-row class="rightBlock">
                    <el-row class="keyword_title">热门资讯</el-row>
                    <el-row class="hotList">
                        <ul>
                            <li v-for="(item, index) in hotList" :key="index" @click="toHot(item.id)">
                                <el-row>
                                    <el-col :span="3" :class="[item.red ? 'redIcon' : '']">TOP{{ index + 1 }}</el-col>
                                    <el-col :span="21" class="hotTitle">{{ item.title }}</el-col>
                                </el-row>
                            </li>
                        </ul>
                    </el-row>
                </el-row>
                <el-row class="rightBlock">
                    <el-row class="keyword_title">热门关键词</el-row>
                    <el-row class="keyword_label">
                        <div class="keywordBlock" v-for="(item, index) in keywordData"
                            :class="[item.active ? 'keywordActive' : '']" :key="index" @click="switchTag(item)">{{
                                item.label }}</div>
                    </el-row>
                </el-row>
            </el-col>
        </el-row>
        <el-row class="pageNum">
            <el-col :span="10"><span class="pageBtn" @click="prev()">上一个</span></el-col>
            <el-col :span="4" class="backList"><router-link to="/informationCenter">返回列表</router-link></el-col>
            <el-col :span="10"><span class="pageBtn" @click="next()">下一个</span></el-col>
        </el-row>
    </div>
    <div class="infoListBox mobile_style">
        <div class="bannarBlock">
            <div class="bannerFontBox">
                <span class="font1">资讯中心</span><br>
                <span class="font2">开创新一代"智慧空间"数字化</span>
            </div>
        </div>
        <div class="mainList">
            <el-row class="infoListTop">
                <span>资讯详情 > {{ infoTitle }}</span>
            </el-row>
            <el-row class="infoDetail">
                <el-row class="infoDetail_title">{{ infoTitle }}</el-row>
                <el-row class="infoDetail_date">{{ infoDate }}</el-row>
                <el-row class="infoDetail_text" v-html="infoContent"></el-row>
            </el-row>
        </div>
    </div>
    </div>
</template>
  
<script>
import { Server } from '@/utils/server'

export default {
    name: 'informationDetail',
    data() {
        return {
            hotList: [],
            keywordData: [
                {
                    id: '00',
                    label: "全部",
                    active: false
                },
            ],
            infoTitle: "",
            infoContent: "",
            infoDate: "",
            dataGroup: [],
            currentData: "",
            currentTag: [],
        }
    },
    watch: {
        $route(to, from) {
            if (this.$route.query.id && from.path == "/informationDetail") {
                this.getInfo(this.$route.query.id)
            }
        }
    },
    mounted() {
        if (this.$route.query != undefined) {
            this.getInfo(this.$route.query.id);
        }
        this.getHotList();
        this.getNews();
        this.androidScrollTop();
    },
    methods: {
        androidScrollTop() {//手机安卓端微信内置浏览器切换页面复位到页面顶部
            this.$store.commit('whichDeviceFun')
            if (!this.$store.state.mobileOrPC) {
                this.$refs.toTop.scrollIntoView()
            }
        },
        getInfo(id) {
            Server.getNewsInfo(id).then(res => {
                this.infoTitle = res.title;
                this.infoContent = res.content;
                this.infoDate = res.createDate;
                this.currentTag = res.tagsDesc;
            }).then(() => {
                this.getTag();
            });
        },
        getTag() {
            this.keywordData = [
                {
                    id: '00',
                    label: "全部",
                    active: false
                },
            ]
            Server.getTag().then(res => {
                let currentArr = res;
                for (let i = 0; i < currentArr.length; i++) {
                    this.keywordData.push({
                        id: currentArr[i].id,
                        label: currentArr[i].label,
                        active: false
                    });
                }
                for (let i in this.currentTag) {
                    this.activeTag(this.currentTag[i]);
                }
                // console.log('tag---', this.keywordData, this.currentTag);
            })
        },
        getHotList() {
            Server.getHotList().then(res => {
                let currentArr = res.records
                for (let i = 0; i < currentArr.length; i++) {
                    if (i <= 2) {
                        this.hotList.push({
                            id: currentArr[i].id,
                            title: currentArr[i].title,
                            red: true
                        })
                    } else {
                        this.hotList.push({
                            id: currentArr[i].id,
                            title: currentArr[i].title,
                            red: false
                        })
                    }
                }
            })
        },
        toHot(id) {
            this.$router.push({
                path: "/informationDetail",
                query: {
                    id: id
                }
            })
        },
        activeTag(name) {
            for (const key in this.keywordData) {
                if (Object.hasOwnProperty.call(this.keywordData, key)) {
                    const element = this.keywordData[key];
                    if (element.label === name) {
                        element.active = true;
                    }
                }
            }
        },
        switchTag(item) {
            for (const key in this.keywordData) {
                if (Object.hasOwnProperty.call(this.keywordData, key)) {
                    const element = this.keywordData[key];
                    if (element.id === item.id) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                }
            }
            this.$router.push({
                path: "/informationCenter",
                query: {
                    tagIds: item.id,
                    tagLabel: item.label
                }
            })
        },
        getNews(params) {
            Server.getNews(params).then(res => {
                console.log(res.records);
                const self = this;
                this.dataGroup = res.records;
                const filterData = res.records.filter(function (v, i) {
                    if (v.id == self.$route.query.id) {
                        self.index = i;
                        return v
                    }
                });
                this.currentData = filterData[0]
                // console.log('prevNext!!!', this.index, this.dataGroup);
            });
        },
        prev() {
            if (this.index > 0) {
                this.index -= 1;
                this.currentData = this.dataGroup[this.index]
                this.$router.push({
                    path: "/informationDetail",
                    query: {
                        id: this.currentData.id
                    }
                })
            } else {
                this.$bus.$emit('state', true);
                this.$bus.$emit('msg', '已经是第一条');
                // alert('已经是第一条')
            }
        },
        next() {
            if (this.index < this.dataGroup.length - 1) {
                this.index += 1;
                this.currentData = this.dataGroup[this.index];
                this.$router.push({
                    path: "/informationDetail",
                    query: {
                        id: this.currentData.id
                    }
                })
            } else {
                this.$bus.$emit('state', true);
                this.$bus.$emit('msg', '已经是最后一条');
                // alert('已经是最后一条')
            }
        },
    }
}
</script>

<style lang="scss">
.infoListBox {

    .mainList {

        .infoListTop {
            font-size: 16px;
            color: #999;
            padding: 30px 0;
            padding-left: 140px;
            border-bottom: 1px solid #313131;
        }

        .infoDetail {
            background-color: #f9f9f9;
            margin: 0 118px;
            padding: 40px 75px;

            .infoDetail_title {
                width: 100%;
                font-size: 24px;
                justify-content: center;
            }

            .infoDetail_date {
                width: 100%;
                font-size: 16px;
                color: #999;
                justify-content: center;
            }

            .infoDetail_text {
                width: 100%;
                min-height: 830px;
                color: #313131;
                line-height: 38px;
                white-space: normal;
                overflow: hidden;

                p,
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    width: 100%;

                    img {
                        max-width: 100%;
                    }
                }

                .ql-align-center {
                    text-align: center;
                    // display: flex;
                    // justify-content: center;
                }

                .ql-align-right {
                    text-align: right;
                    // display: flex;
                    // justify-content: end;
                }
            }
        }
    }

    .rightBlock {
        border-left: 1px solid #313131;
        border-bottom: 1px solid #313131;
        padding: 60px 50px;
        background-color: #fff;

        .keyword_title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 30px;
        }

        .hotList {
            width: 100%;

            ul {
                width: 100%;

                li {
                    font-size: 16px;
                    padding: 12px 0;
                    cursor: pointer;

                    .hotTitle {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .redIcon {
                    color: red;
                }
            }
        }

        .keyword_label {
            .keywordBlock {
                display: flex;
                height: 40px;
                font-size: 16px;
                line-height: 40px;
                margin: 12px;
                justify-content: center;
                padding: 0 20px;
                cursor: pointer;
                background-color: #fff;
                border: 1px solid #d8d8d8;
                color: #313131;
            }

            .keywordBlock:hover {
                border: 1px solid #313131;
            }

            .keywordActive {
                background-color: #313131;
                border: 1px solid #313131;
                color: #fff;
            }
        }
    }

    .pageNum {
        height: 102px;
        line-height: 102px;
        border-top: 1px solid #313131;
        text-align: center;
        font-size: 20px;
        margin-top: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .backList {
            border-left: 1px solid #313131;
            border-right: 1px solid #313131;
        }

        .pageBtn {
            cursor: pointer;
        }
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
    }

    .infoListBox {
        // margin-top: 1.35rem;

        .bannarBlock {
            height: 3rem;
            position: relative;
            border-bottom: 1px solid #313131;

            .bannerFontBox {
                padding: 0.75rem;

                .font1 {
                    font-family: Alimama_ShuHeiTi_Bold;
                    font-size: 0.4rem;
                    font-weight: bold;
                    color: #313131;
                }

                .font2 {
                    font-family: Alimama_ShuHeiTi_Bold;
                    font-size: 0.6rem;
                    font-weight: bold;
                    color: #313131;
                }
            }
        }

        .mainList {
            .infoListTop {
                font-size: 0.3rem;
                padding: 0.3rem;
            }

            .infoDetail {
                .infoDetail_title {
                    font-size: 0.5rem;
                    margin-top: 1rem;
                }
                .infoDetail_date{
                    font-size: 0.35rem;
                    margin-bottom: 1rem;
                }
                .infoDetail_text{
                    line-height: 0.75rem;
                }
            }
        }
    }
}
</style>