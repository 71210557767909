const common = {
	namespaced:true,
	state:{
		name: 'test'
	},
	mutations:{
		
	},
	actions: {
    
	}
}

export default common