<template>
    <div class="fixedMenu pc_style">
        <div class="itemBlock" @mouseenter="telephoneIn" @mouseleave="telephoneOut">
            <el-row class="itemBlock_img">
                <img src="@/assets/image/telephone_icon.png" alt="">
            </el-row>
            <el-row class="itemBlock_desc">电话咨询</el-row>
            <div class="dialogBox phoneBox" v-show="showTelephone">
                <div class="dialogBox_arrow"></div>
                <el-row class="dialogBox_title">咨询热线</el-row>
                <el-row class="dialogBox_desc">400-996-9002</el-row>
            </div>
        </div>
        <div class="itemBlock" @mouseenter="ERcodeIn" @mouseleave="ERcodeOut">
            <el-row class="itemBlock_img">
                <img src="@/assets/image/dialog_icon.png" alt="">
            </el-row>
            <el-row class="itemBlock_desc">技术咨询</el-row>
            <div class="dialogBox ERcodeBox" v-show="showERcode">
                <div class="dialogBox_arrow"></div>
                <img src="@/assets/image/ERcode.png" alt="">
                <div class="dialogBox_title">扫码关注公众号</div>
            </div>
        </div>
        <div class="itemBlock" @click="toaffim">
            <el-row class="itemBlock_img">
                <img src="@/assets/image/customerService_icon.png" alt="">
            </el-row>
            <el-row class="itemBlock_desc">在线客服</el-row>
        </div>
        <div class="itemBlock">
            <el-row id="backTopBar">
                <img src="../assets/image/backTopBar.png" alt="" @click="scrollToTop">
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showERcode: false,
            showTelephone: false
        }
    },
    methods: {
        scrollToTop() {
            document.documentElement.scrollIntoView({
                behavior: "smooth"
            })
        },
        telephoneIn() {
            this.showTelephone = true
        },
        telephoneOut() {
            this.showTelephone = false
        },
        ERcodeIn() {
            this.showERcode = true
        },
        ERcodeOut() {
            this.showERcode = false
        },
        toaffim(){
            window.open('https://affim.baidu.com/unique_31080836/chat?siteId=15684994&userId=31080836&siteToken=7232993a211238c79b944e7f1bdca12a')
        }
    }
}
</script>
<style lang="scss" scoped>
.fixedMenu {
    width: 80px;
    height: 328px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 999;

    .itemBlock {
        width: 100%;
        height: 82px;
        padding: 10px 0;
        cursor: pointer;
        position: relative;

        .itemBlock_desc {
            width: 100%;
            justify-content: center;
            font-family: MicrosoftYaHei;
            color: #999;
            text-align: center;
        }

        .itemBlock_img {
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                width: 40px;
                height: 40px;
            }
        }

        #backTopBar {
            height: 100%;
            justify-content: center;
            align-items: center;
        }

        .dialogBox {
            width: 180px;
            background-color: #FFFFFF;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            padding: 10px;

            .dialogBox_arrow {
                width: 20px;
                height: 20px;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-left: 20px solid #fff;
                border-right: 20px solid transparent;
                position: absolute;
                top: 50%;
                right: -20%;
                transform: translateY(-50%);
            }

            .dialogBox_title {
                color: #999;
            }

            .dialogBox_desc {
                font-family: AlimamaShuHeiTi_Bold;
                font-size: 22px;
                font-weight: bolder;
            }
        }

        .phoneBox {
            height: 80px;
            position: absolute;
            top: 0;
            left: -260%;
        }

        .ERcodeBox {
            height: 180px;
            position: absolute;
            top: 50%;
            left: -260%;
            transform: translateY(-50%);
            text-align: center;

            img {
                width: 128px;
                height: 128px;
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
  .pc_style {
    display: none;
  }
}
</style>