<template>
  <div ref="toTop">
    <div class="mobile_style" style="height:1.35rem"></div>
    <!-- ⬆ 移动端填补顶栏空间的标签不能删除 -->
    <div class="bannarBlock pc_style">
      <water-ripple :src="bannerUrl"></water-ripple>
      <div id="bannerArrowBox">
        <div id="bannerArrowInsideBox">
          <img src="../assets/image/bannerArrow.png" alt="">
          <img src="../assets/image/bannerArrow.png" alt="">
          <img src="../assets/image/bannerArrow.png" alt="">
        </div>
      </div>
    </div>
    <div class="bannarBlock mobile_style">
      <div>定制化软件开发<br>智能化整体解决方案优质供应商</div>
      <div>YUN ZHI TECHNOLOGY</div>
    </div>
    <div class="titleBlock">
      产品中心
      <span class="subtitle">服务、营销、管理、数据<br>致力于构建数据运营</span>
    </div>
    <ProductCenterHomePage></ProductCenterHomePage>
    <div class="titleBlock">
      解决方案
      <span class="subtitle" style="text-align:right;">深耕细分行业<br>提供多领域数字化创意方案</span>
    </div>
    <SolutionHomePage></SolutionHomePage>
    <SloganLine class="pc_style"></SloganLine>
    <div class="titleBlock">
      <span class="pc_style">客户美誉</span><span class="mobile_style">经典案例</span>
      <div class="moreBtn" @click="routerLink('/typicalCase')">更多案例</div>
    </div>
    <div class="typicalCase pc_style">
      <div class="TC_outSideBlock">
        <div class="TC_hoverStyle" v-for="(item, index) in typicalCaseList" :key="index" :id="'hoverAnimation' + item.id"
          @mouseenter="hoverStyleIn(item.id)" @mouseleave="hoverStyleOut(item.id)">
          <div class="TC_inSideBlock">
            <el-row>
              <img class="likeImg" :src="item.imageUrl" alt="">
            </el-row>
            <el-row class="TCI_detail"><span>{{ item.iconfont }}</span>{{ item.name }}</el-row>
          </div>
          <div class="TC_hiddenBlock">
            <div class="TC_hiddenBlock_title">{{ item.name }}</div>
            <div class="TC_hiddenBlock_text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ms_typicalCase mobile_style">
      <div class="ms_tc_outBox" v-for="(item, index) in typicalCaseList" :key="index">
        <div class="ms_tc_leftBox">
          <el-row class="ms_tc_borderBox">
            <img class="ms_tc_Img" :src="item.imageUrl" alt="">
          </el-row>
        </div>
        <div class="ms_tc_rightBox">
          <div class="ms_tc_title">{{ item.name }}</div>
          <!-- <div class="ms_tc_text">{{ item.text }}</div> -->
        </div>
      </div>
    </div>
    <SloganLine></SloganLine>
    <div class="titleBlock pc_style" id="typicalCase_titleBlock">
      <el-row>
        <el-col :span="18" class="TB_typicalCase">
          <el-row class="TBT_inside">
            <el-col :span="6">
              <el-row class="TBT_title">
                <ICountUp :delay="delay" :endVal="projectCover" :options="options" :key="resetCountUp" />+
              </el-row>
              <el-row class="TBT_detail">项目覆盖省</el-row>
            </el-col>
            <!-- <el-col :span="6">
            <el-row class="TBT_title">
              <ICountUp :delay="delay" :endVal="patent" :options="options" :key="resetCountUp" />+
            </el-row>
            <el-row class="TBT_detail">企业荣誉认证</el-row>
          </el-col> -->
            <el-col :span="6">
              <el-row class="TBT_title">
                <ICountUp :delay="delay" :endVal="userSelection" :options="options" :key="resetCountUp" />+
              </el-row>
              <el-row class="TBT_detail">用户信赖选择</el-row>
            </el-col>
            <el-col :span="6">
              <el-row class="TBT_title">
                <ICountUp :delay="delay" :endVal="totalUsers" :options="options" :key="resetCountUp" />+
              </el-row>
              <el-row class="TBT_detail">单平台用户累计</el-row>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" style="text-align: right;">认证实力</el-col>
      </el-row>
    </div>
    <div class="titleBlock mobile_style sp_heightBox">
      <div class="ms_title_center">认证实力</div>
      <el-row>
        <el-col class="TB_typicalCase">
          <el-row class="TBT_inside">
            <el-col :span="8">
              <el-row class="TBT_title">
                <ICountUp :delay="delay" :endVal="projectCover" :options="options" :key="resetCountUp" />+
              </el-row>
              <el-row class="TBT_detail">项目覆盖省</el-row>
            </el-col>
            <el-col :span="8">
              <el-row class="TBT_title">
                <ICountUp :delay="delay" :endVal="userSelection" :options="options" :key="resetCountUp" />+
              </el-row>
              <el-row class="TBT_detail">用户信赖选择</el-row>
            </el-col>
            <el-col :span="8">
              <el-row class="TBT_title">
                <ICountUp :delay="delay" :endVal="totalUsers" :options="options" :key="resetCountUp" />+
              </el-row>
              <el-row class="TBT_detail">单平台用户累计</el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="partnerBlock">
      <PartnerHomePage></PartnerHomePage>
    </div>
    <div class="titleBlock">
      资讯中心
      <div class="moreBtn" @click="routerLink('/informationCenter')">更多资讯</div>
    </div>
    <div class="NewsBlock pc_style">
      <el-row class="NewsBlock_inside">
        <el-col :span="8" class="NewsBlock_imgBox">
          <div class="NewsBlock_imgBlock" :style="{
            background: `url(${currentImage}) no-repeat`,
            backgroundSize: 'contain',
            backgroundPosition: 'center'
          }">
          </div>
        </el-col>
        <el-col :span="16" class="NewsBlock_list">
          <div class="NewsBlock_list_block" :class="['NewsBlock_list_block', currentIndex == index ? 'NLB_active' : ' ']"
            v-for="(item, index) in NewsList" :key="index" @click="toNewsDetail(item.id)"
            @mouseenter="NewsActive(index, item.cover, 'enterKey' + currentIndex)"
            @mouseleave="NewsOut('enterKey' + currentIndex)" :id="'enterKey' + index">
            <span class="NLB_EnterKeyBox">
              <div class="enter-key">
                <div class="stick"></div>
                <div class="arrow"></div>
              </div>
            </span>
            <span class="NLB_title">{{ item.title }}</span>
            <span class="NLB_taps">{{ item.categoryDesc }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="ms_NewsBlock mobile_style">
      <div class="ms_NewsBlock_list">
        <div class="ms_NewsBlock_list_block" v-for="(item, index) in mbNewsList" :key="index"
          @click="toNewsDetailMobile(item.id)">
          <span class="ms_NLB_title">{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div class="submitBox pc_style">
      <div class="submitBox_inside">
        <el-row>
          <el-col :span="5" class="submitBox_inside_title">获取最新方案与报价</el-col>
          <el-col :span="2">最新案例</el-col>
          <el-col :span="2">产品细节</el-col>
          <el-col :span="3">定制化解决方案</el-col>
          <el-col :span="12" class="submitBox_inside_input">
            <input v-model="userMobile" placeholder="请输入手机号码，以便获取最新方案" maxlength="11" />
            <div class="submitBtn" @click="submitUserMobile()">提交</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="submitBox mobile_style">
      <div class="submitBox_inside">
        <el-row>
          <el-col class="submitBox_inside_title">获取最新方案与报价</el-col>
        </el-row>
        <el-row class="submitBox_inside_title2">
          <el-col :span="8">最新案例</el-col>
          <el-col :span="8">产品细节</el-col>
          <el-col :span="8">定制化解决方案</el-col>
        </el-row>
        <el-row>
          <el-col class="submitBox_inside_input">
            <input v-model="userMobile" placeholder="请输入手机号码，以便获取最新方案" maxlength="11" />
            <div class="submitBtn" @click="submitUserMobile()">提交</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="finalBox pc_style">
      <el-row class="finalBox_inside">
        <el-col :span="6" class="finalBlock">
          <el-row class="finalBlock_title">
            <img src="../assets/image/service_icon.png" alt="">服务模式
          </el-row>
          <el-row class="finalBlock_detail">主打“产品+服务+生态”全规划</el-row>
        </el-col>
        <el-col :span="6" class="finalBlock finalBlock_border1">
          <el-row class="finalBlock_title">
            <img src="../assets/image/train_icon.png" alt="">培训机制
          </el-row>
          <el-row class="finalBlock_detail">根据使用角色及使用阶段驻场专项式培训</el-row>
        </el-col>
        <el-col :span="6" class="finalBlock finalBlock_border2">
          <el-row class="finalBlock_title">
            <img src="../assets/image/AfterSales_icon.png" alt="">售后保障
          </el-row>
          <el-row class="finalBlock_detail">7天*12小时专业售后</el-row>
        </el-col>
        <el-col :span="6" class="finalBlock">
          <el-row class="finalBlock_title">
            <img src="../assets/image/maintenance_icon.png" alt=""> 运维尊享
          </el-row>
          <el-row class="finalBlock_detail">定期软（硬）件巡检服务，确保正常运行</el-row>
        </el-col>
      </el-row>
    </div>
    <div class="finalBox mobile_style">
      <el-row class="finalBox_inside">
        <el-col :span="12" class="finalBlock">
          <el-row class="finalBlock_title">
            <img src="../assets/image/service_icon.png" alt="">服务模式
          </el-row>
          <el-row class="finalBlock_detail">主打“产品+服务+生态”全规划</el-row>
        </el-col>
        <el-col :span="12" class="finalBlock">
          <el-row class="finalBlock_title">
            <img src="../assets/image/train_icon.png" alt="">培训机制
          </el-row>
          <el-row class="finalBlock_detail">根据使用角色及使用阶段驻场专项式培训</el-row>
        </el-col>
      </el-row>
      <el-row class="finalBox_inside">
        <el-col :span="12" class="finalBlock">
          <el-row class="finalBlock_title">
            <img src="../assets/image/AfterSales_icon.png" alt="">售后保障
          </el-row>
          <el-row class="finalBlock_detail">7天*12小时专业售后</el-row>
        </el-col>
        <el-col :span="12" class="finalBlock">
          <el-row class="finalBlock_title">
            <img src="../assets/image/maintenance_icon.png" alt=""> 运维尊享
          </el-row>
          <el-row class="finalBlock_detail">定期软（硬）件巡检服务，确保正常运行</el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import SloganLine from '../components/SloganLine.vue';
import ICountUp from 'vue-countup-v2/src/countup.vue';
import WaterRipple from '../components/WaterRipple.vue';
import ProductCenterHomePage from '../components/ProductCenterHomePage.vue';
import SolutionHomePage from '../components/SolutionHomePage.vue';
import PartnerHomePage from '../components/PartnerHomePage.vue';
import { Server } from "@/utils/server";

export default {
  name: 'Home',
  components: {
    SloganLine,
    WaterRipple,
    ICountUp,
    ProductCenterHomePage,
    SolutionHomePage,
    PartnerHomePage
  },
  data() {
    return {
      bannerUrl: require('@/assets/image/banner.png'),
      projectCover: 16,
      patent: 120,
      userSelection: 600,
      totalUsers: 10000,
      //---countUp插件的参数---
      delay: 0,
      instance: '',
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      //---end---
      scrollTop: 0,
      resetCountUp: 0,
      currentIndex: 0,
      currentImage: '',
      NewsList: [],
      mbNewsList: [],
      typicalCaseList: [
        {
          id: 1,
          name: "印力上海",
          iconfont: "智慧商场",
          text: "通过平台，进一步拓展数字化营销在商业地产的分发渠道。 为顾客营造一个服务优质、内容丰富、互动有趣的商业生态。",
          imageUrl: require('@/assets/image/YinXiang.png')
        },
        {
          id: 2,
          name: "瑞安新天地",
          iconfont: "智慧商场",
          text: "瑞安友联平台有效的节省了部分运维成本，线上的便捷灵活性和数据的应用反哺，让我们业务流程更有弹性。",
          imageUrl: require('@/assets/image/RuiAn.png')
        },
        {
          id: 3,
          name: "上海某区政府",
          iconfont: "智慧政府",
          text: "一网统管强化市区协同和部门统筹，不断推动资源共享和处置联动，为探索超大城市治理创新发挥了十分重要的作用。",
          imageUrl: require('@/assets/image/gov1.png')
        },
        {
          id: 4,
          name: "上海某区政府",
          iconfont: "智慧政府",
          text: "走进城运中心的一刹那深受震撼，把科技化的治理理念融入企业管理过程中，大力提升企业数字化治理效能。",
          imageUrl: require('@/assets/image/gov2.png')
        },
      ],
      userMobile: ""
    }
  },
  mounted() {
    window.addEventListener('scroll', this.changeCountUp);
    this.getNews();
    this.androidScrollTop();
  },
  unmounted() {
    window.removeEventListener('scroll', this.changeCountUp);
  },
  methods: {
    androidScrollTop() {//手机安卓端微信内置浏览器切换页面复位到页面顶部
      this.$store.commit('whichDeviceFun')
      if (!this.$store.state.mobileOrPC) {
        this.$refs.toTop.scrollIntoView()
      }
    },
    isInViewPort(element) {//判断目标元素是否在视图范围内
      const viewWidth = window.innerWidth || document.documentElement.clientWidth;
      const viewHeight = window.innerHeight || document.documentElement.clientHeight;
      const {
        top,
        right,
        bottom,
        left,
      } = element.getBoundingClientRect();
      return (
        top >= 0 &&
        left >= 0 &&
        right <= viewWidth &&
        bottom <= viewHeight
      );
    },
    changeCountUp() {
      let watchBox = document.getElementById('typicalCase_titleBlock');
      let isInView = this.isInViewPort(watchBox);
      if (isInView) {
        if (this.resetCountUp == 0) {
          this.resetCountUp++;
        }
      }
    },
    NewsActive(num, url, currentId) {
      this.currentIndex = num;
      this.currentImage = url;
      let currentElement = document.getElementById(currentId);
      currentElement.querySelector(".stick").className = "stick stick-show";
      currentElement.querySelector(".arrow").className = "arrow arrow-show";
      // console.log(currentId)
    },
    NewsOut(currentId) {
      let currentElement = document.getElementById(currentId);
      currentElement.querySelector(".stick").className = "stick stick-hide";
      currentElement.querySelector(".arrow").className = "arrow arrow-hide";
    },
    hoverStyleIn(num) {
      let block1 = document.getElementById('hoverAnimation1'),
        block2 = document.getElementById('hoverAnimation2'),
        block3 = document.getElementById('hoverAnimation3'),
        block4 = document.getElementById('hoverAnimation4');
      switch (num) {
        case 1:
          block1.animate(
            [{
              width: '100%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          break;
        case 2:
          block1.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '100%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          break;
        case 3:
          block1.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '100%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          break;
        case 4:
          block1.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '0%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '100%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          break;
      }
    },
    hoverStyleOut(num) {
      let block1 = document.getElementById('hoverAnimation1'),
        block2 = document.getElementById('hoverAnimation2'),
        block3 = document.getElementById('hoverAnimation3'),
        block4 = document.getElementById('hoverAnimation4');
      switch (num) {
        case 1:
          block1.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          break;
        case 2:
          block1.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          break;
        case 3:
          block1.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          break;
        case 4:
          block1.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block2.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block3.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          block4.animate(
            [{
              width: '25%'
            }],
            {
              duration: 500,
              easing: 'linear',
              delay: 0,
              iterations: 1,
              direction: 'normal',
              fill: 'forwards',
            }
          )
          break;
      }
    },
    getNews() {
      Server.getNews().then(res => {
        console.log(res);
        this.NewsList = res.records;
        if (this.NewsList.length != 0) {
          this.currentImage = this.NewsList[0].cover
          for (let i = 0; i < this.NewsList.length; i++) {
            if (i < 5) {
              this.mbNewsList.push(this.NewsList[i])
            }
          }
          console.log('why!!!', this.NewsList, this.mbNewsList)
        }
      });
    },
    submitUserMobile() {
      let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (this.userMobile != "" && reg.test(this.userMobile)) {
        let params = {
          mobile: this.userMobile
        }
        Server.submitUserMobile(params).then(res => {
          // alert(res);
          this.$bus.$emit('state', true);
          this.$bus.$emit('msg', res);
          this.userMobile = ""
        })
      } else {
        this.$bus.$emit('state', true);
        this.$bus.$emit('msg', "请输入正确的手机号码！");
        // alert("请输入正确的手机号码！");
      }
    },
    toNewsDetail(id) {
      window.open("/informationDetail?id=" + id);
    },
    routerLink(url) {
      this.$router.push({
        path: url
      })
    },
    toNewsDetailMobile(id) {
      this.$router.push({
        path: "/informationDetail",
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes bannerArrowMove {
  from {
    transform: translateY(-50%);
  }

  to {
    transform: translateY(5%);
  }
}

@keyframes showStick {
  0% {
    opacity: 0;
    height: 0px;
    width: 0px;
  }

  5% {
    height: 0px;
    width: 0px;
    opacity: 1;
  }

  50% {
    height: 20px;
    width: 0px;
  }

  100% {
    height: 20px;
    width: 40px;
    opacity: 1;
  }
}

@keyframes showArray {
  0% {
    opacity: 0;
    height: 0px;
    width: 0px;
  }

  5% {
    opacity: 1;
  }

  100% {
    height: 20px;
    width: 20px;
    opacity: 1;
  }
}

@keyframes stickHide {
  0% {
    width: 40px;
    height: 20px;
    opacity: 1;
  }

  50% {
    height: 10px;
    width: 0px;
    opacity: 1;
  }

  100% {
    height: 0px;
    width: 0px;
    opacity: 0;
  }
}

@keyframes arrowHide {
  0% {
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  100% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
}

.bannarBlock {
  height: 680px;
  border: 0;
  position: relative;

  #bannerArrowBox {
    width: 98px;
    height: 98px;
    overflow: hidden;
    position: absolute;
    right: 160px;
    bottom: 40px;

    #bannerArrowInsideBox {
      width: 98px;
      height: 98px;
      position: absolute;
      bottom: 0;
      animation: bannerArrowMove 2s infinite;

      img {
        width: 98px;
        height: 48px;
        display: flex;
      }
    }
  }
}

.titleBlock {
  height: 240px;
  line-height: 240px;
  padding: 0 200px;
  font-size: 68px;
  font-weight: bold;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  font-family: Alimama_ShuHeiTi_Bold;
  position: relative;

  .TB_typicalCase {
    height: 280px;
    line-height: 64px;
    justify-content: center;

    .TBT_inside {
      height: 280px;
      padding: 80px 0;

      .TBT_title {
        font-size: 64px;
      }

      .TBT_detail {
        font-size: 24px;
        font-weight: normal;
      }
    }
  }

  .subtitle {
    font-size: 24px;
    line-height: 40px;
    position: absolute;
    top: 40%;
    right: 200px;
  }

  .moreBtn {
    width: 260px;
    height: 60px;
    font-size: 22px;
    text-align: center;
    line-height: 60px;
    border-radius: 62.5px;
    border: 1px solid #D8D8D8;
    position: absolute;
    top: 50%;
    right: 200px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .moreBtn:hover {
    color: #fff;
    background-color: #313131;
  }
}

.typicalCase {
  height: 490px;
  margin: 0 146px;
  padding: 30px 0;

  .TC_outSideBlock {
    height: 386px;
    overflow: hidden;

    .TC_hoverStyle {
      width: 400px;
      height: 386px;
      overflow: hidden;
      float: left;

      .TC_inSideBlock {
        width: 360px;
        height: 346px;
        border: 1px solid #313131;
        padding: 30px;
        margin: 20px;
        font-size: 16px;
        font-weight: bold;
        float: left;

        .TCI_detail {
          margin: 30px 0;

          span {
            border: 1px solid #313131;
            font-size: 16px;
            font-weight: normal;
            margin-right: 10px;
            padding: 0 5px;
          }
        }

        .likeImg {
          width: 300px;
          height: 225px;
          border: 1px solid #f2f2f2;
        }
      }
    }

    .TC_hoverStyle:hover {
      background: linear-gradient(to left, #313131, #313131) left top no-repeat,
        linear-gradient(to bottom, #313131, #313131) left top no-repeat,
        linear-gradient(to left, #313131, #313131) right top no-repeat,
        linear-gradient(to bottom, #313131, #313131) right top no-repeat,
        linear-gradient(to left, #313131, #313131) left bottom no-repeat,
        linear-gradient(to bottom, #313131, #313131) left bottom no-repeat,
        linear-gradient(to left, #313131, #313131) right bottom no-repeat,
        linear-gradient(to left, #313131, #313131) right bottom no-repeat;
      background-size: 10px 49px, 49px 10px, 10px 49px, 49px 10px;
    }
  }

  .TC_hiddenBlock {
    height: 346px;
    margin: 20px 0;
    margin-left: 450px;
    margin-right: 100px;
    border-top: 1px solid #313131;
    border-bottom: 1px solid #313131;
    padding: 80px;
    overflow: hidden;

    .TC_hiddenBlock_title {
      font-size: 40px;
      font-weight: bold;
    }

    .TC_hiddenBlock_text {
      font-size: 24px;
      padding: 20px 0;
    }
  }
}

.partnerBlock {
  height: 490px;
  padding: 30px 0;
  overflow: hidden;
}

.NewsBlock {
  height: 600px;

  .NewsBlock_inside {
    height: 600px;

    .NewsBlock_imgBox {
      width: 450px;
      height: 600px;

      .NewsBlock_imgBlock {
        width: 450px;
        height: 600px;
        margin-left: 140px;
        display: flex;
        align-items: center;
      }
    }

    .NewsBlock_list {
      height: 600px;
      overflow: hidden;

      .NewsBlock_list_block {
        border-left: 1px solid #313131;
        border-bottom: 1px solid #313131;
        height: 75px;
        color: #313131;
        display: flex;

        .NLB_EnterKeyBox {
          width: 135px;
          height: 75px;

          .enter-key {
            width: 135px;
            height: 74px;
            background-color: transparent;
            position: relative;
          }

          .arrow {
            width: 0px;
            height: 0px;
            opacity: 0;
            border-bottom: 5px solid #ffffff;
            border-left: 5px solid #ffffff;
            transform: rotate(-135deg);
            margin: auto;
            position: absolute;
            top: 0px;
            right: 48px;
            bottom: 0;
          }

          .stick {
            opacity: 0;
            width: 0px;
            height: 0px;
            border-bottom: 5px solid #ffffff;
            border-left: 5px solid #ffffff;
            border-bottom-left-radius: 4px;
            position: absolute;
            margin: auto;
            top: -15px;
            bottom: 0;
            left: 0;
            right: 0;
          }

          .stick-show {
            animation: showStick 0.2s linear forwards;
          }

          .stick-hide {
            width: 40px;
            height: 20px;
            opacity: 1;
            animation: stickHide 0.2s linear forwards;
            animation-delay: 0.2s;
          }

          .arrow-show {
            animation: showArray 0.2s linear forwards;
            animation-delay: 0.2s;
          }

          .arrow-hide {
            animation: arrowHide 0.2s linear forwards;
          }
        }

        .NLB_title {
          width: 70%;
          font-size: 20px;
          font-weight: bolder;
          line-height: 75px;
          padding: 0 10px;
        }

        .NLB_taps {
          height: 30px;
          border: 1px solid #313131;
          font-size: 16px;
          text-align: center;
          margin-top: 22.5px;
          line-height: 30px;
          padding: 0 5px;
        }
      }

      .NewsBlock_list_block:hover,
      .NLB_active {
        background-color: #313131;

        .NLB_title {
          color: #fff;
        }

        .NLB_taps {
          color: #fff;
          border: 1px solid #fff;
        }
      }
    }
  }
}

.submitBox {
  height: 300px;

  .submitBox_inside {
    margin: 0 200px;
    font-size: 20px;
    height: 270px;
    padding-top: 30px;
    line-height: 270px;

    .submitBox_inside_title {
      font-size: 30px;
    }

    .submitBox_inside_input {
      padding-top: 105px;

      input {
        width: 580px;
        height: 60px;
        border: 1px solid #D8D8D8;
        padding: 0 5px;
        border-radius: 6px 0px 0px 6px;
        float: left;
      }

      .submitBtn {
        width: 159px;
        height: 60px;
        background-color: #313131;
        color: #fff;
        font-size: 24px;
        line-height: 60px;
        text-align: center;
        border-radius: 0px 6px 6px 0px;
        float: left;
        cursor: pointer;
      }
    }

  }
}

.finalBox {
  height: 240px;
  border-top: 1px solid #313131;
  border-bottom: 1px solid #313131;
  margin-bottom: 75px;

  .finalBox_inside {
    height: 240px;
    margin: 0 200px;

    .finalBlock {
      padding-top: 90px;
      padding-left: 60px;

      .finalBlock_title {
        font-size: 20px;
        font-weight: bold;
        color: #313131;

        img {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
      }

      .finalBlock_detail {
        font-size: 16px;
        color: #666;
        padding-left: 40px;
        padding-right: 10px;
      }
    }

    .finalBlock_border1 {
      border-left: 1px solid #313131;
      border-right: 1px solid #313131;
    }

    .finalBlock_border2 {
      border-right: 1px solid #313131;
    }
  }
}

.mobile_style {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .pc_style {
    display: none;
  }

  .mobile_style {
    display: block;
  }

  .bannarBlock {
    // margin-top: 1.35rem;
    height: auto;
    background-image: url("../assets/image/banner_mobile.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1rem 0.5rem;
    font-size: 0.5rem;
    font-weight: bolder;
  }

  .titleBlock {
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 0.5rem;
    font-size: 0.5rem;
    font-weight: bold;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    font-family: Alimama_ShuHeiTi_Bold;
    position: relative;

    .subtitle {
      font-size: 0.3rem;
      line-height: 0.5rem;
      position: absolute;
      top: 50%;
      right: 0.75rem;
      transform: translateY(-50%);
      text-align: right;
    }

    .moreBtn {
      width: 2rem;
      height: 0.75rem;
      font-size: 0.3rem;
      text-align: center;
      line-height: 0.75rem;
      border-radius: 62.5px;
      border: 1px solid #D8D8D8;
      position: absolute;
      top: 50%;
      right: 200px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .ms_title_center {
      width: 100%;
      height: 1rem;
      text-align: center;
    }

    .TB_typicalCase {
      .TBT_inside {
        height: 2.5rem;

        .TBT_title {
          height: 0.75rem;
          font-size: 0.35rem;
          justify-content: center;
        }

        .TBT_detail {
          height: 0.75rem;
          font-size: 0.3rem;
          justify-content: center;
        }
      }
    }
  }

  .ms_typicalCase {
    width: 100%;
    height: auto;

    .ms_tc_outBox {
      display: flex;
      padding: 0.25rem 0.5rem;

      .ms_tc_leftBox {
        padding: 0.15rem 0;

        .ms_tc_borderBox {
          border: 1px solid #cbcbcb;
          padding: 0.25rem;
        }

        .ms_tc_Img {
          width: 2rem;
          height: 1.5rem;
        }
      }

      .ms_tc_rightBox {
        width: 100%;
        border-top: 1px solid #cbcbcb;
        border-bottom: 1px solid #cbcbcb;
        margin-left: 0.2rem;
        padding: 0.1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .ms_tc_title {
          font-weight: bolder;
          font-size: 0.45rem;
        }

        .ms_tc_text {
          text-indent: 2em;
        }
      }
    }
  }

  .sp_heightBox {
    height: auto;
    line-height: normal;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .partnerBlock {
    height: 6rem;
    padding: 0.1rem 0;
    overflow: hidden;
  }

  .ms_NewsBlock {
    padding-bottom: 1rem;

    .ms_NewsBlock_list {
      .ms_NewsBlock_list_block {
        border-bottom: 1px solid #313131;
        font-size: 0.3rem;
        padding: 0.3rem;
        font-weight: bolder;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }

  .submitBox {
    height: 4rem;

    .submitBox_inside {
      margin: 0 0.5rem;
      height: 4rem;
      padding-top: 30px;
      line-height: 0.5rem;

      .submitBox_inside_title {
        font-size: 0.35rem;
      }

      .submitBox_inside_title2 {
        font-size: 0.25rem;
      }

      .submitBox_inside_input {
        padding-top: 105px;

        input {
          width: 70%;
          height: 1rem;
          border: 1px solid #D8D8D8;
          padding: 0 0.25rem;
          border-radius: 0.1rem 0px 0px 0.1rem;
          float: left;
        }

        .submitBtn {
          width: 25%;
          height: 1rem;
          background-color: #313131;
          color: #fff;
          font-size: 0.3rem;
          line-height: 1rem;
          text-align: center;
          border-radius: 0px 0.1rem 0.1rem 0px;
          float: left;
          cursor: pointer;
        }
      }

    }
  }

  .finalBox {
    height: 6rem;
    border: 0;

    .finalBox_inside {
      height: 3rem;
      margin: 0 0.5rem;

      .finalBlock {
        padding-top: 1rem;
        padding-left: 0.5rem;
        border: 1px solid #313131;

        .finalBlock_title {
          font-size: 0.3rem;
          font-weight: bold;
          color: #313131;

          img {
            width: 0.5rem;
            height: 0.5rem;
            margin-right: 0.25rem;
          }
        }

        .finalBlock_detail {
          font-size: 0.25rem;
          color: #666;
          padding-left: 0.5rem;
          padding-right: 0.1rem;
        }
      }
    }
  }
}
</style>