<template>
    <div class="maskBox" v-show="visiable">
        <div class="contentBox">
            <div class="closeBox"><span @click="close">关闭</span></div>
            <el-row class="content">{{ content }}</el-row>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'messageBox',
    props: {
        msg: String,
        state: Boolean
    },
    data() {
        return {
            visiable: false,
            content: ""
        }
    },
    mounted() {
        this.$bus.$on("state", key => {
            this.visiable = key;
            // console.log(this.visiable);
        });
        this.$bus.$on("msg", key => {
            this.content = key;
            // console.log(this.visiable);
        });
    },
    methods: {
        close() {
            this.visiable = false;
        }
    }
};
</script>
  
<style lang="scss" scoped>
.maskBox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;

    .contentBox {
        min-width: 600px;
        min-height: 240px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 10px 20px;
        border-radius: 15px;
        font-family: MicrosoftYaHei;
        font-weight: bolder;

        .closeBox {
            display: flex;
            justify-content: end;

            span {
                cursor: pointer;
                padding: 5px 10px;
                font-size: 20px;
            }
        }

        .content {
            font-size: 28px;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .maskBox {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99999;

        .contentBox {
            min-width: 8rem;
            min-height: 5rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            padding: 0.1rem 0.2rem;
            border-radius: 0.15rem;
            font-family: MicrosoftYaHei;
            font-weight: bolder;

            .closeBox {
                display: flex;
                justify-content: end;

                span {
                    cursor: pointer;
                    padding: 0.1rem 0.15rem;
                    font-size: 0.3rem;
                }
            }

            .content {
                font-size: 0.35rem;
            }
        }
    }
}
</style>