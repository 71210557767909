<template>
    <div class="productCenterBlock pc_style">
        <div id="PCB_style1" @mouseenter="changeWeight(1)">
            <div class="PCB_BlockOpen" v-show="PCB1_onoffState" @click="routerLink('navigation')">
                <el-row class="PCB_BO_title">导视导览系统</el-row>
                <el-row>————</el-row>
                <el-row class="PCB_BO_desc">提供室内精准的定位导航服务平台，可跨楼层、街区</el-row>
                <el-row>
                    <div class="PCB_BO_intro">
                        <div>室内蓝牙定位导航</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>高精地图</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>厘米级实时定位</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>路线规划</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>软硬件一体</div>
                    </div>
                </el-row>
                <div class="PCB_BO_intro2">
                    <el-row>
                        <el-col :span="8">>全场导航</el-col><el-col :span="8">>智慧停车</el-col><el-col :span="8">>AR导航</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">>反向寻车</el-col><el-col :span="8">>正向寻位</el-col><el-col :span="8">>VR导航</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">>车位预约</el-col><el-col :span="8">>品牌索引</el-col><el-col :span="8">>商场服务</el-col>
                    </el-row>
                </div>
            </div>
            <el-row class="moreBtn" v-show="PCB1_onoffState" @click="routerLink('navigation')">
                <el-col :span="16" class="moreBtn_font">查看详情</el-col>
                <el-col :span="8">
                    <img src="../assets/image/arrow_right.png" alt="">
                </el-col>
            </el-row>
            <div class="PCB_BlockClose" v-show="!PCB1_onoffState">
                <el-row class="PCB_BC_title">导视导览系统</el-row>
                <el-row>————</el-row>
                <el-row>提供室内精准的定位导航服务平台，可跨楼层、街区</el-row>
            </div>
        </div>
        <div id="PCB_style2" @mouseenter="changeWeight(2)">
            <div class="PCB_BlockOpen" v-show="PCB2_onoffState" @click="routerLink('memberMarking')">
                <el-row class="PCB_BO_title">会员营销系统</el-row>
                <el-row>————</el-row>
                <el-row class="PCB_BO_desc">为商场搭建会员运营平台，提供全渠道的会员数据</el-row>
                <el-row>
                    <div class="PCB_BO_intro">
                        <div>会员等级+权益+积分管理</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>定制化营销方案</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>私域流量价值</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>可视化会员数据</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>会员画像分析</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>会员全生命周期管理</div>
                    </div>
                </el-row>
                <div class="PCB_BO_intro2">
                    <el-row>
                        <el-col :span="6">>会员码</el-col>
                        <el-col :span="6">>无感积分</el-col>
                        <el-col :span="6">>活动预约</el-col>
                        <el-col :span="6">>会员信息</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">>拍照积分</el-col>
                        <el-col :span="6">>专题推文</el-col>
                        <el-col :span="6">>商场服务</el-col>
                        <el-col :span="6">>扫码积分</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">>热区打卡</el-col>
                        <el-col :span="6">>服务优享</el-col>
                        <el-col :span="6">>活动预约</el-col>
                        <el-col :span="6">>运动无限</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">>智能客服</el-col>
                    </el-row>
                </div>
            </div>
            <el-row class="moreBtn" v-show="PCB2_onoffState" @click="routerLink('memberMarking')">
                <el-col :span="16" class="moreBtn_font">查看详情</el-col>
                <el-col :span="8">
                    <img src="../assets/image/arrow_right_white.png" alt="">
                </el-col>
            </el-row>
            <div class="PCB_BlockClose" v-show="!PCB2_onoffState">
                <el-row class="PCB_BC_title">会员营销系统</el-row>
                <el-row>————</el-row>
                <el-row>为商场搭建会员运营平台，提供全渠道的会员数据</el-row>
            </div>
        </div>
        <div id="PCB_style3" @mouseenter="changeWeight(3)">
            <div class="PCB_BlockOpen" v-show="PCB3_onoffState" @click="routerLink('merchantServices')">
                <el-row class="PCB_BO_title">商户服务系统</el-row>
                <el-row>————</el-row>
                <el-row class="PCB_BO_desc">提供便捷的线上商户运营服务，优化商场资源管理配置</el-row>
                <el-row>
                    <div class="PCB_BO_intro">
                        <div>0跑动线上业务流转</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>可视化数据报表平台</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>一网统管商户及各职能部门</div>
                    </div>
                    <div class="PCB_BO_intro">
                        <div>可叠加应用,覆盖“商场&商户“业务全场景</div>
                    </div>
                </el-row>
                <div class="PCB_BO_intro2">
                    <el-row>
                        <el-col :span="6">>营业汇报</el-col>
                        <el-col :span="6">>日常报修</el-col>
                        <el-col :span="6">>活动通知</el-col>
                        <el-col :span="6">>消息管理</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">>物品放行</el-col>
                        <el-col :span="6">>线上活动</el-col>
                        <el-col :span="6">>通知管理</el-col>
                        <el-col :span="6">>文件管理</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">>加班申请</el-col>
                        <el-col :span="6">>场地活动</el-col>
                        <el-col :span="6">>会议管理</el-col>
                        <el-col :span="6">>数据报表</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">>一户一档</el-col>
                        <el-col :span="6">>品牌推广</el-col>
                        <el-col :span="6">>投诉建议</el-col>
                        <el-col :span="6">>公区维护</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">>三关一闭</el-col>
                        <el-col :span="6">>惩处管理</el-col>
                        <el-col :span="6">>店务管理</el-col>
                        <el-col :span="6">>集团管理</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">>广告投放</el-col>
                        <el-col :span="6">>卡券核销</el-col>
                        <el-col :span="6">>工作移交</el-col>
                        <el-col :span="6">>电子员工证</el-col>
                    </el-row>
                </div>
            </div>
            <el-row class="moreBtn" v-show="PCB3_onoffState" @click="routerLink('merchantServices')">
                <el-col :span="16" class="moreBtn_font">查看详情</el-col>
                <el-col :span="8">
                    <img src="../assets/image/arrow_right.png" alt="">
                </el-col>
            </el-row>
            <div class="PCB_BlockClose" v-show="!PCB3_onoffState">
                <el-row class="PCB_BC_title">商户服务系统</el-row>
                <el-row>————</el-row>
                <el-row>提供便捷的线上商户运营服务，优化商场资源管理配置</el-row>
            </div>
        </div>
    </div>
    <div class="mobile_style">
        <div class="mb_mainBox" @click="routerLink('navigation')">
            <div class="mb_title">导视导览系统</div>
            <hr />
            <div class="mb_detail">线下转线上运营，提升商场整体运营及服务能力，赋能租户促进业务增长</div>
            <div class="mb_tag1Box">
                <div>室内蓝牙定位导航</div>
                <div>高精地图</div>
                <div>厘米级实时定位</div>
                <div>路线规划</div>
                <div>软硬件一体</div>
            </div>
            <div class="mb_tag2Box">
                <el-row>
                    <el-col :span="8">>全场导航</el-col><el-col :span="8">>智慧停车</el-col><el-col :span="8">>AR导航</el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">>反向寻车</el-col><el-col :span="8">>正向寻位</el-col><el-col :span="8">>VR导航</el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">>车位预约</el-col><el-col :span="8">>品牌索引</el-col><el-col :span="8">>商场服务</el-col>
                </el-row>
            </div>
        </div>
        <div class="mb_mainBox" @click="routerLink('memberMarking')">
            <div class="mb_title">会员营销系统</div>
            <hr />
            <div class="mb_detail">为商场搭建会员运营平台，提供全渠道的会员数据</div>
            <div class="mb_tag1Box">
                <div>会员等级+权益+积分管理</div>
                <div>定制化营销方案</div>
                <div>私域流量价值</div>
                <div>可视化会员数据</div>
                <div>会员画像分析</div>
                <div>会员全生命周期管理</div>
            </div>
            <div class="mb_tag2Box">
                <el-row>
                    <el-col :span="6">>会员码</el-col><el-col :span="6">>无感积分</el-col><el-col :span="6">>活动预约</el-col><el-col :span="6">>会员信息</el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">>拍照积分</el-col><el-col :span="6">>专题推文</el-col><el-col :span="6">>商场服务</el-col><el-col :span="6">>扫码积分</el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">>热区打卡</el-col><el-col :span="6">>服务优享</el-col><el-col :span="6">>活动预约</el-col><el-col :span="6">>运动无限</el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">>智能客服</el-col>
                </el-row>
            </div>
        </div>
        <div class="mb_mainBox" @click="routerLink('merchantServices')">
            <div class="mb_title">商户服务系统</div>
            <hr />
            <div class="mb_detail">提供便捷的线上商户运营服务，优化商场资源管理配置</div>
            <div class="mb_tag1Box">
                <div>0跑动线上业务流转</div>
                <div>可视化数据报表平台</div>
                <div>一网统管商户及各职能部门</div>
                <div>可叠加应用,覆盖“商场&商户“业务全场景</div>
            </div>
            <div class="mb_tag2Box">
                <el-row>
                    <el-col :span="6">>营业汇报</el-col><el-col :span="6">>日常报修</el-col><el-col :span="6">>活动通知</el-col><el-col :span="6">>消息管理</el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">>物品放行</el-col><el-col :span="6">>场地活动</el-col><el-col :span="6">>会议管理</el-col><el-col :span="6">>数据报表</el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">>一户一档</el-col><el-col :span="6">>品牌推广</el-col><el-col :span="6">>投诉建议</el-col><el-col :span="6">>公区维护</el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">>三关一闭</el-col><el-col :span="6">>惩处管理</el-col><el-col :span="6">>店务管理</el-col><el-col :span="6">>集团管理</el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">>广告投放</el-col><el-col :span="6">>卡券核销</el-col><el-col :span="6">>工作移交</el-col><el-col :span="6">>电子员工证</el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'ProductCenterHomePage',
    data() {
        return {
            PCB1_onoffState: true,
            PCB2_onoffState: false,
            PCB3_onoffState: false,
        }
    },
    methods: {
        changeWeight(num) {
            let block1 = document.getElementById('PCB_style1'),
                block2 = document.getElementById('PCB_style2'),
                block3 = document.getElementById('PCB_style3');
            switch (num) {
                case 1:
                    block1.animate(
                        [{
                            width: '60%'
                        }],
                        {
                            duration: 200,
                            easing: 'linear',
                            delay: 0,
                            iterations: 1,
                            direction: 'normal',
                            fill: 'forwards',
                        }
                    )
                    block2.animate(
                        [{
                            width: '20%'
                        }],
                        {
                            duration: 200,
                            easing: 'linear',
                            delay: 0,
                            iterations: 1,
                            direction: 'normal',
                            fill: 'forwards',
                        }
                    )
                    block3.animate(
                        [{
                            width: '20%'
                        }],
                        {
                            duration: 200,
                            easing: 'linear',
                            delay: 0,
                            iterations: 1,
                            direction: 'normal',
                            fill: 'forwards',
                        }
                    )
                    this.PCB1_onoffState = true;
                    this.PCB2_onoffState = false;
                    this.PCB3_onoffState = false;
                    break;
                case 2:
                    block1.animate(
                        [{
                            width: '20%'
                        }],
                        {
                            duration: 200,
                            easing: 'linear',
                            delay: 0,
                            iterations: 1,
                            direction: 'normal',
                            fill: 'forwards',
                        }
                    )
                    block2.animate(
                        [{
                            width: '60%'
                        }],
                        {
                            duration: 200,
                            easing: 'linear',
                            delay: 0,
                            iterations: 1,
                            direction: 'normal',
                            fill: 'forwards',
                        }
                    )
                    block3.animate(
                        [{
                            width: '20%'
                        }],
                        {
                            duration: 200,
                            easing: 'linear',
                            delay: 0,
                            iterations: 1,
                            direction: 'normal',
                            fill: 'forwards',
                        }
                    )
                    this.PCB1_onoffState = false;
                    this.PCB2_onoffState = true;
                    this.PCB3_onoffState = false;
                    break;
                case 3:
                    block1.animate(
                        [{
                            width: '20%'
                        }],
                        {
                            duration: 200,
                            easing: 'linear',
                            delay: 0,
                            iterations: 1,
                            direction: 'normal',
                            fill: 'forwards',
                        }
                    )
                    block2.animate(
                        [{
                            width: '20%'
                        }],
                        {
                            duration: 200,
                            easing: 'linear',
                            delay: 0,
                            iterations: 1,
                            direction: 'normal',
                            fill: 'forwards',
                        }
                    )
                    block3.animate(
                        [{
                            width: '60%'
                        }],
                        {
                            duration: 200,
                            easing: 'linear',
                            delay: 0,
                            iterations: 1,
                            direction: 'normal',
                            fill: 'forwards',
                        }
                    )
                    this.PCB1_onoffState = false;
                    this.PCB2_onoffState = false;
                    this.PCB3_onoffState = true;
                    break;
            }
        },
        routerLink(data) {
            // console.log(data);
            this.$router.push({
                path: '/productCenter',
                query: {
                    data: data
                }
            })
        },
    }
}
</script>
  
<style lang="scss" scoped>
.productCenterBlock {
    height: 768px;
    cursor: url(../assets/image/mouseArrow.svg), pointer;
    position: relative;

    #PCB_style1,
    #PCB_style2,
    #PCB_style3 {
        display: block;
        float: left;
        height: 768px;
        position: relative;
        overflow: hidden;

        .PCB_BlockOpen {
            position: absolute;
            top: 120px;
            left: 200px;
            right: 200px;
            bottom: 160px;
            font-size: 20px;

            .PCB_BO_title {
                font-size: 40px;
                font-weight: bold;
            }

            .PCB_BO_desc {
                margin: 20px 0;
                font-family: MicrosoftYaHei;
                font-weight: bolder;
            }

            .PCB_BO_intro {
                // color: #fff;
                text-align: center;

                div {
                    background-color: #f2f2f2;
                    margin: 10px 5px;
                    padding: 5px 10px;
                }
            }

            .PCB_BO_intro2 {
                font-size: 16px;
                margin-top: 50px;
                line-height: 40px;
            }
        }

        .PCB_BlockClose {
            font-size: 20px;
            line-height: 40px;
            position: absolute;
            top: 260px;
            left: 40px;
            right: 40px;

            .PCB_BC_title {
                font-size: 30px;
                font-weight: bold;
            }
        }

        .moreBtn {
            position: absolute;
            right: 140px;
            bottom: 42px;
            cursor: url(../assets/image/cursorPoint.png), pointer;

            .moreBtn_font {
                line-height: 44px;
                text-align: center;
                font-size: 16px;
            }

            img {
                width: 40px;
                height: 44px;
                align-items: center;
                justify-content: center;
            }
        }
    }

    #PCB_style3 {
        width: 20%;
    }

    #PCB_style2 {
        background-color: #000;
        color: #fff;
        width: 20%;

        .PCB_BO_intro {
            // color: #000 !important;

            div {
                background-color: #292929 !important;
            }
        }
    }

    #PCB_style1 {
        width: 60%;
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
    }

    .mb_mainBox {
        padding: 0.75rem 0.5rem;

        .mb_title {
            font-size: 0.45rem;
            font-weight: bold;
        }

        hr {
            width: 1rem;
            margin: 0.3rem 0;
        }

        .mb_detail {
            font-size: 0.35rem;
        }

        .mb_tag1Box {
            text-align: center;
            display: flex;
            flex-flow: row wrap;
            margin: 0.5rem 0;

            div {
                width: auto;
                background-color: #f2f2f2;
                margin: 0.1rem;
                padding: 0.1rem;
                font-size: 0.3rem;
                margin-right: 0.3rem;
            }
        }

        .mb_tag2Box {
            margin-top: 0.4rem;
            font-size: 0.3rem;
            line-height: 0.75rem;
        }
    }
    .mb_mainBox:nth-child(even){
        background-color: #f6f6f6;
    }
}
</style>
  