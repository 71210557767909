<template>
    <div ref="obtain">
        <div class="mobile_style" style="height:1.35rem"></div>
        <div class="bannerBox pc_style" id="bannerBox">
            <div class="bannerLeftBox">
                <div class="bannerLeft_title">{{ contentJson.bannerTitle }}</div>
                <hr>
                <div class="bannerLeft_describe">
                    {{ contentJson.bannerDesc }}
                </div>
            </div>
            <div class="bannerRightBox">
                <div class="bannerRight_block" v-for="(item, index) in contentJson.relatedCases" :key="index"
                    :style="[item.imgUrl != '' ? { 'background-image': `url(${this.getImgPath(item.imgUrl)})` } : '']"
                    v-show="item.entryName == '' ? false : true">
                    <div class="bannerRight_maskBox">
                        <div class="bannerRight_title">{{ item.entryName }}</div>
                        <div class="bannerRight_describe">
                            <el-row>{{ item.customer }}</el-row>
                        </div>
                    </div>
                    <div :class="'animationBlock' + (index + 1)"></div>
                </div>
            </div>
        </div>
        <el-row class="pc_style">
            <el-col :span="3">
                <div class="category_box" v-show="showMenuState">
                    <div class="category_body">
                        <div v-for="(categoryItem, categoryIndex) in contentJson.categoryList" :key="categoryIndex"
                            @click="scrolltoBlock(categoryItem.id)" class="category_inside">
                            <div class="category_title" :class="categoryItem.active ? 'case_name' : ''">
                                {{ categoryItem.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="21">
                <div class="productCenterBox" id="scrollBox1">
                    <el-row class="productCenter_title">{{ contentJson.productDetails }}</el-row>
                    <el-row class="productCenter_block1">
                        <el-col :span="6" class="iblock1_inside" v-for="(item, index) in contentJson.b1List" :key="index">
                            <div class="iblock1_content">
                                <el-row class="iblock1_fontBold">{{ item.title }}</el-row>
                                <el-row class="iblock1_fontNormal">{{ item.describe }}</el-row>
                                <div class="iblock1_fontSmall">
                                    <el-row v-show="item.text1 == '' ? false : true">-&nbsp;{{ item.text1 }}</el-row>
                                    <el-row v-show="item.text2 == '' ? false : true">-&nbsp;{{ item.text2 }}</el-row>
                                    <el-row v-show="item.text3 == '' ? false : true">-&nbsp;{{ item.text3 }}</el-row>
                                </div>
                                <img :src="getImgPath(item.imageUrl)" alt="">
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="productCenterBox" id="scrollBox2">
                    <el-row class="productCenter_title">{{ contentJson.functionIntroduction }}</el-row>
                    <el-row>
                        <el-col :span="10">
                            <img class="productCenter_img" :src="this.currentImg" alt="">
                        </el-col>
                        <el-col :span="14">
                            <div class="productCenter_tableBlock">
                                <div class="pt_title">{{ contentJson.b2ArrDesc }}</div>
                                <div class="ptBox" v-for="(item, index) in contentJson.b2Arr" :key="index"> > {{ item }}
                                </div>
                            </div>
                            <el-row class="productCenter_cardBlock" v-for="(item, index) in contentJson.b2List" :key="index"
                                @mouseenter="checkPic(item)" :class="[item.active ? 'behigh' : '']">
                                <el-row class="cardBlock_one">
                                    <div class="fontWeightStyle fontTitle">{{ item.titel }}</div>
                                    <div class="fontDesc">{{ item.desc }}</div>
                                    <div class="fontWeightStyle fontNum"
                                        :class="[item.active ? 'fontNum_black' : 'fontNum_white']">0{{
                                            index + 1 }}</div>
                                </el-row>
                                <el-row class="cardBlock_two">
                                    <el-col :span="8" v-for="(items, indexs) in item.list" :key="indexs">> {{ items
                                    }}</el-col>
                                </el-row>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
                <div class="productCenterBox" id="scrollBox3">
                    <el-row class="productCenter_title">{{ contentJson.valueUp }}</el-row>
                    <el-row class="productCenter_block3">
                        <el-col :span="15">
                            <div class="iblock3_imgBox">
                                <img :src="this.currentImg2" alt="">
                                <div class="iblock3_imgBox_mask">
                                    <el-row class="whitefont" v-for="(item, index) in contentJson.b3List" :key="index"
                                        :id="'fontMove' + (index + 1)" :class="[item.active ? '' : 'moveFont']">{{
                                            item.inPictrueText
                                        }}</el-row>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="9" class="iblock3_rightBox">
                            <el-row v-for="(item, index) in contentJson.b3List" :key="index" class="b3rb_inside">
                                <el-row class="numRow fontWeightStyle" @mouseenter="stateChangeLine(index + 1, item)"
                                    :class="[item.active ? 'fontBlack' : '']">
                                    {{ item.customer }}
                                </el-row>
                                <el-row class="textRow" :class="[item.active ? 'spanFontBlack' : 'spanFontwhite']">
                                    <ICountUp :delay="delay" :endVal="item.number" :options="options"
                                        :key="this.resetCountUp" />
                                    <span>{{ item.unit }}</span>
                                </el-row>
                            </el-row>
                        </el-col>
                        <div v-for="(item, index) in contentJson.b3List" :key="index" :id="'line' + (index + 1)"
                            :class="[item.active ? 'ib3_lineStyle2' : 'ib3_lineStyle1']"></div>
                    </el-row>
                </div>
                <div class="productCenterBox" id="scrollBox4" v-show="applicationShow">
                    <el-row class="productCenter_title">{{ contentJson.application }}</el-row>
                    <div class="iblock4">
                        <div class="ib4_Block" v-for="(item, index) in contentJson.b4List" :key="index"
                            :class="[item.active ? 'ib4_openStyle' : 'ib4_closeStyle']"
                            :style="[item.imgUrl != '' ? { 'background-image': `url(${this.getImgPath(item.imgUrl)})` } : '']"
                            @mouseenter="switchIb4Change(item.id)">
                            <div class="ib4_maskbox">
                                <div class="ib4_closeBlock" v-show="!item.active">{{ item.title }}</div>
                                <div class="ib4_openBlock" v-show="item.active">
                                    <el-row class="iblock4_title">{{ item.title }}</el-row>
                                    <el-row class="iblock4_text">{{ item.text1 }}<br>{{ item.text2 }}<br>{{ item.text3
                                    }}</el-row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div class="bottomMenuBox1 pc_style" v-show="showMenuState" v-if="bottomShow">
            <div class="BMB_block" v-for="(item, index) in bottomMenu" :key="index" @click="routerLink(item.data)">{{
                item.name }}
                <span class="BMB_active" v-if="item.active"></span>
            </div>
        </div>
        <div class="bottomMenuBox2 pc_style" v-show="showMenuState" v-else>
            <div class="BMB_block" v-for="(item, index) in bottomMenu" :key="index" @click="routerLink(item.data)">{{
                item.name }}
                <span class="BMB_active" v-if="item.active"></span>
            </div>
        </div>
        <!-- <div class="bannerBox mobile_style"> -->
        <div class="bannerBox mobile_style" :style="{ 'background-image': `url(${getImgPath(contentJson.mobileBG)}) ` }">
            <div class="bannerLeft_title">{{ contentJson.bannerTitle }}</div>
            <hr>
            <div class="bannerLeft_describe">
                {{ contentJson.bannerDesc }}
            </div>
        </div>
        <div class="mobile_style">
            <div class="productCenterBox">
                <el-row class="productCenter_title">{{ contentJson.productDetails }}</el-row>
                <div class="productCenter_block1">
                    <div class="iblock1_outside">
                        <div class="iblock1_inside" v-for="(item, index) in contentJson.b1List" :key="index">
                            <div class="iblock1_content">
                                <div class="iblock1_fontBold">{{ item.title }}</div>
                                <div class="iblock1_fontNormal">{{ item.describe }}</div>
                                <div class="iblock1_fontSmall">
                                    <el-row v-show="item.text1 == '' ? false : true">-&nbsp;{{ item.text1 }}</el-row>
                                    <el-row v-show="item.text2 == '' ? false : true">-&nbsp;{{ item.text2 }}</el-row>
                                    <el-row v-show="item.text3 == '' ? false : true">-&nbsp;{{ item.text3 }}</el-row>
                                </div>
                                <img :src="getImgPath(item.imageUrl)" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="productCenterBox">
                <el-row class="productCenter_title">{{ contentJson.functionIntroduction }}</el-row>
                <div class="productCenter_block2">
                    <div class="productCenter_tableBlock">
                        <div class="pt_title">{{ contentJson.b2ArrDesc }}</div>
                        <div class="ptBox" v-for="(item, index) in contentJson.b2Arr" :key="index"> > {{ item }}
                        </div>
                    </div>
                    <el-row class="productCenter_cardBlock" v-for="(item, index) in contentJson.b2List" :key="index"
                        @click="checkPic(item)">
                        <el-row class="cardBlock_one">
                            <div class="fontWeightStyle fontTitle">{{ item.titel }}</div>
                            <div class="fontDesc">{{ item.desc }}</div>
                            <div class="fontWeightStyle fontNum" :class="[item.active ? 'fontNum_black' : 'fontNum_white']">
                                0{{ index + 1 }}</div>
                        </el-row>
                        <el-row class="cardBlock_two" v-show="item.active">
                            <el-row style="width: 100%;" v-for="(items, indexs) in item.list" :key="indexs">> {{ items
                            }}</el-row>
                        </el-row>
                    </el-row>
                </div>
            </div>
            <div class="productCenterBox">
                <el-row class="productCenter_title">{{ contentJson.valueUp }}</el-row>
                <div class="productCenter_block3">
                    <div v-for="(item, index) in contentJson.b3List" :key="index">
                        <el-row class="row1">
                            <el-col :span="12">{{ item.inPictrueText }}</el-col>
                            <el-col :span="12" class="fontWeight">{{ item.customer }}</el-col>
                        </el-row>
                        <div class="row2">{{ item.number }}+</div>
                    </div>
                </div>
            </div>
            <div class="productCenterBox" v-show="applicationShow">
                <el-row class="productCenter_title">{{ contentJson.application }}</el-row>
                <div class="iblock4" v-for="(item, index) in contentJson.b4List" :key="index">
                    <div class="ib4_Block ib4_openStyle"
                        :style="[item.imgUrl != '' ? { 'background-image': `url(${this.getImgPath(item.imgUrl)})` } : '']">
                        <div class="ib4_maskbox">
                            <el-row class="iblock4_title">{{ item.title }}</el-row>
                            <el-row class="iblock4_text">{{ item.text1 }}<br>{{ item.text2 }}<br>{{ item.text3 }}</el-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import ICountUp from 'vue-countup-v2/src/countup.vue';
import productCenterJson from '@/static/json/productCenter.json';

export default {
    name: 'productCenter',
    components: {
        ICountUp
    },
    data() {
        return {
            //---countUp插件的参数---
            delay: 0,
            instance: '',
            options: {
                useEasing: true,
                useGrouping: true,
                // duration: 0.5,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            //---end---
            scrollTop: 0,
            resetCountUp: 0,
            b2ImgList: [
                {
                    id: 1,
                    imageUrl: ""
                }
            ],
            currentImg: "",
            currentImg2: "",
            showMenuState: false,
            bottomMenu: [
                {
                    id: 1,
                    name: '定位导视系统',
                    data: 'navigation',
                    active: true
                },
                {
                    id: 2,
                    name: '会员系统',
                    data: 'memberMarking',
                    active: false
                },
                {
                    id: 3,
                    name: '商户服务管理系统',
                    data: 'merchantServices',
                    active: false
                },
                {
                    id: 4,
                    name: '交互商显',
                    data: 'interactiveView',
                    active: false
                },
            ],
            applicationShow: true,
            bottomShow: true,
            contentJson: {}//存储json中的数据
        }
    },
    watch: {
        $route(to, from) {
            if (this.$route.query.data && from.path == "/productCenter") {
                this.changeItemData(this.$route.query.data)
            }
        }
    },
    mounted() {
        if (this.$route.query != undefined) {
            this.changeItemData(this.$route.query.data);
        }
        window.addEventListener('scroll', this.watchScroll);
        this.androidScrollTop();
    },
    unmounted() {
        window.removeEventListener('scroll', this.watchScroll);
    },
    methods: {
        androidScrollTop() {//手机安卓端微信内置浏览器切换页面复位到页面顶部
            this.$store.commit('whichDeviceFun')
            if (!this.$store.state.mobileOrPC) {
                this.$refs.obtain.scrollIntoView()
            }
        },
        isInViewPort(element) {//判断目标元素是否在视图范围内
            const viewWidth = window.innerWidth || document.documentElement.clientWidth;
            const viewHeight = window.innerHeight || document.documentElement.clientHeight;
            const {
                top,
                right,
                bottom,
                left,
            } = element.getBoundingClientRect();
            return (
                top >= 0 &&
                left >= 0 &&
                right <= viewWidth &&
                bottom <= viewHeight * 0.8
            );
        },
        watchScroll() {
            this.changeCountUp();
            this.showMenu();
            this.leftMenuChange();
        },
        publicChangeActive(itemId, list) {
            for (const key in list) {
                if (Object.hasOwnProperty.call(list, key)) {
                    const element = list[key];
                    if (element.id === itemId) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                }
            }
        },
        changeCountUp() {
            let watchBox = document.getElementById('scrollBox3');
            let isInView = this.isInViewPort(watchBox);
            if (isInView) {
                if (this.resetCountUp == 0) {
                    this.resetCountUp++;
                }
            }
        },
        showMenu() {
            let nowScroll = document.documentElement.scrollTop,
                bannerScroll = document.getElementById('bannerBox').offsetHeight;
            if (nowScroll < bannerScroll / 2) {
                this.showMenuState = false;
            } else {
                this.showMenuState = true;
            }
        },
        getEleTop(el) {
            let realTop = el.offsetTop;
            realTop += el.offsetParent.offsetTop;
            return realTop;
        },
        leftMenuChange() {
            let currentArr = [],
                nowHeight = Math.abs(this.$refs.obtain.getBoundingClientRect().top) + 120;
            if (this.applicationShow) {
                currentArr = [
                    this.getEleTop(document.getElementById('scrollBox1')),
                    this.getEleTop(document.getElementById('scrollBox2')),
                    this.getEleTop(document.getElementById('scrollBox3')),
                    this.getEleTop(document.getElementById('scrollBox4'))
                ]
            } else {
                currentArr = [
                    this.getEleTop(document.getElementById('scrollBox1')),
                    this.getEleTop(document.getElementById('scrollBox2')),
                    this.getEleTop(document.getElementById('scrollBox3'))
                ]
            }
            console.log(currentArr);
            for (let i = 0; i < currentArr.length; i++) {
                if (i == currentArr.length - 1) {
                    if (nowHeight >= currentArr[i]) {
                        this.publicChangeActive(i + 1, this.contentJson.categoryList);
                        this.bottomShow = false;
                    } else {
                        this.bottomShow = true;
                    }
                } else {
                    if (nowHeight >= currentArr[i] && nowHeight < currentArr[i + 1]) {
                        this.publicChangeActive(i + 1, this.contentJson.categoryList);
                    }
                }
            }
        },
        scrolltoBlock(id) {
            console.log('scrolltoBlock-----', id)
            document.querySelector("#scrollBox" + id).scrollIntoView({
                behavior: "smooth"
            });
        },
        switchIb4Change(itemId) {
            this.publicChangeActive(itemId, this.contentJson.b4List);
        },
        stateChangeLine(itemId, item) {
            let list = this.contentJson.b3List;
            for (const key in list) {
                if (Object.hasOwnProperty.call(list, key)) {
                    const element = list[key];
                    if (element.id === itemId) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                }
            }
            this.currentImg2 = this.getImgPath(item.imageUrl);
        },
        // changeLineWiden(num, item) {
        //     let nowline = document.getElementById("line" + num);
        //     let nowfont = document.getElementById('fontMove' + num);
        //     nowline.classList.remove("ib3_lineStyle1");
        //     nowline.classList.add("ib3_lineStyle2");
        //     nowline.animate(
        //         [{
        //             width: '75%'
        //         }],
        //         {
        //             duration: 300,
        //             easing: 'linear',
        //             delay: 0,
        //             iterations: 1,
        //             direction: 'normal',
        //             fill: 'forwards',
        //         }
        //     )
        //     nowfont.animate(
        //         [{
        //             paddingLeft: '50%'
        //         }],
        //         {
        //             duration: 300,
        //             easing: 'linear',
        //             delay: 0,
        //             iterations: 1,
        //             direction: 'normal',
        //             fill: 'forwards',
        //         }
        //     )
        //     this.currentImg2 = this.getImgPath(item.imageUrl);
        // },
        // changeLineNarrow(num) {
        //     let nowline = document.getElementById("line" + num);
        //     let nowfont = document.getElementById('fontMove' + num);
        //     nowline.classList.remove("ib3_lineStyle2");
        //     nowline.classList.add("ib3_lineStyle1");
        //     nowline.animate(
        //         [{
        //             width: '55%'
        //         }],
        //         {
        //             duration: 300,
        //             easing: 'linear',
        //             delay: 0,
        //             iterations: 1,
        //             direction: 'normal',
        //             fill: 'forwards',
        //         }
        //     )
        //     nowfont.animate(
        //         [{
        //             paddingLeft: "76%"
        //         }],
        //         {
        //             duration: 300,
        //             easing: 'linear',
        //             delay: 0,
        //             iterations: 1,
        //             direction: 'normal',
        //             fill: 'forwards',
        //         }
        //     )
        // },
        routerLink(data) {
            this.$router.push({
                path: '/productCenter',
                query: {
                    data: data
                }
            });
            this.changeItemData(data);
        },
        changeItemData(data) {
            console.log(data);
            switch (data) {
                case "navigation":
                    this.contentJson = productCenterJson.navigation;
                    this.publicChangeActive(1, this.bottomMenu);
                    this.applicationShow = true;
                    break;
                case "memberMarking":
                    this.contentJson = productCenterJson.memberMarking;
                    this.publicChangeActive(2, this.bottomMenu);
                    this.applicationShow = false;
                    break;
                case "merchantServices":
                    this.contentJson = productCenterJson.merchantServices;
                    this.publicChangeActive(3, this.bottomMenu);
                    this.applicationShow = false;
                    break;
                case "interactiveView":
                    this.contentJson = productCenterJson.interactiveView;
                    this.publicChangeActive(4, this.bottomMenu);
                    this.applicationShow = false;
                    break;
            }
            this.currentImg = this.getImgPath(this.contentJson.block2Img);
            this.currentImg2 = this.getImgPath(this.contentJson.block3Img);
        },
        getImgPath(data) {
            return require('@/assets/' + data);
        },
        checkPic(item) {
            for (const key in this.contentJson.b2List) {
                if (Object.hasOwnProperty.call(this.contentJson.b2List, key)) {
                    const element = this.contentJson.b2List[key];
                    if (element.id === item.id) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                }
            }
            this.currentImg = this.getImgPath(item.imageUrl);
        },
    }
}
</script>
<style lang="scss" scoped>
@keyframes menuOpen {
    from {
        height: 128px;
    }

    to {
        height: 190px;
    }
}

@keyframes higher {
    from {
        top: 280px;
        bottom: 250px;
    }

    to {
        top: 130px;
        bottom: 130px;
    }
}

@keyframes bannerAnimation {
    0% {
        transform: translateX(-100%);
    }

    25% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(0);
    }

    75% {
        transform: translateX(-100%);
    }
}

@keyframes moveFont {
    from {
        padding-left: 50%;
    }

    to {
        padding-left: 73%;
    }
}

.bannerBox {
    height: 680px;
    display: flex;
    border-bottom: 1px solid #313131;

    .bannerLeftBox {
        width: 52%;
        padding-top: 180px;
        padding-left: 240px;
        padding-right: 100px;
        padding-bottom: 180px;

        .bannerLeft_title {
            font-size: 60px;
        }

        .bannerLeft_describe {
            font-size: 20px;
        }

        hr {
            width: 360px;
            margin-top: 30px;
            margin-bottom: 50px;
        }
    }

    .bannerRightBox {
        width: 48%;
        display: flex;
        justify-content: right;

        .bannerRight_block {
            width: 33.3333%;
            position: relative;
            overflow: hidden;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            .bannerRight_maskBox {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($color: #000000, $alpha: 0.5);
                color: #fff;
                padding: 270px 30px;
                line-height: 40px;
                z-index: 90;

                .bannerRight_title {
                    font-size: 30px;
                }

                .bannerRight_describe {
                    font-size: 16px;
                    position: absolute;
                    bottom: 42%;
                }
            }

            .animationBlock1,
            .animationBlock2,
            .animationBlock3 {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #313131;
                z-index: 9;
                transform: translateX(-100%);
                // animation: bannerAnimation 6s infinite;
            }

            .animationBlock1 {
                animation-delay: 3s;
            }

            .animationBlock2 {
                animation-delay: 3.5s;
            }

            .animationBlock3 {
                animation-delay: 4s;
            }
        }
    }
}

.category_box {
    width: 240px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    .category_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 200px;

        .category_inside {
            width: 100%;

            .category_title {
                display: flex;
                align-items: center;
                margin: 15px 0;
                font-size: 20px;
                color: #999999;
                cursor: pointer;
            }

            .case_name {
                padding: 50px 0;
                font-size: 30px;
                font-family: Alimama_ShuHeiTi_Bold;
                font-weight: bold;
                color: #313131;
            }
        }
    }
}

.productCenterBox {
    height: 1080px;
    color: #313131;
    border-top: 1px solid #313131;

    .productCenter_title {
        font-size: 30px;
        font-family: Alimama_ShuHeiTi_Bold;
        padding-top: 100px;
        padding-left: 30px;
        padding-bottom: 80px;
    }

    .productCenter_img {
        width: 600px;
        height: 800px;
        background-color: #f6f6f6;
        margin: 0 30px;
    }

    .behigh {
        animation: menuOpen 0.5s;
        animation-fill-mode: forwards;
    }

    .productCenter_cardBlock {
        height: 100px;
        overflow: hidden;

        .cardBlock_one {
            width: 95%;
            height: 100px;
            border-bottom: 1px solid #313131;
            align-items: end;
            padding-bottom: 16px;
            position: relative;

            .fontTitle {
                font-size: 24px;
                margin-right: 20px;
            }

            .fontDesc {
                font-size: 20px;
            }

            .fontNum {
                font-size: 60px;
                position: absolute;
                right: 0;
                bottom: 0;
            }

            .fontNum_white {
                color: #f6f6f6;
            }

            .fontNum_black {
                color: #313131;

            }
        }

        .cardBlock_two {
            width: 100%;
            font-size: 16px;
            line-height: 40px;
            margin-top: 20px;
        }
    }

    .productCenter_tableBlock {
        line-height: 50px;
        margin-top: 10px;
        font-size: 16px;
        display: flex;
        flex-flow: wrap;

        .pt_title {
            font-weight: bolder;
            font-size: 24px;
            width: 100%;
        }

        .ptBox {
            width: 33.33%;
        }
    }

    .iblock4 {
        width: 100%;
        height: 750px;
        border: 2px solid #eee;
        display: flex;

        .ib4_openStyle {
            width: 50%;
        }

        .ib4_closeStyle {
            width: 12.5%;
        }

        .ib4_Block {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            color: #fff;
            position: relative;

            .ib4_maskbox {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($color: #000000, $alpha: 0.5);

                .ib4_openBlock {
                    height: 750px;
                    padding: 240px 0px;
                    text-align: center;
                    border-right: 0.005208rem solid #ededed;

                    .iblock4_title {
                        font-size: 40px;
                        font-weight: bold;
                        justify-content: center;
                    }

                    .iblock4_text {
                        font-size: 24px;
                        line-height: 38px;
                        justify-content: center;
                    }
                }

                .ib4_closeBlock {
                    height: 750px;
                    font-size: 30px;
                    font-family: MicrosoftYaHei;
                    font-weight: bold;
                    padding: 240px 0;
                    border-right: 1px solid #ededed;
                    text-align: center;
                }
            }
        }
    }

    .productCenter_block1 {
        background-color: #fff;
        height: 854px;
        border: 1px solid #ededed;

        .iblock1_inside {
            position: relative;
            border-right: 1px solid #ededed;

            .iblock1_content {
                overflow: hidden;
                position: absolute;
                top: 130px;
                left: 50px;
                right: 50px;
                bottom: 130px;

                img {
                    width: 320px;
                    height: 180px;
                    position: absolute;
                    bottom: 60px;
                }

                .iblock1_fontBold {
                    font-size: 28px;
                    font-weight: bold;
                    // font-family: MicrosoftYaHei;
                    border-bottom: 1px solid #313131;
                    padding: 10px 0;
                }

                .iblock1_fontNormal {
                    font-size: 20px;
                    margin-top: 25px;
                    margin-bottom: 50px;
                }

                .iblock1_fontSmall {
                    font-size: 16px;
                    line-height: 40px;
                    position: absolute;
                    top: 200px;
                }
            }
        }

    }

    .productCenter_block3 {
        height: 854px;
        margin-right: 120px;
        position: relative;

        .iblock3_imgBox {
            width: 100%;
            height: 780px;
            position: relative;

            img {
                width: 968px;
                height: 780px;
            }

            .iblock3_imgBox_mask {
                width: 968px;
                height: 780px;
                // padding-right: 48px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($color: #000000, $alpha: 0.10);

                .whitefont {
                    font-size: 24px;
                    color: #fff;
                    justify-content: left;
                    padding-top: 180px;
                    height: 215px;
                    padding-left: 50%;
                }

                .whitefont:first-child {
                    padding-top: 65px;
                    height: 100px;
                }

                .moveFont {
                    animation: moveFont 0.5s;
                    animation-fill-mode: forwards;
                }
            }
        }

        .ib3_lineStyle1 {
            width: 55%;
            height: 2px;
            background: #313131;
            position: absolute;
            right: 0;
        }

        .ib3_lineStyle1::after {
            position: absolute;
            top: 0;
            left: 0;
            content: " ";
            display: block;
            width: 32%;
            height: 100%;
            background-color: #ccc;
        }

        .ib3_lineStyle2 {
            width: 75%;
            height: 2px;
            background: #313131;
            position: absolute;
            right: 0;
        }

        .ib3_lineStyle2::after {
            position: absolute;
            top: 0;
            left: 0;
            content: " ";
            display: block;
            width: 50%;
            height: 100%;
            background-color: #ccc;
        }

        #line1 {
            top: 100px;
        }

        #line2 {
            top: 315px;
        }

        #line3 {
            top: 530px;
        }

        #line4 {
            top: 745px;
        }

        .iblock3_rightBox {
            .b3rb_inside {
                .numRow {
                    width: 100%;
                    height: 150px;
                    padding-top: 60px;
                    font-size: 35px;
                    color: #ccc;
                    justify-content: right;
                }

                .fontBlack,
                .numRow:hover {
                    color: #313131
                }

                .textRow {
                    width: 100%;
                    padding-top: 10px;
                    justify-content: right;
                    font-size: 24px;
                    color: #313131;

                    span {
                        font-size: 36px;
                        padding-right: 10px;
                    }
                }

                .spanFontBlack {
                    span {
                        color: #313131;
                    }
                }

                .spanFontwhite {
                    span {
                        color: #ccc;
                    }
                }
            }

            .b3rb_inside:first-child {
                .numRow {
                    height: 100px;
                    padding-top: 0;
                }
            }
        }
    }
}

.productCenterBox:first-child {
    border-top: 0;
}

.bottomMenuBox1 {
    width: 988px;
    height: 76px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    backdrop-filter: blur(10px);
    padding: 19px 0;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    .BMB_block {
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        width: 25%;
        border-right: 1px solid #999;
        float: left;
        cursor: pointer;
        position: relative;

        .BMB_active {
            width: 10px;
            height: 10px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid #313131;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -10px;
        }
    }

    .BMB_block:last-child {
        border: 0;
    }
}

.bottomMenuBox2 {
    width: 988px;
    height: 76px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    backdrop-filter: blur(10px);
    padding: 19px 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .BMB_block {
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        width: 25%;
        border-right: 1px solid #999;
        float: left;
        cursor: pointer;
        position: relative;

        .BMB_active {
            width: 10px;
            height: 10px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid #313131;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -10px;
        }
    }

    .BMB_block:last-child {
        border: 0;
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
        // margin-bottom: 0.5rem;
    }

    .bannerBox {
        // margin-top: 1.35rem;
        padding: 0.5rem;
        border-bottom: 1px solid #313131;
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;

        .bannerLeft_title {
            font-size: 0.5rem;
            font-weight: bolder;
            color: #fff;
        }

        .bannerLeft_describe {
            font-size: 0.3rem;
            color: #fff;
        }

        hr {
            width: 3rem;
            margin: 0.3rem 0;
        }
    }

    .productCenterBox {
        height: auto;
        border-top: 1px solid #313131;

        .productCenter_title {
            height: auto;
            font-size: 0.5rem;
            padding: 1rem 0.3rem;
            justify-content: center;
        }

        .productCenter_block1 {
            height: auto;
            overflow: auto;

            .iblock1_outside {
                width: 20rem;
                height: 8rem;

                .iblock1_inside {
                    width: 5rem;
                    height: 10rem;
                    float: left;

                    .iblock1_content {
                        padding: 0.5rem 0;

                        .iblock1_fontBold {
                            font-size: 0.45rem;
                            padding: 0.3rem 0;
                        }

                        .iblock1_fontNormal {
                            font-size: 0.3rem;
                            position: relative;
                            margin: 0.5rem 0;
                            min-height: 1rem;
                        }

                        .iblock1_fontSmall {
                            font-size: 0.3rem;
                            position: relative;
                            top: 0;
                            line-height: 0.5rem;
                        }

                        img {
                            width: 100%;
                            height: 2.25rem;
                            bottom: 0.1rem;
                        }
                    }
                }
            }
        }

        .productCenter_block2 {
            padding: 0.25rem;
            margin-bottom: 1rem;

            .productCenter_tableBlock {
                margin-bottom: 0.5rem;
                font-size: 0.3rem;
                line-height: 0.75rem;

                .pt_title {
                    font-size: 0.4rem;
                }
            }

            .productCenter_cardBlock {
                height: auto;

                .cardBlock_one {
                    font-size: 1.5rem;
                    min-height: 2rem;
                    width: 100%;
                    // align-items: center;

                    .fontTitle {
                        font-size: 0.5rem;
                        margin-right: 0.3rem;
                    }

                    .fontDesc {
                        font-size: 0.3rem;
                    }

                    .fontNum {
                        font-size: 0.6rem;
                    }
                }

                .cardBlock_two {
                    font-size: 0.3rem;
                    line-height: 0.75rem;
                    height: auto;
                }
            }
        }

        .productCenter_block3 {
            height: auto;
            margin: 0;
            padding: 0.25rem;
            font-size: 0.35rem;

            .row1 {
                height: 1rem;
                line-height: 1rem;

                .fontWeight {
                    font-size: 0.5rem;
                    font-weight: bolder;
                    text-align: right;
                }
            }

            .row2 {
                width: 100%;
                height: 1rem;
                line-height: 1rem;
                text-align: right;
                border-top: 1px solid #D8D8D8;
            }
        }

        .iblock4 {
            width: 100%;
            height: auto;
            border: 0;
            display: flex;

            .ib4_openStyle {
                width: 100%;
                height: 7rem;
                margin: 0.25rem 0.5rem;
            }

            .ib4_Block {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                color: #fff;
                position: relative;

                .ib4_maskbox {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba($color: #000000, $alpha: 0.5);
                    padding: 2rem 1rem;

                    .iblock4_title {
                        font-size: 0.5rem;
                        font-weight: bold;
                        justify-content: center;
                    }

                    .iblock4_text {
                        font-size: 0.3rem;
                        line-height: 0.5rem;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
</style>