<template>
    <div class="bottomBox pc_style">
        <div class="bottomInsideBox">
            <div class="topRow">
                <el-row class="BT_row">
                    <el-col :span="4">
                        <el-row class="title"><span class="fontHoverBorderWhite">产品中心</span></el-row>
                        <el-row>——</el-row>
                        <el-row><span class="fontHoverBorderWhite link"
                                @click="routerLink('/productCenter', { data: 'navigation' })">导视导览系统</span></el-row>
                        <el-row><span class="fontHoverBorderWhite link"
                                @click="routerLink('/productCenter', { data: 'memberMarking' })">会员营销系统</span></el-row>
                        <el-row><span class="fontHoverBorderWhite link"
                                @click="routerLink('/productCenter', { data: 'merchantServices' })">商户服务系统</span></el-row>
                        <el-row><span class="fontHoverBorderWhite link"
                                @click="routerLink('/productCenter', { data: 'interactiveView' })">交互商显</span></el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row class="title"><span class="fontHoverBorderWhite">解决方案</span></el-row>
                        <el-row>——</el-row>
                        <el-row><span class="fontHoverBorderWhite link"
                                @click="routerLink('/solution', { data: 'commercialRealEstate' })">商业地产</span></el-row>
                        <el-row><span class="fontHoverBorderWhite link"
                                @click="routerLink('/solution', { data: 'governmentDigitization' })">政府数字化</span></el-row>
                        <el-row><span class="fontHoverBorderWhite link"
                                @click="routerLink('/solution', { data: 'smartPark' })">智慧园区</span></el-row>
                        <el-row><span class="fontHoverBorderWhite link"
                                @click="routerLink('/solution', { data: 'smartExhibitionHall' })">智慧展馆</span></el-row>
                        <el-row><span class="fontHoverBorderWhite link"
                                @click="routerLink('/solution', { data: 'smartCampus' })">智慧校园</span></el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row class="title"><span class="fontHoverBorderWhite link"
                                @click="routerLink('/typicalCase')">经典案例</span></el-row>
                        <el-row>——</el-row>
                        <el-row class="title"><span class="fontHoverBorderWhite link"
                                @click="routerLink('/informationCenter')">资讯中心</span></el-row>
                        <el-row>——</el-row>
                        <el-row class="title"><span class="fontHoverBorderWhite link"
                                @click="routerLink('/aboutUs')">关于云止</span></el-row>
                        <el-row>——</el-row>
                        <el-row class="title"><span class="fontHoverBorderWhite link"
                                @click="routerLink('/joinUs')">加入我们</span></el-row>
                        <el-row>——</el-row>
                    </el-col>
                    <el-col :span="12">
                        <el-row class="title">关于云止科技</el-row>
                        <el-row>扫码关注官方微信服务号，了解更多数字化信息，获取更全面的优质服务。</el-row>
                        <el-row>
                            <el-col :span="5">
                                <el-row class="QRcode_img">
                                    <img referrerpolicy="no-referrer" src="@/assets/image/ERcode.png" />
                                </el-row>
                                <el-row class="QRcode_msg">微信公众号</el-row>
                            </el-col>
                            <el-col :span="5">
                                <el-row class="QRcode_img">
                                    <img referrerpolicy="no-referrer" src="@/assets/image/business.png" />
                                </el-row>
                                <el-row class="QRcode_msg">企业微信</el-row>
                            </el-col>
                            <el-col :span="14"></el-col>
                        </el-row>
                        <el-row>
                            <span class="contactUs">资讯电话：400-996-9002<br>
                                公司邮箱：system@yunpoint.com<br>
                                公司地址：上海市嘉定区金沙江西路1555弄-上海西郊商务园C1-3号楼6楼</span>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <div class="bottomRow">云止科技 Copyright@2023&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index"
                    target="_blank">沪ICP备20011094号-2</a>
            </div>
        </div>
    </div>
    <div class="bottomBox mobile_style">
        <div class="bottom_mobileBox">
            <div class="bmb_Detail">
                <el-row class="bmb_aboutUs">关于云止科技</el-row>
                <el-row class="bmb_normal">扫码关注官方微信服务号，了解更多数字化信息，获取更全面的优质服务。</el-row>
                <el-row>
                    <el-col :span="5">
                        <el-row class="QRcode_img">
                            <img referrerpolicy="no-referrer" src="@/assets/image/ERcode.png" />
                        </el-row>
                        <el-row class="QRcode_msg">微信公众号</el-row>
                    </el-col>
                    <el-col :span="5">
                        <el-row class="QRcode_img">
                            <img referrerpolicy="no-referrer" src="@/assets/image/business.png" />
                        </el-row>
                        <el-row class="QRcode_msg">企业微信</el-row>
                    </el-col>
                    <el-col :span="14"></el-col>
                </el-row>
                <el-row class="bmb_normal">
                    <el-col :span="4" style="text-align: right;">咨询电话：</el-col><el-col :span="20">400-996-9002</el-col>
                </el-row>
                <el-row class="bmb_normal">
                    <el-col :span="4" style="text-align: right;">公司邮箱：</el-col><el-col :span="20">system@yunpoint.com</el-col>
                </el-row>
                <el-row class="bmb_normal">
                    <el-col :span="4" style="text-align: right;">公司地址：</el-col><el-col :span="20">上海市嘉定区金沙江西路1555弄-上海西郊商务园C1-3号楼6楼</el-col>
                </el-row>
            </div>
            <div class="bmb_finalRow">云止科技 Copyright@2022沪ICP备20011094号-2</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BottomBar',
    methods: {
        routerLink(url, data) {
            this.$router.push({
                path: url,
                query: data
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.bottomBox {
    background-color: #313131;
    color: #fff;
    height: 668px;
    position: relative;

    .bottomInsideBox {
        position: absolute;
        top: 80px;
        left: 220px;
        right: 220px;
        bottom: 15px;

        .topRow {
            .BT_row {
                font-size: 16px;

                .el-row {
                    padding: 15px 0px;
                }

                .title {
                    font-size: 20px;
                    font-weight: bold;
                }

                .QRcode_msg {
                    justify-content: center;
                }

                .contactUs {
                    line-height: 40px;
                    margin-top: 10px;
                }

                .QRcode_img {
                    position: relative;
                    height: 128px;
                }

                .QRcode_img img {
                    width: 128px;
                    height: 128px;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .link {
                    cursor: pointer;
                }
            }
        }

        .bottomRow {
            width: 100%;
            font-size: 16px;
            text-align: center;
            position: absolute;
            bottom: 0;

            a {
                cursor: pointer;
            }
        }
    }

    .bottom_mobileBox {
        position: absolute;
        top: 0.75rem;
        left: 0.25rem;
        right: 0.25rem;
        bottom: 0;

        .bmb_Detail {
            display: flex;
            flex-direction: column;

            .bmb_aboutUs {
                font-size: 0.3rem;
                font-weight: bold;
                margin-bottom: 0.2rem;
            }

            .bmb_normal {
                font-size: 0.25rem;
                margin-bottom: 0.25rem;
                line-height: 0.3rem;
            }

            .QRcode_img {
                height: 1.55rem;

                img {
                    width: 1.55rem;
                    height: 1.55rem;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .QRcode_msg {
                padding-top: 0.2rem;
                padding-bottom: 0.75rem;
                justify-content: center;
                font-size: 0.25rem;
            }
        }

        .bmb_finalRow {
            position: absolute;
            bottom: 0.1rem;
            font-size: 0.25rem;
            width: 100%;
            text-align: center;
            color: #999999;
        }
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
        min-height: 8rem;
    }
}</style>