<template>
    <div ref="toTop">
        <div class="mobile_style" style="height:1.35rem"></div>
        <!-- ⬆ 移动端填补顶栏空间的标签不能删除 -->
        <div class="pc_style">
            <div class="bannarBlock">
                <div class="bannerFontBox">
                    <span class="font_CN">提供行业内有竞争力的薪资待遇<br>拥有完善丰富的福利体系</span>
                    <el-row class="seekJobBtn" @click="toRecruit">
                        <el-col :span="18">查看招聘岗位</el-col>
                        <el-col :span="6"><img src="../assets/image/arrow_right_white.png" alt=""></el-col>
                    </el-row>
                </div>
                <div id="bannerArrowBox">
                    <div id="bannerArrowInsideBox">
                        <img src="../assets/image/bannerArrow.png" alt="">
                        <img src="../assets/image/bannerArrow.png" alt="">
                        <img src="../assets/image/bannerArrow.png" alt="">
                    </div>
                </div>
            </div>
            <div class="titleBlock">成长激励</div>
            <div class="incentiveBlock">
                <div class="ib_backgroundBlock"></div>
                <el-row class="ib_mainBox">
                    <el-col :span="12" class="ib_mainBox_left">
                        <img :src="slideActive[0].url" alt="">
                    </el-col>
                    <el-col :span="12" class="ib_mainBox_right">
                        <div class="swiperBox">
                            <!-- <swiper :modules="modules" :slides-per-view="1" :space-between="50" :autoplay="swiperOption" -->
                            <swiper :modules="modules" :slides-per-view="1" :space-between="50" :navigation="{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }" @slideChange="onSwiper">
                                <swiper-slide v-for="(item, index) in swiperList" :key="index">
                                    <el-row class="ibm_right_row1">
                                        <el-col class="ibmrr_number" :span="3">0{{ item.id }}</el-col>
                                        <el-col class="ibmrr_font" :span="21">{{ item.name }}</el-col>
                                    </el-row>
                                    <el-row class="ibm_right_row2">{{ item.text }}</el-row>
                                </swiper-slide>
                                <div class="swiper-button-prev"></div>
                                <div class="swiper-button-next"></div>
                            </swiper>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="titleBlock">晋升机制</div>
            <div class="promotionBlock">
                <div class="pb_img"></div>
                <div class="pb_list">
                    <div class="pb_list_row">
                        <div class="pblr_title">T/P序列晋升渠道</div>
                        <div class="pblr_text">产品/项目晋升通道、技术晋升通道、市场晋升通道、职能晋升通道</div>
                    </div>
                    <div class="pb_list_row">
                        <div class="pblr_title">定期开放晋升通道</div>
                        <div class="pblr_text">公司开放年度晋升渠道评估，让员工有机会向更高级别的职位发展</div>
                    </div>
                    <div class="pb_list_row">
                        <div class="pblr_title">员工晋升自荐体系</div>
                        <div class="pblr_text">员工可以根据自己的职业规划和发展需求，主动向公司表达晋升意愿</div>
                    </div>
                </div>
            </div>
            <SloganLine></SloganLine>
            <div class="titleBlock">福利待遇</div>
            <div class="welfareBlock">
                <el-row class="welfare_BlockBox">
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon1.png" alt="">
                        </el-row>
                        <el-row class="wb_font">休闲零食</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon2.png" alt="">
                        </el-row>
                        <el-row class="wb_font">年终奖金</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon3.png" alt="">
                        </el-row>
                        <el-row class="wb_font">五险一金</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon4.png" alt="">
                        </el-row>
                        <el-row class="wb_font">带薪年假</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon5.png" alt="">
                        </el-row>
                        <el-row class="wb_font">年调薪制度</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon6.png" alt="">
                        </el-row>
                        <el-row class="wb_font">生日惊喜</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon7.png" alt="">
                        </el-row>
                        <el-row class="wb_font">员工旅游</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon8.png" alt="">
                        </el-row>
                        <el-row class="wb_font">年度体检</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon9.png" alt="">
                        </el-row>
                        <el-row class="wb_font">团建聚餐</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon10.png" alt="">
                        </el-row>
                        <el-row class="wb_font">节日福利</el-row>
                    </div>
                </el-row>
            </div>
            <div class="seekJobBlock" @click="toRecruit">
                <el-row class="seekJobBtn">
                    <el-col :span="18">查看招聘岗位</el-col>
                    <el-col :span="6"><img src="../assets/image/arrow_right_white.png" alt=""></el-col>
                </el-row>
            </div>
        </div>
        <div class="mobile_style">
            <div class="bannarBlock">
                <div class="bannerFontBox">
                    <span class="font_CN">提供行业内有竞争力的薪资待遇<br>拥有完善丰富的福利体系</span>
                </div>
            </div>
            <div class="seekJobBtnBox">
                <el-row class="seekJobBtn" @click="mobileToRecruit">
                    <el-col :span="18">查看招聘岗位</el-col>
                    <el-col :span="6"><img src="../assets/image/arrow_right_white.png" alt=""></el-col>
                </el-row>
            </div>
            <div class="incentiveBlock">
                <div class="ib_backgroundBlock"></div>
                <el-row class="ib_mainBox">
                    <el-col :span="24" class="ib_mainBox_right">
                        <div class="swiperBox">
                            <!-- <swiper :modules="modules" :slides-per-view="1" :space-between="50" :autoplay="swiperOption" -->
                            <swiper :modules="modules" :slides-per-view="1" :space-between="50" :navigation="{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }" @slideChange="onSwiper">
                                <swiper-slide v-for="(item, index) in swiperList" :key="index">
                                    <el-row class="ibm_right_row1">
                                        <el-col class="ibmrr_number" :span="3">0{{ item.id }}</el-col>
                                        <el-col class="ibmrr_font" :span="21">{{ item.name }}</el-col>
                                    </el-row>
                                    <el-row class="ibm_right_row2">{{ item.text }}</el-row>
                                </swiper-slide>
                                <div class="swiper-button-prev"></div>
                                <div class="swiper-button-next"></div>
                            </swiper>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="titleBlock">晋升机制</div>
            <div class="ms_row">
                <div class="ms_title">T/P序列晋升渠道</div>
                <div class="ms_text">产品/项目晋升通道、技术晋升通道、市场晋升通道、职能晋升通道</div>
            </div>
            <div class="ms_row">
                <div class="ms_title">定期开放晋升通道</div>
                <div class="ms_text">公司开放年度晋升渠道评估，让员工有机会向更高级别的职位发展</div>
            </div>
            <div class="ms_row" style="border-bottom: 1px solid #313131;">
                <div class="ms_title">员工晋升自荐体系</div>
                <div class="ms_text">员工可以根据自己的职业规划和发展需求，主动向公司表达晋升意愿</div>
            </div>
            <SloganLine style="margin-top: 1rem;"></SloganLine>
            <div class="welfareBlock">
                <el-row class="welfare_BlockBox">
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon1.png" alt="">
                        </el-row>
                        <el-row class="wb_font">休闲零食</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon2.png" alt="">
                        </el-row>
                        <el-row class="wb_font">年终奖金</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon3.png" alt="">
                        </el-row>
                        <el-row class="wb_font">五险一金</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon4.png" alt="">
                        </el-row>
                        <el-row class="wb_font">带薪年假</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon5.png" alt="">
                        </el-row>
                        <el-row class="wb_font">年调薪制度</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon6.png" alt="">
                        </el-row>
                        <el-row class="wb_font">生日惊喜</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon7.png" alt="">
                        </el-row>
                        <el-row class="wb_font">员工旅游</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon8.png" alt="">
                        </el-row>
                        <el-row class="wb_font">年度体检</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon9.png" alt="">
                        </el-row>
                        <el-row class="wb_font">团建聚餐</el-row>
                    </div>
                    <div class="welfare_block">
                        <el-row class="wb_img">
                            <img src="../assets/image/JoinUs_icon10.png" alt="">
                        </el-row>
                        <el-row class="wb_font">节日福利</el-row>
                    </div>
                </el-row>
            </div>
            <div class="seekJobBtnBox">
                <el-row class="seekJobBtn" @click="mobileToRecruit">
                    <el-col :span="18">查看招聘岗位</el-col>
                    <el-col :span="6"><img src="../assets/image/arrow_right_white.png" alt=""></el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import SloganLine from '../components/SloganLine.vue';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Navigation, Autoplay } from 'swiper';
import { getCurrentInstance } from 'vue';

import 'swiper/swiper-bundle.min.css';

export default {
    name: 'joinUs',
    components: {
        SloganLine,
        Swiper,
        SwiperSlide,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const onSwiper = (swiper) => {
            proxy.changeActive(swiper.activeIndex + 1, proxy.swiperList);
        };
        // const onSlideChange = () => {
        //     console.log('onSlideChange');
        // };
        return {
            onSwiper,
            // onSlideChange,
            modules: [Navigation, Autoplay],
        };
    },
    data() {
        return {
            swiperList: [
                {
                    id: 1,
                    active: true,
                    url: require("@/assets/image/swiper1.png"),
                    name: "关注成长",
                    text: "完善的规划体系与学习资源，注重培养员工能力和专业技能，提升竞争力；"
                },
                {
                    id: 2,
                    active: false,
                    url: require("@/assets/image/swiper2.png"),
                    name: "因材施教",
                    text: "提供全方位的支持与长期陪伴，助员工完成team leader/岗位级别的晋升；"
                },
                {
                    id: 3,
                    url: require("@/assets/image/swiper3.png"),
                    active: false,
                    name: "鼓励创新",
                    text: "我们鼓励创新，不为员工设限，提供舞台与专业指导，鼓励员工追求超越；"
                },
            ],
            slideActive: [
                {
                    id: 1,
                    active: true,
                    url: require("@/assets/image/swiper1.png"),
                    name: "关注成长",
                    text: "完善的规划体系与学习资源，注重培养员工能力和专业技能，提升竞争力；"
                }
            ],
            swiperOption: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
            },
        }
    },
    mounted() {
        this.androidScrollTop();
    },
    methods: {
        androidScrollTop() {//手机安卓端微信内置浏览器切换页面复位到页面顶部
            this.$store.commit('whichDeviceFun')
            if (!this.$store.state.mobileOrPC) {
                this.$refs.toTop.scrollIntoView()
            }
        },
        changeActive(itemId, list) {
            this.slideActive = [];
            for (const key in list) {
                if (Object.hasOwnProperty.call(list, key)) {
                    const element = list[key];
                    if (element.id === itemId) {
                        element.active = true;
                        this.slideActive.push(element)
                    } else {
                        element.active = false;
                    }
                }
            }
        },
        toRecruit() {
            window.open("https://www.zhipin.com/gongsi/job/7afe9c75e3195dc51nZ52t--EFs~.html?ka=company-jobs")
        },
        mobileToRecruit() {
            window.location.href = "https://www.zhipin.com/gongsi/job/7afe9c75e3195dc51nZ52t--EFs~.html?ka=company-jobs"
        }
    }
}
</script>
<style lang="scss" scoped>
@keyframes bannerArrowMove {
    from {
        transform: translateY(-50%);
    }

    to {
        transform: translateY(5%);
    }
}

font {
    color: #313131;
}

.bannarBlock {
    height: 560px;
    border-bottom: 1px solid #313131;
    padding: 140px 180px;
    position: relative;

    .bannerFontBox {

        .font_CN {
            font-family: Alimama_ShuHeiTi_Bold;
            font-size: 68px;
            font-weight: bold;
        }

        .seekJobBtn {
            margin-top: 60px;
        }

    }

    #bannerArrowBox {
        width: 98px;
        height: 98px;
        overflow: hidden;
        position: absolute;
        right: 160px;
        bottom: 40px;

        #bannerArrowInsideBox {
            width: 98px;
            height: 98px;
            position: absolute;
            bottom: 0;
            animation: bannerArrowMove 2s infinite;

            img {
                width: 98px;
                height: 48px;
                display: flex;
            }
        }
    }
}

.seekJobBlock {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.seekJobBtn {
    width: 248px;
    height: 60px;
    color: #fff;
    background-color: #313131;
    padding: 0 40px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;

    img {
        display: flex;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
    }
}

.titleBlock {
    height: 250px;
    font-size: 36px;
    font-family: MicrosoftYaHei;
    font-weight: bolder;
    line-height: 250px;
    text-align: center;
}

.incentiveBlock {
    height: 600px;
    position: relative;

    .ib_backgroundBlock {
        position: absolute;
        top: 120px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f9f9f9;
    }

    .ib_mainBox {
        margin: 0 180px;
        height: 600px;

        .ib_mainBox_left {

            // background-color: #313131;
            // overflow: hidden;
            img {
                width: 800px;
                height: 600px;
            }
        }

        .ib_mainBox_right {
            padding: 0 100px;
            padding-top: 200px;

            .swiperBox {
                height: 400px;
                position: relative;

                .swiper {
                    height: 400px;
                }

                .ibm_right_row1 {
                    margin-bottom: 50px;
                    font-size: 48px;
                    font-weight: bolder;

                    .ibmrr_number {
                        border-bottom: 6px solid #313131;
                        text-align: center;
                        color: #d8d8d8;
                    }

                    .ibmrr_font {
                        padding: 0 40px;
                        border-bottom: 1px solid #313131;
                    }
                }

                .ibm_right_row2 {
                    font-size: 20px;
                }

                .swiper-button-prev,
                .swiper-button-next {
                    width: 64px;
                    height: 64px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .swiper-button-prev {
                    position: absolute;
                    top: 300px;
                    left: 400px;
                    background-image: url(../assets/image/arrow_left.png);
                }

                .swiper-button-next {
                    position: absolute;
                    top: 300px;
                    right: 0;
                    background-image: url(../assets/image/arrow_right.png);
                }

                .swiper-button-prev::after,
                .swiper-button-next::after {
                    content: '';
                }
            }
        }
    }
}

.promotionBlock {
    height: 960px;
    margin-left: 180px;
    margin-bottom: 120px;
    display: flex;
    border-top: 1px solid #313131;
    border-left: 1px solid #313131;

    .pb_img {
        width: 720px;
        height: 960px;
        border-right: 1px solid #313131;
        border-bottom: 1px solid #313131;
        background-image: url("../assets/image/joinUs_block2.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .pb_list {
        width: 58%;

        .pb_list_row {
            height: 280px;
            padding: 80px 160px;
            border-bottom: 1px solid #313131;

            .pblr_title {
                font-size: 24px;
                font-family: MicrosoftYaHei;
                font-weight: bolder;
                margin-bottom: 30px;
            }

            .pblr_text {
                font-size: 20px;
                font-family: MicrosoftYaHei;
            }
        }
    }
}

.welfareBlock {
    height: 400px;

    .welfare_BlockBox {
        padding: 0 200px;

        .welfare_block {
            width: 280px;
            height: 170px;
            margin: 10px 9px;

            .wb_img {
                justify-content: center;

                img {
                    width: 60px;
                    height: 60px;
                }
            }

            .wb_font {
                font-size: 24px;
                font-family: MicrosoftYaHei;
                font-weight: bolder;
                justify-content: center;
            }
        }
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
    }

    .bannarBlock {
        height: 3rem;
        border-bottom: 1px solid #313131;
        padding: 0.75rem 0.5rem;
        position: relative;
        // margin-top: 1.35rem;

        .bannerFontBox {

            .font_CN {
                font-family: Alimama_ShuHeiTi_Bold;
                font-size: 0.5rem;
                font-weight: bold;
            }
        }
    }

    .seekJobBtnBox {
        display: flex;
        justify-content: center;
        padding: 1rem;

        .seekJobBtn {
            width: 4rem;
            height: 1rem;
            padding: 0 0.3rem;
            font-size: 0.3rem;
            text-align: center;

            img {
                width: 0.5rem;
                height: 0.5rem;
            }
        }
    }

    .incentiveBlock {
        height: auto;
        position: relative;

        .ib_backgroundBlock {
            position: absolute;
            top: 0.5rem;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #f9f9f9;
        }

        .ib_mainBox {
            margin: 0;
            height: auto;

            .ib_mainBox_right {
                padding: 0 1rem;
                padding-top: 200px;

                .swiperBox {
                    height: 5rem;
                    position: relative;

                    .swiper {
                        height: 5rem;
                    }

                    .ibm_right_row1 {
                        margin-bottom: 50px;
                        font-size: 0.6rem;
                        font-weight: bolder;

                        .ibmrr_number {
                            border-bottom: 1px solid #313131;
                            text-align: center;
                            color: #d8d8d8;
                        }

                        .ibmrr_font {
                            padding: 0 40px;
                            border-bottom: 1px solid #313131;
                        }
                    }

                    .ibm_right_row2 {
                        font-size: 0.3rem;
                    }

                    .swiper-button-prev,
                    .swiper-button-next {
                        width: 1rem;
                        height: 1rem;
                    }

                    .swiper-button-prev {
                        position: absolute;
                        top: 3rem;
                        left: 5rem;
                        background-image: url(../assets/image/arrow_left.png);
                    }

                    .swiper-button-next {
                        position: absolute;
                        top: 3rem;
                        right: 0;
                        background-image: url(../assets/image/arrow_right.png);
                    }

                    .swiper-button-prev::after,
                    .swiper-button-next::after {
                        content: '';
                    }
                }
            }
        }
    }

    .titleBlock {
        font-size: 0.6rem;
        padding: 1rem;
        width: auto;
        height: 3rem !important;
        line-height: 1rem;
    }

    .ms_row {
        border-top: 1px solid #313131;
        padding: 1rem 0.3rem;

        .ms_title {
            font-size: 0.4rem;
            font-weight: bolder;
            line-height: 1rem;
        }

        .ms_text {
            font-size: 0.3rem;
            line-height: 0.5rem;
        }
    }

    .welfareBlock {
        height: auto;

        .welfare_BlockBox {
            padding: 0.5rem;
            margin-top: 2rem;

            .welfare_block {
                width: 23%;
                height: 2rem;
                margin: 10px 9px;

                .wb_img {
                    justify-content: center;

                    img {
                        width: 0.7rem;
                        height: 0.7rem;
                    }
                }

                .wb_font {
                    font-size: 0.3rem;
                }
            }
        }
    }
}
</style>