<template>
    <div class="logoLine">
        <div id="text">
            "智慧空间"&nbsp;&nbsp;数字化&nbsp;&nbsp;&nbsp;&nbsp;YUNZHITECHNOLOGY&nbsp;&nbsp;&nbsp;&nbsp;
            "智慧空间"&nbsp;&nbsp;数字化&nbsp;&nbsp;&nbsp;&nbsp;YUNZHITECHNOLOGY&nbsp;&nbsp;&nbsp;&nbsp;
            "智慧空间"&nbsp;&nbsp;数字化&nbsp;&nbsp;&nbsp;&nbsp;YUNZHITECHNOLOGY&nbsp;&nbsp;&nbsp;&nbsp;
            "智慧空间"&nbsp;&nbsp;数字化&nbsp;&nbsp;&nbsp;&nbsp;YUNZHITECHNOLOGY&nbsp;&nbsp;&nbsp;&nbsp;
            "智慧空间"&nbsp;&nbsp;数字化&nbsp;&nbsp;&nbsp;&nbsp;YUNZHITECHNOLOGY&nbsp;&nbsp;&nbsp;&nbsp;
            "智慧空间"&nbsp;&nbsp;数字化&nbsp;&nbsp;&nbsp;&nbsp;YUNZHITECHNOLOGY&nbsp;&nbsp;&nbsp;&nbsp;
            "智慧空间"&nbsp;&nbsp;数字化&nbsp;&nbsp;&nbsp;&nbsp;YUNZHITECHNOLOGY&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'SloganLine'
}
</script>
  
<style scoped>
@keyframes loop {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.logoLine {
    height: 48px;
    background-color: #313131;
    color: #fff;
    line-height: 48px;
    font-size: 28px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
}

#text {
    white-space: nowrap;
    position: absolute;
    left: 0;
    right: 0;
    animation: loop 18s infinite;
    animation-timing-function: linear;
}

@media only screen and (max-width: 1100px) {
    .logoLine{
        height: 0.75rem;
        line-height: 0.75rem;
        font-size: 0.35rem;
    }
}
</style>
  