import { createRouter, createWebHistory } from "vue-router";

// 导入需要路由的组件
import Home from "@/views/Home.vue";
import productCenter from "@/views/ProductCenter.vue";
import typicalCase from "@/views/function/typicalCase/CaseList.vue";
import caseDetail from "@/views/function/typicalCase/CaseDetail.vue";
import informationCenter from "@/views/function/informationCenter/index.vue";
import informationDetail from "@/views/function/informationCenter/informationDetail.vue";
import solution from "@/views/Solution.vue";
import aboutUs from "@/views/AboutUs.vue";
import joinUs from "@/views/JoinUs.vue";

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/productCenter",
      name: "productCenter",
      component: productCenter,
    },
    {
      path: "/typicalCase",
      name: "typicalCase",
      component: typicalCase,
    },
    {
      path: "/caseDetail",
      name: "caseDetail",
      component: caseDetail,
    },
    {
      path: "/informationCenter",
      name: "informationCenter",
      component: informationCenter,
    },
    {
      path: "/informationDetail",
      name: "informationDetail",
      component: informationDetail,
    },
    {
      path: "/solution",
      name: "solution",
      component: solution
    },
    {
      path: "/aboutUs",
      name: "aboutUs",
      component: aboutUs
    },
    {
      path: "/joinUs",
      name: "joinUs",
      component: joinUs
    }
  ],
});

export default router;
