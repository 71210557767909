<template>
    <div v-show="visiable">
        <div class="maskBox pc_style">
            <div class="closeBtn" @click="close">
                <img src="@/assets/image/closeBtn.png" alt="">
            </div>
            <div class="mainBox">
                <div class="searchInputBox">
                    <div class="inputOutBox">
                        <img src="@/assets/image/search_icon_gray.png" alt="" class="searchIcon" @click="checkValue">
                        <input type="text" class="searchInput" v-model="searchParams" @input="searchValueChange"
                            @keyup.enter="checkValue()">
                        <div class="deleteBtn" v-show="inputClose" @click="clearValue"></div>
                    </div>
                </div>
                <div class="keywordBox" v-show="hasData">
                    <div class="keywordBox_inside">
                        <span>快速搜索</span>
                        <span class="menuBtn" @click="changeValue(item)" v-for="(item, index) in keywordArr" :key="index">{{
                            item }}</span>
                    </div>
                </div>
                <div class="msgListBox" v-show="!hasData">
                    <div class="keywordBox_inside">
                        <span>搜索结果</span>
                        <span class="menuBtn" v-for="(item, index) in categoryList" :key="index"
                            @click="changeActive(item.id)" :class="[item.active ? 'fontBolder' : '']">{{ item.name }}(<label
                                v-if="item.name != '全部'">{{
                                    item.number }}</label><label v-else>{{ allNumber }}</label>)</span>
                    </div>
                    <div class="dataListBox">
                        <div class="listRow" v-for="(item, index) in currentList" :key="index" @click="newTapWeb(item)">
                            <div class="listTit">{{ item.title }}</div>
                            <div class="listDesc">{{ item.description }}</div>
                            <div class="listSource">{{ translate(item.target) }}</div>
                        </div>
                        <div class="noData" v-if="currentList.length == 0">未搜索到相关内容</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb_mainBox mobile_style">
            <div class="closeBtn" @click="close">
                <img src="@/assets/image/closeBtn.png" alt="">
            </div>
            <div class="mb_searchInputBox">
                <div class="mb_inputOutBox">
                    <img src="@/assets/image/search_icon_gray.png" alt="" class="mb_searchIcon" @click="checkValue">
                    <input type="text" class="mb_searchInput" v-model="searchParams" @input="searchValueChange"
                        @keyup.enter="checkValue()">
                    <div class="mb_deleteBtn" v-show="inputClose" @click="clearValue"></div>
                </div>
            </div>
            <div class="mb_keywordBox" v-show="hasData">
                <div class="mb_keywordBox_inside">
                    <div><span>快速搜索</span></div>
                    <span class="mb_menuBtn" @click="changeValue(item)" v-for="(item, index) in keywordArr" :key="index">{{
                        item }}</span>
                </div>
            </div>
            <div class="mb_msgListBox" v-show="!hasData">
                <div class="mb_keywordBox_inside">
                    <div>搜索结果</div>
                    <span class="mb_menuBtn" v-for="(item, index) in categoryList" :key="index"
                        @click="changeActive(item.id)" :class="[item.active ? 'mb_fontBolder' : '']">{{ item.name }}(<label
                            v-if="item.name != '全部'">{{
                                item.number }}</label><label v-else>{{ allNumber }}</label>)</span>
                </div>
                <div class="dataListBox">
                    <div class="listRow" v-for="(item, index) in currentList" :key="index" @click="newTapWeb(item)">
                        <div class="mb_listTit">{{ item.title }}</div>
                        <div class="mb_listDesc">{{ item.description }}</div>
                        <div class="mb_listSource">{{ translate(item.target) }}</div>
                    </div>
                    <div class="mb_noData" v-if="currentList.length == 0">未搜索到相关内容</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { Server } from "@/utils/server";

export default {
    name: 'messageBox',
    props: {
        searchState: Boolean
    },
    data() {
        return {
            visiable: false,
            searchParams: "",
            hasData: true,
            inputClose: false,
            allNumber: 0,
            keywordArr: [
                '导视导览系统', '会员营销系统', '商户服务系统', '交互商显'
            ],
            categoryList: [
                {
                    id: 1,
                    name: "全部",
                    number: 0,
                    active: true,
                },
                // {
                //     id: 2,
                //     name: "产品中心",
                //     number: 0,
                //     active: false,
                // },
                // {
                //     id: 3,
                //     name: "解决方案",
                //     number: 0,
                //     active: false,
                // },
                // {
                //     id: 4,
                //     name: "经典案例",
                //     number: 0,
                //     active: false,
                // },
                // {
                //     id: 5,
                //     name: "资讯中心",
                //     number: 0,
                //     active: false,
                // },
            ],
            allDataList: [],
            productList: [],
            solutionList: [],
            caseList: [],
            NewsList: [],
            currentList: []
        }
    },
    mounted() {
        this.$bus.$on("searchState", key => {
            this.visiable = key;
            this.hasData = true;
            this.inputClose = false;
            this.$store.commit('whichDeviceFun')
            if(this.$store.state.mobileOrPC){
                this.stopScroll();
            }
        });
        this.resetState();
    },
    methods: {
        close() {
            this.visiable = false;
            this.searchParams = "";
            this.$store.commit('whichDeviceFun')
            if(this.$store.state.mobileOrPC){
                this.canScroll();
            }
        },
        resetState() {
            this.categoryList = [
                {
                    id: 1,
                    name: "全部",
                    number: 0,
                    active: true,
                }];
            this.allDataList = [];
            this.productList = [];
            this.solutionList = [];
            this.caseList = [];
            this.NewsList = [];
            this.currentList = [];
            this.allNumber = 0;
        },
        searchFun() {
            // console.log('searchFun---')
            let params = {
                params: {
                    key: this.searchParams
                }
            };
            this.resetState();
            this.hasData = false;
            Server.search(params).then(res => {
                // console.log(res);
                for (let i = 0; i < res.length; i++) {
                    this.categoryList.push({
                        id: i + 2,
                        name: res[i].typeStr,
                        number: res[i].size,
                        active: false,
                    })
                    this.allNumber = this.allNumber + res[i].size;
                    if (res[i].typeStr == "产品中心") {
                        this.productList = res[i].data;
                        this.allDataList = [...this.allDataList, ...this.productList]
                    }
                    if (res[i].typeStr == "解决方案") {
                        this.solutionList = res[i].data;
                        this.allDataList = [...this.allDataList, ...this.solutionList]
                    }
                    if (res[i].typeStr == "案例中心") {
                        this.caseList = res[i].data;
                        this.allDataList = [...this.allDataList, ...this.caseList]
                    }
                    if (res[i].typeStr == "资讯中心") {
                        this.NewsList = res[i].data;
                        this.allDataList = [...this.allDataList, ...this.NewsList]
                    }
                }
                // console.log('全部---', this.allDataList);
                this.currentList = this.allDataList;
            }).then(() => {
                this.highLight();
            })

        },
        highLight() {
            const nameElement = document.querySelectorAll('.dataListBox .listRow div');
            // console.log('~~~~~', nameElement);
            const arr = Array.from(nameElement);
            arr.forEach(item => {
                const text = item.innerText;
                const regExp = new RegExp(this.searchParams, 'g');
                const highlightedText = text.replace(regExp, `<span style="color:#EF781B">${this.searchParams}</span>`);
                item.innerHTML = highlightedText;
            })
        },
        changeValue(val) {
            this.searchParams = val;
            this.inputClose = true;
            this.searchFun();
        },
        searchValueChange() {
            if (this.searchParams == "") {
                this.inputClose = false;
            } else {
                this.inputClose = true;
            }
        },
        clearValue() {
            this.searchParams = "";
            this.inputClose = false;
        },
        checkValue() {
            if (this.searchParams != "") {
                this.searchFun();
            } else {
                this.$bus.$emit('state', true)
                this.$bus.$emit('msg', '查询条件不能为空！')
            }
        },
        changeActive(itemId) {
            for (const key in this.categoryList) {
                if (Object.hasOwnProperty.call(this.categoryList, key)) {
                    const element = this.categoryList[key];
                    if (element.id === itemId) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                }
            }
            this.checkList(itemId);
            this.$nextTick(() => {
                this.highLight();
            })
        },
        checkList(num) {
            // console.log(num, this.categoryList)
            switch (num) {
                case 1:
                    this.currentList = this.allDataList;
                    break;
                case 2:
                    this.currentList = this.caseList;
                    break;
                case 3:
                    this.currentList = this.productList;
                    break;
                case 4:
                    this.currentList = this.solutionList;
                    break;
                case 5:
                    this.currentList = this.NewsList;
                    break;
            }
        },
        translate(str) {
            switch (str) {
                case 'exp':
                    return "案例中心"
                case 'consult':
                    return "资讯中心"
                case 'navigation':
                    return "导视导览系统"
                case 'memberMarking':
                    return "会员营销系统"
                case 'merchantServices':
                    return "商户服务系统"
                case 'interactiveView':
                    return "交互商显"
                case 'commercialRealEstate':
                    return "商业地产"
                case 'governmentDigitization':
                    return "政府数字化"
                case 'smartPark':
                    return "智慧园区"
                case 'smartExhibitionHall':
                    return "智慧展馆"
                case 'smartCampus':
                    return "智慧校园"
            }
        },
        newTapWeb(item) {
            switch (item.target) {
                case "exp":
                    window.open('/caseDetail?id=' + item.id);
                    break;
                case "consult":
                    window.open("/informationDetail?id=" + item.id);
                    break;
                default:
                    this.toProductAndSolution(item)
                    break;
            }
        },
        toProductAndSolution(item) {
            switch (item.target) {
                case 'navigation':
                    window.open('/productCenter?data=' + item.target);
                    break;
                case 'memberMarking':
                    window.open('/productCenter?data=' + item.target);
                    break;
                case 'merchantServices':
                    window.open('/productCenter?data=' + item.target);
                    break;
                case 'interactiveView':
                    window.open('/productCenter?data=' + item.target);
                    break;
                case 'commercialRealEstate':
                    window.open('/solution?data=' + item.target);
                    break;
                case 'governmentDigitization':
                    window.open('/solution?data=' + item.target);
                    break;
                case 'smartPark':
                    window.open('/solution?data=' + item.target);
                    break;
                case 'smartExhibitionHall':
                    window.open('/solution?data=' + item.target);
                    break;
                case 'smartCampus':
                    window.open('/solution?data=' + item.target);
                    break;
            }
        },
        stopScroll() {
            var mo = function (e) { e.preventDefault(); };
            document.body.style.overflow = 'hidden';
            document.addEventListener("touchmove", mo, false);//禁止页面滑动
        },
        canScroll() {
            var mo = function (e) { e.preventDefault(); };
            document.body.style.overflow = '';//出现滚动条
            document.removeEventListener("touchmove", mo, false);
        },
    }
};
</script>
  
<style lang="scss" scoped>
.maskBox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99998;

    .closeBtn {
        height: 48px;
        position: absolute;
        top: 36px;
        right: 65px;
        cursor: pointer;

        img {
            width: 48px;
            height: 48px;
        }
    }

    .mainBox {
        background-color: #fff;
        position: absolute;
        top: 120px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: scroll;

        .searchInputBox {
            padding-top: 160px;
            display: flex;
            justify-content: center;

            .inputOutBox {
                width: 1000px;
                height: 64px;
                border: 2px solid #d8d8d8;
                border-radius: 60px;
                display: flex;
                justify-content: center;

                .searchIcon {
                    width: 40px;
                    height: 40px;
                    margin: 10px 0;
                    cursor: pointer;
                }

                .searchInput {
                    border: 0;
                    outline: none;
                    background-color: transparent;
                    width: 880px;
                    height: 60px;
                    padding: 0 10px;
                }

                .deleteBtn {
                    width: 28px;
                    height: 28px;
                    background-color: #d8d8d8;
                    border-radius: 50%;
                    margin: 16px 0;
                    background-image: url('../assets/image/closeBtn.png');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    cursor: pointer;
                }
            }
        }

        .keywordBox,
        .msgListBox {
            padding-top: 60px;

            .keywordBox_inside {
                width: 1000px;
                font-size: 20px;
                margin: 0 24%;

                span {
                    padding: 0 15px;
                    user-select: none;
                    cursor: pointer;
                }

                .menuBtn:hover,
                .fontBolder {
                    font-weight: bolder;
                }
            }

            .keywordBox_inside :first-child {
                color: #999;
                cursor: auto;
            }

            .dataListBox {
                margin: 0 24%;

                .listRow {
                    padding: 20px 10px;

                    .listTit {
                        font-size: 20px;
                        color: #313131;
                    }

                    .listDesc {
                        font-size: 18px;
                        padding: 14px 0;
                        color: #666;
                    }

                    .listSource {
                        font-size: 16px;
                        color: #999;
                    }
                }

                .listRow:hover {
                    background-color: #f9f9f9;
                }

                .noData {
                    display: flex;
                    justify-content: center;
                    font-size: 30px;
                    color: #999;
                    padding-top: 180px;
                }
            }
        }
    }
}

.mb_mainBox {
    background-color: #fff;
    position: absolute;
    top: 1.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    z-index: 9999;
    .closeBtn{
        width: 0.75rem;
        height: 0.75rem;
        position: absolute;
        top: 0;
        right: 0.3rem;
        background-color: #313131;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 0.5rem;
            height: 0.5rem;
        }
    }

    .mb_searchInputBox {
        padding-top: 1rem;
        display: flex;
        justify-content: center;

        .mb_inputOutBox {
            width: 90%;
            height: 0.75rem;
            border: 2px solid #d8d8d8;
            border-radius: 60px;
            display: flex;
            justify-content: center;

            .mb_searchIcon {
                width: 0.5rem;
                height: 0.5rem;
                margin: 0.1rem 0;
                cursor: pointer;
            }

            .mb_searchInput {
                border: 0;
                outline: none;
                background-color: transparent;
                width: 85%;
                height: 0.75rem;
                padding: 0 0.1rem;
            }

            .mb_deleteBtn {
                width: 0.5rem;
                height: 0.5rem;
                background-color: #d8d8d8;
                border-radius: 50%;
                margin: 0.1rem 0;
                background-image: url('../assets/image/closeBtn.png');
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                cursor: pointer;
            }
        }
    }

    .mb_keywordBox,
    .mb_msgListBox {
        padding-top: 0.35rem;

        .mb_keywordBox_inside {
            font-size: 0.3rem;
            padding: 0 0.35rem;

            span {
                padding: 0 0.15rem;
                user-select: none;
                cursor: pointer;
            }

            .mb_menuBtn:hover,
            .mb_fontBolder {
                font-weight: bolder;
            }
        }

        .mb_keywordBox_inside :first-child {
            color: #999;
            cursor: auto;
        }

        .dataListBox {
            margin: 0 0.2rem;

            .listRow {
                padding: 0.2rem 0.1rem;

                .mb_listTit {
                    font-size: 0.3rem;
                    color: #313131;
                }

                .mb_listDesc {
                    font-size: 0.3rem;
                    padding: 0.15rem 0;
                    color: #666;
                }

                .mb_listSource {
                    font-size: 0.3rem;
                    color: #999;
                }
            }

            .mb_listRow:hover {
                background-color: #f9f9f9;
            }

            .mb_noData {
                display: flex;
                justify-content: center;
                font-size: 0.3rem;
                color: #999;
                padding-top: 1rem;
            }
        }
    }
}

.mobile_style {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .pc_style {
        display: none;
    }

    .mobile_style {
        display: block;
    }
}
</style>