import "lib-flexible"; // 引入 lib-flexible 自动设置 rem 基准值

import { createApp } from "vue";
import App from "./App.vue";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import store from "./store";
import router from "./router"; // 导入路由实例
import { VueMasonryPlugin } from 'vue-masonry'; //瀑布流插件
import bus from './utils/bus'

const app = createApp(App);

// 在样式文件中使用 px2rem-loader
const styles = require.context("@/styles", true, /\.scss$/);
styles.keys().forEach(styles);
router.afterEach(() => {
    window.scrollTo(0, 0);
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0

    // setTimeout(() => {
    //     var _hmt = _hmt || [];
    //     (function () {
    //         //每次执行前，先移除上次插入的代码
    //         document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
    //         // var hm = document.createElement("script");
    //         // hm.src = "https://hm.baidu.com/hm.js?7232993a211238c79b944e7f1bdca12a";
    //         // hm.id = "baidu_tj"
    //         // var s = document.getElementsByTagName("script")[0];
    //         // s.parentNode.insertBefore(hm, s);
    //         var hm = document.createElement("script");
    //         hm.src = "https://hm.baidu.com/hm.js?9b073c45accd374c1a01e13840f2b010";
    //         hm.id = "baidu_tj"
    //         var s = document.getElementsByTagName("script")[0];
    //         s.parentNode.insertBefore(hm, s);
    //     })();
    // }, 0);
});

app.config.globalProperties.$bus = bus;
app.use(router);
app.use(ElementPlus);
app.use(VueMasonryPlugin);
app.use(store).mount("#app");